import { FilterType } from "src/models/ReportingFilter";
import { z, ZodObject } from "zod";

// ["=", "!=", ">", "<", ">=", "<=", "IN", "NOT IN", "ILIKE", "NOT ILIKE"]
export enum SQLQueryWhereOperator {
  EQUAL = "=",
  NOT_EQUAL = "!=",
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_OR_EQUAL = ">=",
  LESS_THAN_OR_EQUAL = "<=",
  IN = "IN",
  NOT_IN = "NOT IN",
  LIKE = "ILIKE",
  NOT_LIKE = "NOT ILIKE",
}

const SQLQueryWhereOperatorSchema = z.nativeEnum(SQLQueryWhereOperator);

const sqlQueryWhereOperatorParser = (
  val: string,
): z.infer<typeof SQLQueryWhereOperatorSchema> | undefined => {
  try {
    return SQLQueryWhereOperatorSchema.parse(val);
  } catch (_) {
    return undefined;
  }
};

const SQLQueryWhereConditionsSchema = z.object({
  field: z.string(),
  fieldType: z.nativeEnum(FilterType),
  operator: SQLQueryWhereOperatorSchema,
  value: z.union([z.string(), z.number(), z.boolean()]),
});

export type SQLQueryWhereConditions = z.infer<typeof SQLQueryWhereConditionsSchema>;

export type SQLQueryWhere = {
  type: "AND" | "OR";
  conditions: (SQLQueryWhereConditions | SQLQueryWhere)[];
};

const SQLQueryWhereSchema: z.ZodType<SQLQueryWhere> = z.object({
  type: z.enum(["AND", "OR"]),
  conditions: z.array(z.union([SQLQueryWhereConditionsSchema, z.lazy(() => SQLQueryWhereSchema)])),
});

const SQLQueryWhereArraySchema = SQLQueryWhereSchema.optional();

const SQLQueryOrderBySchema = z.object({
  field: z.string(),
  direction: z.enum(["ASC", "DESC"]),
});

const SQLQuerySchema = z.object({
  select: z.string().array().optional(),
  from: z.string(),
  where: SQLQueryWhereArraySchema,
  orderBy: SQLQueryOrderBySchema.array().optional(),
});

export type SQLQuery = z.infer<typeof SQLQuerySchema>;

export function parseWhereClause(whereClause: SQLQueryWhere, timezone: string): string {
  const groupedConditions = whereClause.conditions.map((condition) => {
    if ("field" in condition) {
      const type = condition.fieldType ?? FilterType.STRING;

      let fieldExpression = `"${condition.field}"`;
      let valueExpression = `'${condition.value}'`;

      if (type === FilterType.DATE) {
        fieldExpression = `("${condition.field}"::timestamp AT TIME ZONE 'UTC' AT TIME ZONE '${timezone}')::date`;
        valueExpression = `'${condition.value}'::date`;
      }

      if (
        condition.operator === SQLQueryWhereOperator.LIKE ||
        condition.operator === SQLQueryWhereOperator.NOT_LIKE
      ) {
        valueExpression = `'%${condition.value}%'`;
      }

      return `${fieldExpression} ${condition.operator} ${valueExpression}`;
    } else {
      return `(${parseWhereClause(condition, timezone)})`;
    }
  });

  return groupedConditions.join(` ${whereClause.type} `);
}

export function buildSQL(query: SQLQuery, timezone: string): string {
  const selectClause = `SELECT ${query.select ? query.select.join(", ") : "*"}`;
  const fromClause = `FROM ${query.from}`;

  const whereClause = query.where ? `WHERE ${parseWhereClause(query.where, timezone)}` : "";
  const orderByClause = query.orderBy
    ? `ORDER BY ${query.orderBy.map((o) => `${o.field} ${o.direction}`).join(", ")}`
    : "";

  return (
    [selectClause, fromClause, whereClause, orderByClause].filter((clause) => clause).join(" ") +
    ";"
  );
}

export function encodeWhereClause(whereClause: SQLQueryWhere, prefix = "where"): string {
  const conditions = whereClause.conditions
    .map((condition, index) => {
      if ("field" in condition) {
        return (
          `${prefix}[conditions][${index}][field]=${encodeURIComponent(condition.field)}&` +
          `${prefix}[conditions][${index}][fieldType]=${encodeURIComponent(condition.fieldType)}&` +
          `${prefix}[conditions][${index}][operator]=${encodeURIComponent(condition.operator)}&` +
          `${prefix}[conditions][${index}][value]=${encodeURIComponent(condition.value.toString())}`
        );
      } else {
        return encodeWhereClause(condition, `${prefix}[conditions][${index}]`);
      }
    })
    .join("&");

  return `${prefix}[type]=${whereClause.type}&${conditions}`;
}

export function convertQueryToURLParams(query: SQLQuery) {
  const params: string[] = [];

  // Encode SELECT fields
  if (query.select) {
    params.push(
      `select=${query.select ? query.select.map((field) => encodeURIComponent(field)).join(",") : "*"}`,
    );
  }

  // Encode FROM table
  if (query.from) {
    params.push(`from=${encodeURIComponent(query.from)}`);
  }

  // Encode WHERE clause if it exists
  if (query.where) {
    params.push(encodeWhereClause(query.where));
  }

  // Encode ORDER BY if it exists
  if (query.orderBy) {
    query.orderBy.forEach((order) => {
      params.push(
        `orderBy[field]=${encodeURIComponent(order.field)}&orderBy[direction]=${order.direction}`,
      );
    });
  }

  // Combine all parameters into a single string
  return params.join("&");
}

export function decodeWhereConditions(
  baseKey: string,
  queryParams: URLSearchParams,
): SQLQueryWhere | undefined {
  const type = queryParams.get(`${baseKey}[type]`);
  if (!type || (type !== "AND" && type !== "OR")) {
    return undefined;
  }

  const conditions: (SQLQueryWhere | SQLQueryWhereConditions)[] = [];
  let index = 0;
  while (
    queryParams.has(`${baseKey}[conditions][${index}][field]`) ||
    queryParams.has(`${baseKey}[conditions][${index}][type]`)
  ) {
    if (queryParams.has(`${baseKey}[conditions][${index}][type]`)) {
      const nestedCondition = decodeWhereConditions(
        `${baseKey}[conditions][${index}]`,
        queryParams,
      );
      if (nestedCondition) {
        conditions.push(nestedCondition);
      }
    } else {
      const field = queryParams.get(`${baseKey}[conditions][${index}][field]`);
      const fieldType = queryParams.get(`${baseKey}[conditions][${index}][fieldType]`);
      const operator = queryParams.get(`${baseKey}[conditions][${index}][operator]`);
      const value = queryParams.get(`${baseKey}[conditions][${index}][value]`);
      const parsedOperator = operator
        ? sqlQueryWhereOperatorParser(decodeURIComponent(operator))
        : undefined;
      if (field && parsedOperator && value) {
        conditions.push({
          field: decodeURIComponent(field),
          fieldType: fieldType ? (fieldType as FilterType) : FilterType.STRING,
          operator: parsedOperator,
          value: decodeURIComponent(value),
        });
      }
    }
    index++;
  }

  return {
    type: type,
    conditions,
  };
}

export function decodeURLToJSON(url: string) {
  const queryParams = new URLSearchParams(url.split("?")[1]);
  const query: SQLQuery = {
    from: "",
  };

  // Decode SELECT fields
  const selectFields = queryParams.get("select");
  if (selectFields) {
    query.select = selectFields.split(",").map((field) => decodeURIComponent(field));
  }

  // Decode FROM table
  const fromTable = queryParams.get("from");
  if (fromTable) {
    query.from = decodeURIComponent(fromTable);
  }

  const whereClause = decodeWhereConditions("where", queryParams);
  if (whereClause) {
    query.where = whereClause;
  }

  // Decode ORDER BY if present
  if (queryParams.has("orderBy[field]") && queryParams.has("orderBy[direction]")) {
    const orderByField = queryParams.get("orderBy[field]");
    const orderByDirection = queryParams.get("orderBy[direction]");
    if (
      orderByField &&
      orderByDirection &&
      (orderByDirection === "ASC" || orderByDirection === "DESC")
    ) {
      query.orderBy = [
        {
          field: decodeURIComponent(orderByField),
          direction: orderByDirection,
        },
      ];
    }
  }

  return query;
}

export function createReportingCteBlockWithMetadataExpanded(
  blockName: string,
  lenderId: number,
  metadataSchema: ZodObject<any>,
): string {
  let cteBlock = `WITH ${blockName} AS (
    SELECT
      "accountName",
      "entityName",
      "documentType",
      "recordType",
      "year",
      "quality",
      "frequency",
      "requestDate",
      "finalDueDate",
      "reviewDate",
      "daysToDueDate",
      "collectedOn",
      "updatedAt",
      "accountOwner",
      "borrowers",
      "status"`;

  Object.keys(metadataSchema.shape).forEach((key) => {
    let innerZodType = metadataSchema.shape[key];
    if (innerZodType._def.typeName === "ZodOptional") {
      innerZodType = innerZodType._def.innerType;
    }
    const type = innerZodType._def.typeName;
    let castType = "";
    switch (type) {
      case "ZodString":
        castType = "text";
        break;
      case "ZodNumber":
        castType = "numeric";
        break;
      case "ZodBoolean":
        castType = "boolean";
        break;
      case "ZodDate":
        castType = "timestamp";
        break;
      case "ZodEnum":
      case "ZodNativeEnum":
        castType = "text";
        break;
      default:
        castType = "text";
    }
    cteBlock += `,
      CAST(metadata->>'${key}' AS ${castType}) AS "${key}"`;
  });

  cteBlock += `
    FROM
      reporting_v
    WHERE "lenderId" = ${lenderId}
  )`;

  return cteBlock;
}
