import { LoginResponse } from "src/pages/api/intake/index.contracts";
import {
  createQuestionCompletedAction,
  reviewDocumentCompletedUpload,
  reviewDocumentUpload,
} from "src/redux/actions/intake.actions";
import produce from "immer";
import { Reducer } from "redux";

export type LoginState =
  | {
      profileFetchStatus: "not-fetched";
      result: undefined;
      // When logging out, profileFetchStatus is set to "not-fetched" and loading is set to true.
      // Why?
      loading: boolean;
      showResendButton: boolean;
      redirectionRequire?: boolean;
      errorMessage?: string;
      loadingOne?: boolean;
    }
  | {
      profileFetchStatus: "fetched";
      result: LoginResponse;
      loading: false;
      showResendButton: boolean;
      redirectionRequire?: boolean;
      errorMessage?: string;
      loadingOne?: boolean;
    }
  | {
      profileFetchStatus: "fetching";
      result: undefined;
      loading: true;
      showResendButton: boolean;
      redirectionRequire?: boolean;
      errorMessage?: string;
      loadingOne?: boolean;
    };

export const initialLoginState: LoginState = {
  profileFetchStatus: "not-fetched",
  result: undefined,
  loading: false,
  showResendButton: false,
  redirectionRequire: false,
  errorMessage: "",
  loadingOne: false,
};

export const interfoldUserReducer: Reducer<LoginState> = (state = initialLoginState, action) =>
  produce(state, (d) => _interfoldUserReducer(d, action));

const _interfoldUserReducer: Reducer = (draft: LoginState, action) => {
  if (reviewDocumentUpload.match(action)) {
    draft.loadingOne = true;
    return;
  }
  if (reviewDocumentCompletedUpload.match(action)) {
    draft.result = { ...draft.result, reviews: action.payload.reviews } as LoginResponse;
    draft.loading = false;
    draft.loadingOne = false;
    draft.profileFetchStatus = "fetched";
    draft.showResendButton = false;
    return;
  }
  if (createQuestionCompletedAction.match(action)) {
    draft.result = { ...draft.result, loans: action.payload.loans } as LoginResponse;
    draft.loading = false;
    draft.loadingOne = false;
    draft.profileFetchStatus = "fetched";
    draft.showResendButton = false;
    return;
  }
};
