import { DocumentType, DocumentUploadStatus, EntityType } from "@prisma/client";
import { Cadence } from "prisma/schema";
import { DocumentTypeEnum } from "src/Enums/DocumentTypeEnum";
import { DocumentUploadInfo } from "src/models/DocumentStatusFrequency";
import { LoanDetails } from "src/models/Loan";
import { ReminderTypeEnum } from "src/Enums/ReminderTypeEnum";
import { DocumentRequestAttributes, DocumentUpload } from "./DocumentRequest";

// A "DocumentDetails" is actually
// a group of document requests
// for one given _intake form section_.
// (This is either an entity--one specific entity
// --or "Collateral".)
//
// In the UI, when you see an accordion where the titles are entity names,
// a DocumentDetails array is almost definitely where the data came from.
export type DocumentDetails = {
  // The entity id (again)? But what about "Collateral"?
  // TODO: figure out what this is for and remove if possible
  id: string;
  completed: boolean;
  // These are document requests.
  docStatus?: DocumentStatus[];
  type?: DocumentType;
  documentUploads?: DocumentUpload[];
} & (
  | {
      // Which section do these documents belong to,
      // or which section were these documents uploaded from?
      // Sentence case: "Borrower", "Guarantor", or "Collateral".
      item:
        | DocumentTypeEnum.BORROWER
        | DocumentTypeEnum.GUARANTOR
        | DocumentTypeEnum.OWNED_BY_GUARANTOR;

      // Whether the entity is an "INDIVIDUAL" or a "COMPANY".
      entityType: EntityType;
      entityName: string;
      entityId: number;
    }
  | {
      // Which section does these documents belong to,
      // or which section were these documents uploaded from?
      // Sentence case: "Borrower", "Guarantor", or "Collateral".
      item: DocumentTypeEnum.COLLATERAL;

      // If `item` is "Collateral",
      // there is no applicable entity,
      // so these fields are undefined.
      entityType?: undefined;
      entityName?: undefined;
      entityId?: number;
    }
);

// A "DocumentStatus" is a document request.
export interface DocumentStatus {
  // The document request's associated document_type.name.
  docType: string;

  // Some document types have a more specific "display name"
  // that we want to show in the UI;
  // this display name comes from the "attributes" column
  // of the document request itself.
  // For example, for Schedule K-1s,
  // the display name might be something like
  // "Schedule K-1s for Al's Pizza Company".
  // When the display name is not present,
  // we just show the document type name.
  displayName?: string;
  incompleteDocs: number;
  frequencies: DocumentUploadInfo[];
  cadence?: Cadence;
  documentRequestId?: string;
  notes?: string | null;
  senderId?: number;
  borrowerContactIds?: number[];
  loanNumbers?: string[];
  attributes: DocumentRequestAttributes;
  metadata?: Record<string, any>;
}

// In the "Edit reporting sequence" modal,
// each "DocumentDetails" has prefilled checkboxes.
// See: DocumentStatusWithCheckbox
export type DocumentDetailsWithCheckbox = DocumentDetails & {
  docStatus: DocumentStatusWithCheckbox[];
};
// Turn a group of DocumentDetails into a group of DocumentDetailsWithCheckbox
// with all checkboxes checked.
export const prefillAllCheckboxes = (
  documentDetails: DocumentDetails[],
): DocumentDetailsWithCheckbox[] => {
  return documentDetails?.map((dd) => ({
    ...dd,
    docStatus:
      dd.docStatus?.map((ds) => ({
        ...ds,
        required: true,
      })) || [],
  }));
};
// Add checkboxes to a group of DocumentDetails;
// a checkbox will be checked
// if the document request is part of the given annual review.
export const prefillCheckboxesFromAnnualReview = (
  documentDetails: DocumentDetails[],
  annualReview: LoanDetails,
): DocumentDetailsWithCheckbox[] => {
  return documentDetails.map((dd) => ({
    ...dd,
    docStatus:
      dd.docStatus?.map((relationshipDocumentStatus) => ({
        ...relationshipDocumentStatus,
        required: Object.values(ReminderTypeEnum).includes(
          relationshipDocumentStatus.docType as ReminderTypeEnum,
        )
          ? true
          : annualReview.documentRequests.some((dr) =>
              dr.docStatus?.some((annualReviewDocumentStatus) =>
                documentStatusesMatch(relationshipDocumentStatus, annualReviewDocumentStatus),
              ),
            ),
      })) || [],
  }));
};
// The DocumentStatuses in annual reviews are missing some properties
// that we need to compare them to DocumentStatuses
// in the full list of document details.
// TODO we should add a document request ID to DocumentStatus
const documentStatusesMatch = (value: DocumentStatus, other: DocumentStatus): boolean => {
  return value.docType === other.docType && Boolean(value.cadence?.periods?.[0]?.period);
};
// In the "Edit reporting sequence" modal,
// we want to prefill a checkbox next to each DocumentStatus
// based on whether the document request is already part of the annual review.
export interface DocumentStatusWithCheckbox extends DocumentStatus {
  required: boolean;
}

// Used to calculate the earliest Request Date
// Request date is derived from the `start` field
// in cadence.periods. This value is used in conjunction
// with isAnnualReview to determine if we show a timer icon
// or simply the clear icon.
export const getEarliestDocumentDueDate = (documentDetails: DocumentDetails[]): number => {
  const currentDate = new Date().getTime();
  let earliestDocumentDueDate = Infinity;
  documentDetails?.map((document) => {
    document.docStatus?.map((docStatus) => {
      earliestDocumentDueDate = Math.min(
        earliestDocumentDueDate,
        new Date(docStatus.cadence?.periods?.[0].start || currentDate).getTime(),
      );
    });
  });
  return earliestDocumentDueDate;
};

interface DocumentDetailsStatusCount {
  totalCurrentlyRequestedDocs: number;
  totalIncompleteDocs: number;
  totalNotVerifiedDocs: number;
  totalWaivedDocs: number;
}
export const countDocumentDetailsByStatus = (
  documentRequests: DocumentDetails[],
): DocumentDetailsStatusCount => {
  // Documents that are WAIVED, can not be counted in total docs
  const totalDocs = documentRequests.flatMap((r) =>
    r.docStatus?.flatMap((ds) =>
      ds.frequencies.filter((fr) => fr.status !== DocumentUploadStatus.WAIVED),
    ),
  ).length;

  // Documents that are WAIVED, can not be counted in incomplete docs
  const totalIncompleteDocs = documentRequests
    .flatMap((r) => r.docStatus)
    .reduce((total, item) => {
      const incompleteDocs = item?.frequencies
        .filter((fr) => fr.status !== DocumentUploadStatus.WAIVED)
        .reduce((count, frequency) => {
          if (frequency.rawDocumentUrl == null || frequency.rawDocumentUrl === "") {
            return count + 1;
          }
          return count;
        }, 0);
      return total + (incompleteDocs || 0);
    }, 0);

  // Documents that are WAIVED, can not be counted in verified docs
  const totalNotVerifiedDocs = documentRequests
    .flatMap((r) => r.docStatus)
    .reduce((total, item) => {
      const notVerifiedDocs = item?.frequencies
        .filter((fr) => fr.status !== DocumentUploadStatus.WAIVED)
        .reduce((count, frequency) => {
          if (frequency.status !== DocumentUploadStatus.VERIFIED) {
            return count + 1;
          }
          return count;
        }, 0);
      return total + (notVerifiedDocs || 0);
    }, 0);

  const totalWaivedDocs = documentRequests.flatMap((r) =>
    r.docStatus?.flatMap((ds) =>
      ds.frequencies.filter((fr) => fr.status === DocumentUploadStatus.WAIVED),
    ),
  ).length;

  return {
    totalCurrentlyRequestedDocs: totalDocs,
    totalIncompleteDocs,
    totalNotVerifiedDocs,
    totalWaivedDocs,
  };
};
