import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";
import { AutoRenderedSheetBuilderWithConfidence } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";

export const Form1120sIncomeStatementRowHeadings = {
  GrossRevenues: "Gross revenues",
  LessReturnsAndAllowances: "Less: Returns and allowances",
  NetRevenues: "Net revenues",
  LessCostOfGoodsSold: "Less: Cost of goods sold",
  GrossProfit: "Gross profit",
  NetGainOrLossFromForm4797: "Net gain or (loss) from form 4797, part II, line 17",
  OtherIncome: "Other income",
  TotalIncome: "Total income",
  CompensationOfOfficers: "Compensation of officers",
  SalariesAndWages: "Salaries and wages (less employment credits)",
  RepairsAndMaintenance: "Repairs and maintenance",
  BadDebts: "Bad debts",
  Rents: "Rents",
  TaxesAndLicenses: "Taxes and licenses",
  InterestExpense: "Interest expense",
  Depreciation: "Depreciation from form 4562 not claimed on form 1125-A or elsewhere",
  Depletion: "Depletion",
  Advertising: "Advertising",
  PensionAndProfitSharingPlans: "Pension, profit-sharing, etc., plans",
  EmployeeBenefitPrograms: "Employee benefit programs",
  EnergyEfficientCommercialBuildingsDeduction: "Energy efficient commercial buildings deduction",
  OtherDeductions: "Other deductions",
  TotalExpensesAndDeductions: "Total expenses and deductions",
  PreTaxProfitOrLoss: "Pre-tax profit or loss",
  TotalTax: "Total tax",
  NetIncome: "Net income",
};

export function build1120sIncomeStatement(
  renderableWithConfidence: RenderableWithConfidence<Form1120S>,
  startingRow: number,
  startingColumn: string,
): AutoRenderedSheetBuilder<Form1120S, typeof Form1120sIncomeStatementRowHeadings> {
  return AutoRenderedSheetBuilderWithConfidence.from<
    Form1120S,
    typeof Form1120sIncomeStatementRowHeadings
  >(renderableWithConfidence, Form1120sIncomeStatementRowHeadings, startingRow, startingColumn)
    .addRowFromData("GrossRevenues", (data) => data.income?.grossRevenue ?? 0)
    .addRowFromData(
      "LessReturnsAndAllowances",
      (data) => data.income?.lessReturnsAndAllowances ?? 0,
    )
    .addRowWithFormula(
      "NetRevenues",
      (finders) =>
        `=${finders.propertyReference("GrossRevenues")} - ${finders.propertyReference("LessReturnsAndAllowances")}`,
    )
    .addRowFromData("LessCostOfGoodsSold", (data) => data.income?.lessCostOfGoodsSold ?? 0)
    .addRowWithFormula(
      "GrossProfit",
      (finders) =>
        `=${finders.propertyReference("NetRevenues")} - ${finders.propertyReference("LessCostOfGoodsSold")}`,
    )
    .addRowFromData(
      "NetGainOrLossFromForm4797",
      (data) => data.income?.netGainOrLossFromForm4797 ?? 0,
    )
    .addRowFromData("OtherIncome", (data) => data.income?.otherIncome ?? 0)
    .addRowWithFormula(
      "TotalIncome",
      (finders) =>
        `=SUM(${finders.propertyReference("GrossProfit")}:${finders.propertyReference("OtherIncome")})`,
    )
    .addEmptyRow()
    .addRowFromData(
      "CompensationOfOfficers",
      (data) => data.deductions?.compensationOfOfficers ?? 0,
    )
    .addRowFromData("SalariesAndWages", (data) => data.deductions?.salariesAndWages ?? 0)
    .addRowFromData("RepairsAndMaintenance", (data) => data.deductions?.repairsAndMaintenance ?? 0)
    .addRowFromData("BadDebts", (data) => data.deductions?.badDebts ?? 0)
    .addRowFromData("Rents", (data) => data.deductions?.rents ?? 0)
    .addRowFromData("TaxesAndLicenses", (data) => data.deductions?.taxesAndLicenses ?? 0)
    .addRowFromData("InterestExpense", (data) => data.deductions?.interest ?? 0)
    .addRowFromData("Depreciation", (data) => data.deductions?.depreciation ?? 0)
    .addRowFromData("Depletion", (data) => data.deductions?.depletion ?? 0)
    .addRowFromData("Advertising", (data) => data.deductions?.advertising ?? 0)
    .addRowFromData(
      "PensionAndProfitSharingPlans",
      (data) => data.deductions?.pensionAndProfitSharingPlans ?? 0,
    )
    .addRowFromData(
      "EmployeeBenefitPrograms",
      (data) => data.deductions?.employeeBenefitPrograms ?? 0,
    )
    .addRowFromData(
      "EnergyEfficientCommercialBuildingsDeduction",
      (data) => data.deductions?.energyEfficientCommercialBuildingsDeduction ?? 0,
    )
    .addRowFromData("OtherDeductions", (data) => data.deductions?.otherDeductions ?? 0)
    .addRowWithFormula(
      "TotalExpensesAndDeductions",
      (finders) =>
        `=SUM(${finders.propertyReference("CompensationOfOfficers")}:${finders.propertyReference("OtherDeductions")})`,
    )
    .addRowWithFormula(
      "PreTaxProfitOrLoss",
      (finders) =>
        `=${finders.propertyReference("TotalIncome")} - ${finders.propertyReference("TotalExpensesAndDeductions")}`,
    )
    .addRowFromData("TotalTax", (data) => data.totalTax ?? 0)
    .addRowWithFormula(
      "NetIncome",
      (finders) =>
        `=${finders.propertyReference("PreTaxProfitOrLoss")} - ${finders.propertyReference("TotalTax")}`,
    );
}
