import { Reducer } from "redux";
import {
  fetchDocumentAndReminders,
  fetchDocumentAndRemindersCompleted,
  fetchDocumentAndRemindersFailed,
} from "src/redux/actions/document-reminder.action";
import { DocumentAndRemindersActions } from "src/redux/actions/actions.constants";
import {
  DOCUMENT,
  DocumentReminderOption,
  DocumentReminderOptionType,
  REMINDER,
} from "src/models/DocumentAndReminders";
import _ from "lodash";

export interface DocumentAndReminderState {
  isLoading: boolean;
  documents: DocumentReminderOption[];
  reminders: DocumentReminderOption[];
  error: string | null;
}

export const initialDocumentAndReminderState: DocumentAndReminderState = {
  isLoading: false,
  documents: [],
  reminders: [],
  error: null,
};

type DocumentAndReminderActionWrapper =
  | ReturnType<typeof fetchDocumentAndReminders>
  | ReturnType<typeof fetchDocumentAndRemindersCompleted>
  | ReturnType<typeof fetchDocumentAndRemindersFailed>;

const transformDocumentAndReminders = (
  documentOrReminders: DocumentReminderOption[],
  type: DocumentReminderOptionType,
) => {
  const typeIds = new Map();
  const uniqueTypes = [];

  for (const documentOrReminder of documentOrReminders) {
    const document = {
      id: documentOrReminder.id,
      value: `${type}-${documentOrReminder.id}`,
      label: documentOrReminder.name,
      type: type,
      name: documentOrReminder.name,
      duplicate_values: [],
    };

    if (!typeIds.has(document.label)) {
      typeIds.set(document.label, document);
      uniqueTypes.push(document);
    } else {
      typeIds.get(document.label).duplicate_values.push(`${type}-${documentOrReminder.id}`);
    }
  }

  return _.sortBy(uniqueTypes, "label");
};

export const documentAndReminderReducer: Reducer<DocumentAndReminderState> = (
  state = initialDocumentAndReminderState,
  action,
) => {
  const documentAndReminderAction = action as DocumentAndReminderActionWrapper;
  const { type } = documentAndReminderAction;
  switch (type) {
    case DocumentAndRemindersActions.FETCH_DOCUMENT_AND_REMINDERS:
      return {
        ...state,
        isLoading: true,
      };

    case DocumentAndRemindersActions.FETCH_DOCUMENT_AND_REMINDERS_COMPLETED: {
      const payload = documentAndReminderAction.payload;
      if ("documents" in payload && "reminders" in payload) {
        return {
          ...state,
          isLoading: false,
          documents: transformDocumentAndReminders(payload.documents, DOCUMENT),
          reminders: transformDocumentAndReminders(payload.reminders, REMINDER),
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    }

    case DocumentAndRemindersActions.FETCH_DOCUMENT_AND_REMINDERS_FAILED: {
      const errorPayload = documentAndReminderAction.payload as Error;
      return {
        ...state,
        isLoading: false,
        error: errorPayload.message,
      };
    }

    default:
      return state;
  }
};
