export type EscapedHtml = string & { __brand: "EscapedHtml" };

export function escapeHtml(str: string): EscapedHtml {
  const replacements: Record<string, string> = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "\n": "<br>",
  };

  return str.replace(/[&<>"'\n]/g, (char) => replacements[char]) as EscapedHtml;
}

export function EscapedHtml(strings: TemplateStringsArray, ...values: any[]): EscapedHtml {
  let result = "";

  for (let i = 0; i < strings.length; i++) {
    result += strings[i];
    if (i < values.length) {
      result += escapeHtml(String(values[i]));
    }
  }

  return result as EscapedHtml;
}
