import { baseApiService } from "src/services/api-services/BaseApiService";
import { convertAnnualReviewsToLoanCardData } from "src/services/loan-services/LoanService";

export class AnnualReviewService {
  static getInstance(): AnnualReviewService {
    return new AnnualReviewService();
  }

  async createAnnualReview(payload: any): Promise<any> {
    const annualReview = (await baseApiService.post(`/api/annual-review`, payload)).data;

    if (!annualReview) {
      return;
    }

    const response = convertAnnualReviewsToLoanCardData([annualReview]);

    return response[0];
  }
}
export const annualReviewService = AnnualReviewService.getInstance();
