import { DisplayLabel } from "src/utils/label";
import { z } from "zod";

export const Cents = z.number().int().nonnegative().brand<"Cents">();
export type Cents = z.infer<typeof Cents>;

export const Dollars = z.number().brand<"Dollars">();
export type Dollars = z.infer<typeof Dollars>;

export const centsFromDollars = (dollars: Dollars) => Cents.parse(dollars * 100);

// TODO: call Dollars.parse() once type safety in InvestmentDetailsCard improves
export const dollarsFromCents = (cents: Cents) => Dollars.safeParse(cents / 100);

export const formatDollars = (value: Dollars): DisplayLabel => {
  const dollars = Math.abs(value);
  switch (true) {
    case dollars >= 1_000_000_000 - 500_000:
      return `$${parseFloat((value / 1_000_000_000).toPrecision(3))}B`;
    case dollars >= 1_000_000 - 500:
      return `$${parseFloat((value / 1_000_000).toPrecision(3))}M`;
    case dollars >= 1_000 - 5:
      return `$${parseFloat((value / 1_000).toPrecision(3))}K`;
    default:
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(value);
  }
};
