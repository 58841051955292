import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  createNewLoan,
  getDraftLoansByLenderEmployeeId,
  loanService,
  updateExistingLoan,
} from "src/services/loan-services/LoanService";
import { LoanAction } from "src/redux/actions/actions.constants";
import {
  createLoanDataCompleted,
  createLoanDataFailed,
  createLoanEnded,
  deleteDraftLoansCompleted,
  deleteDraftLoansFailed,
  fetchDraftLoansCompleted,
  fetchDraftLoansFailed,
  updateLoanDataCompleted,
  updateLoanDataFailed,
} from "src/redux/actions/loan.action";
import { toastService } from "src/services/ToastService";
import {
  CreateOrUpdateLoanRequestType,
  UpdateLoanRequestType,
  LoanCreateOrUpdateResponse,
} from "src/contracts/loan";
import { Loan } from "src/models/Loan";
import { LoanStatus } from "@prisma/client";

export function* createLoan(action: {
  type: string;
  payload: CreateOrUpdateLoanRequestType;
}): Generator {
  try {
    const response = yield call(createNewLoan, action.payload);
    yield put(createLoanDataCompleted(response as LoanCreateOrUpdateResponse));
    yield put(createLoanEnded());
    toastService.showSuccess("New Loan Request Created");
  } catch (error) {
    toastService.showError("An error occurred while creating the loan request. Please try again.");
    yield put(createLoanDataFailed(new Error("Could not create loan. Something went wrong!")));
  }
}

export function* updateLoan(action: { type: string; payload: UpdateLoanRequestType }): Generator {
  try {
    const response = yield call(updateExistingLoan, action.payload);
    yield put(updateLoanDataCompleted(response as LoanCreateOrUpdateResponse));
    yield put(createLoanEnded());
    toastService.showSuccess("Loan Request Updated");
  } catch (error) {
    toastService.showError("An error occurred while updating the loan request. Please try again.");
    yield put(updateLoanDataFailed(new Error("Could not update loan. Something went wrong!")));
  }
}

export function* fetchDraftLoans(action: {
  type: string;
  payload: { lenderEmployeeId: number };
}): Generator {
  try {
    const response = yield call(getDraftLoansByLenderEmployeeId, action.payload.lenderEmployeeId);

    yield put(fetchDraftLoansCompleted(response as Loan[]));
  } catch (error) {
    yield put(
      fetchDraftLoansFailed(new Error("Could not fetch draft loans. Something went wrong!")),
    );
  }
}

export function* deleteDraftLoan(action: { type: string; payload: { loanId: number } }): Generator {
  try {
    yield call(loanService.updateLoanStatus, {
      loanId: action.payload.loanId,
      status: LoanStatus.DELETED,
    });
    yield put(deleteDraftLoansCompleted({ loanId: action.payload.loanId }));

    toastService.showSuccess("Draft loan deleted successfully");
  } catch (e: any) {
    yield put(
      deleteDraftLoansFailed(new Error("Could not delete draft loan. Something went wrong!")),
    );
  }
}

export function* loanSaga(): Generator {
  yield all([
    takeLatest(LoanAction.CREATE_LOAN, createLoan),
    takeLatest(LoanAction.UPDATE_LOAN, updateLoan),
    takeLatest(LoanAction.FETCH_DRAFT_LOANS, fetchDraftLoans),
    takeLatest(LoanAction.DELETE_DRAFT_LOAN, deleteDraftLoan),
  ]);
}

export default loanSaga;
