// This file contains the customer-user-facing questionnaire
// (known in the codebase as the "borrower intake form")
// for Interfold's pre-production CRE workflow.
// It is used both to generate the form on the frontend
// and to validate the answers on the backend.
//
// Warning! Make sure you don't import this file from src/models, because
// that will cause a circular dependency.
// Instead, if you want to make helper types or functions for this data,
// put them in src/data/helpers.ts (for now--we will make this more granular later.)
import { BorrowerIntakeForm, BorrowerIntakePage } from "src/models/BorrowerIntakePage";
import z from "zod";

export const entityTypes = [
  "LLC",
  "Partnership",
  "LLP",
  "C Corporation",
  "S Corporation",
  "Person/Household",
];

export const supplementalBorrowerQuestionId = "3448639a-11ab-406a-884b-1e4f78781670";

// used this for no duplication of uuids
export const overviewQuestionIds = {
  overview1QuestionId: "8bb04f28-b150-4eec-9928-7a08802c9403",
  overview2QuestionId: "8c658b7e-5103-4606-b3ec-6672ae0af732",
  overview3QuestionId: "c287819a-65c1-4038-c4bd-17d766550d60",
  overview4QuestionId: "8238af7c-76ee-11ee-b962-0242ac120002",
  overview2SubQuestion1Id: "9c858b7e-6103-4606-b3ec-6672ae0af594",
};

const sections = {
  overview: [
    {
      id: 1,
      page: 1,
      questions: [
        {
          id: overviewQuestionIds.overview1QuestionId,
          type: "single",
          title: "What entity will be borrowing the funds?",
          action: "create-borrower",
          validationSchema: {
            [overviewQuestionIds.overview1QuestionId]: z.object({
              "entity-name": z.string(),
              "entity-type": z.string(),
              fiscal_year_creation: z.string(),
            }),
          },
          inputs: [
            {
              type: "text",
              title: "Entity name",
              required: true,
              role: "entity-name",
              meta: {
                placeholder: "Entity name",
              },
            },
            {
              type: "dropdown",
              title: "Entity type",
              required: true,
              role: "entity-type",
              meta: {
                placeholder: "Entity type",
                options: entityTypes,
              },
            },
            {
              type: "radio",
              title: "Is the entity created within the current fiscal year?",
              required: true,
              role: "fiscal_year_creation",
              hidden: false,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      page: 2,
      questions: [
        {
          id: overviewQuestionIds.overview2QuestionId,
          type: "multiple",
          title: "What entity will be borrowing the funds?",
          action: "create-borrower-owners",
          actionLabel: "Add an owner",
          subtitle: /* dynamic */ "",
          dynamicSubQuestions: true,
          validationSchema: {
            [overviewQuestionIds.overview2SubQuestion1Id]: z.array(
              z.object({
                owner_name: z.string(),
                owner_type: z.string(),
              }),
            ),
          },
          subQuestions: [
            {
              id: overviewQuestionIds.overview2SubQuestion1Id,
              type: "single",
              title: "",
              action: "create-borrower-owners",
              actionLabel: "Add an owner",
              inputs: [
                {
                  type: "text",
                  title: "Owner",
                  role: "owner_name",
                  required: true,
                  meta: {
                    placeholder: "Owner",
                    width: "xl:5/12, 3/12",
                  },
                },
                {
                  title: "Owner type",
                  role: "owner_type",
                  type: "segments",
                  required: true,
                  meta: {
                    width: "xl:5/12, 4/12",
                    options: [
                      {
                        label: "BUSINESS",
                        value: "business",
                      },
                      {
                        label: "PERSON",
                        value: "Person/Household",
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 3,
      page: 3,
      questions: [
        {
          id: overviewQuestionIds.overview3QuestionId,
          type: "multiple",
          title: "Would you like to add additional parties to collaborate (e.g., accountant)?",
          action: "provide-entity-financials",
          actionLabel: "Add contact",
          showActionButtonOnce: true,
          toolTip: "To update the original providers, re-visit the previous screen",
          dynamicSubQuestions: true,
          subQuestions: [
            {
              id: "c287819a-65c1-4038-c4bd-17f766550d60",
              type: "single",
              title: "ABC Property Co.",
              icon: "business",
              availableOptions: {
                access: [{ value: "ABC Property Co.", label: "ABC Property Co." }],
              },
              action: "provide-entity-financials",
              inputs: [
                {
                  type: "text",
                  title: "Name",
                  required: true,
                  meta: {
                    placeholder: "Name",
                    width: "w-1/3, max-w-[264px]",
                  },
                  role: "name",
                },
                {
                  type: "text",
                  title: "Email address",
                  required: true,
                  meta: {
                    placeholder: "Email address",
                    width: "w-1/3, max-w-[264px]",
                  },
                  role: "email",
                },
                {
                  type: "dropdown",
                  title: "Role",
                  required: true,
                  meta: {
                    placeholder: "select",
                    width: "w-1/3, max-w-[264px]",
                    options: ["Owner", "Assistant", "Accountant", "Employee", "Broker"],
                  },
                  role: "role",
                },
                {
                  type: "checkbox-group",
                  title: "Access permissions:",
                  required: true,
                  role: "access",
                  meta: {
                    width: "full, max-w-[870px]",
                  },
                },
              ],
              prefilledValues: ["Jon Brown", "Matt Smith"],
              prefilledRole: ["access"],
            },
          ],
        },
      ],
    } as BorrowerIntakePage,
    {
      id: 4,
      page: 4,
      questions: [
        {
          id: overviewQuestionIds.overview4QuestionId,
          type: "single",
          title: "What will the funding be used for?",
          action: "update-loan", //TODO: UPDATE THE BACKEND API WITH THIS ACTION
          validationSchema: {
            [overviewQuestionIds.overview4QuestionId]: z.object({
              loan_description: z.string(),
            }),
          },
          inputs: [
            {
              type: "text-area",
              title: "Describe the uses of the funds with a few sentences.",
              required: true,
              role: "loan_description",
              meta: {
                placeholder: "",
              },
            },
          ],
        },
      ],
    },
  ],
  guarantors: [
    {
      id: 1,
      page: 1,
      questions: [
        {
          id: "3e3d66d5-7f1b-4937-bc54-b794a71914a2",
          type: "single",
          title: "Please provide 3 years of personal tax returns (Form 1040, federal tax return):",
          toolTip:
            "Provide full federal tax returns including all attached statements and schedules.",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 1,
              documentYear: "2022",
              title: "2022 | Tax return",
              required: true,
              role: "filepicker_input",
            },
            {
              type: "filepicker",
              documentTypeId: 2,
              documentYear: "2021",
              title: "2021 | Tax return",
              required: true,
              role: "filepicker_input",
            },
            {
              type: "filepicker",
              documentTypeId: 3,
              documentYear: "2020",
              title: "2020 | Tax return",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 2,
      page: 2,
      questions: [
        {
          id: "07a6e534-5097-493d-96e7-7839d6a9094a",
          type: "multiple",
          title: `For companies, partnerships, and business interests where you hold an ownership stake, please provide recent affiliated Schedule K-1's or corporate tax returns:`,
          toolTip:
            "Business interests provide sources of additional income and assets which typically increase your credit worthiness as a borrower. Owners typically receive a Schedule K-1 from the company during tax season.",
          subQuestions: [
            {
              id: "dbf579cc-2ccb-48f8-ac4c-6e223146c359",
              type: "single",
              title: "",
              inputs: [
                {
                  type: "text",
                  title: "Entity name",
                  required: true,
                  meta: {
                    width: "full",
                    placeholder: "Entity name",
                  },
                  role: "name",
                },
                {
                  type: "text",
                  title: "Ownership %",
                  required: true,
                  meta: {
                    width: "full",
                    placeholder: "%",
                  },
                  role: "ownership_percentage",
                },
                {
                  type: "filepicker",
                  documentTypeId: 4,
                  documentYear: "2023",
                  title: "Recent schedule K-1 or corporate tax return",
                  required: true,
                  role: "filepicker_input",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 3,
      page: 3,
      questions: [
        {
          id: "d35ea388-3db3-4512-8305-f1cb46111c51",
          type: "single",
          title:
            "<div>" +
            `Please provide an up-to-date Personal Financial Statement (<a href="https://www.bankunited.com/docs/default-source/sba-loans/bank-united-loan-information-packet" target="_blank">download bank template here</a>). We can also
          accept an up-to-date Personal Financial Statement prepared for another financial institution.` +
            "</div>",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 5,
              documentYear: "2023",
              title: "Personal financial statement",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 4,
      page: 4,
      questions: [
        {
          id: "fc5848b8-e25c-4ab6-9c5f-24bd9aef48a1",
          type: "single",
          title: "Please provide up-to-date bank statements for personal bank accounts:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 6,
              documentYear: "2023",
              title: "Personal bank statement",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 5,
      page: 5,
      questions: [
        {
          id: "db0ca0e3-d598-4ae4-9ee0-69ed10300dcf",
          type: "single",
          title: "Tell us more about XYZ Investment Corp.",
          inputs: [
            {
              type: "text",
              title: "Entity name",
              required: true,
              role: "entity-name",
              meta: {
                placeholder: "Entity name",
              },
            },
            {
              type: "dropdown",
              title: "Entity type",
              required: true,
              role: "entity-type",
              meta: {
                placeholder: "Entity type",
                options: [
                  "LLC",
                  "Partnership",
                  "LLP",
                  "C Corporation",
                  "S Corporation",
                  "Person/Household",
                ],
              },
            },
            {
              type: "radio",
              title: "Is the entity created within the current fiscal year?",
              required: true,
              role: "radio_input",
              hidden: false,
            },
          ],
        },
      ],
    },
    {
      id: 6,
      page: 6,
      questions: [
        {
          id: "821f5c0b-7328-4f20-ab94-f2bd13547790",
          type: "single",
          title: "Please provide the latest 3 years of corporate tax returns, as applicable:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 17,
              documentYear: "2022",
              title: "2022 | Tax return",
              required: true,
              role: "filepicker_input",
            },
            {
              type: "filepicker",
              documentTypeId: 18,
              documentYear: "2021",
              title: "2021 | Tax return",
              required: true,
              role: "filepicker_input",
            },
            {
              type: "filepicker",
              documentTypeId: 19,
              documentYear: "2020",
              title: "2020 | Tax return",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 7,
      page: 7,
      questions: [
        {
          id: "202ccbad-38ad-434b-9c1d-61b6f526bc59",
          type: "single",
          title: "Please provide a projected income (P&L) for the first year of operations:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 7,
              documentYear: "2023",
              title: "Projected income statement (P&L)",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 8,
      page: 8,
      questions: [
        {
          id: "99035b28-d33b-4377-aca2-5592348271e7",
          type: "single",
          title:
            "Please provide year-to-date (YTD) financial statements for the current year, and for the same period last year:",
          toolTip:
            "Provide year-to-date (YTD) financial statements for the current year, and for the same period last year.",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 8,
              documentYear: "2023",
              title: "2023 | YTD financial statements",
              required: true,
              role: "filepicker_input",
            },
            {
              type: "filepicker",
              documentTypeId: 9,
              documentYear: "2022",
              title: "2022 | Financial statements for the same period YTD",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
        {
          id: "a714d092-f249-4bd1-9080-1f05e4ef33ed",
          type: "single",
          title: "Please provide year-end financial statements for last year (as of December 31):",
          toolTip: "Please provide year-end financial statements for last year.",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 10,
              documentYear: "2022",
              title: "2022 | Year-end financial statements",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 9,
      page: 9,
      questions: [
        {
          id: "7b02be76-415e-4164-a185-964d54202ghf",
          type: "single",
          title: "Please list all debts associated with the borrower.",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 11,
              documentYear: "2023",
              title: "Business debt schedule",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
        {
          id: "77a2cd8c-a9f5-494d-b932-669a77d46bba",
          type: "multiple",
          title: "Or provide the details manually.",
          subQuestions: [
            {
              id: "77a2cd8d-a9f5-494d-b932-669a77d46bba",
              type: "single",
              title: "",
              inputs: [
                {
                  type: "text",
                  title: "Bank/lender",
                  required: true,
                  meta: {
                    placeholder: "Bank/lender name",
                    width: "1/4",
                  },
                  role: "bank_lender_role",
                },
                {
                  type: "text",
                  title: "Original loan balance",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "original_loan_role",
                },
                {
                  type: "text",
                  title: "Current balance due",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "current_balance_due",
                },
                {
                  type: "text",
                  title: "Interest rate",
                  required: true,
                  meta: {
                    placeholder: "%",
                    width: "1/4",
                  },
                  role: "intereset_rate_role",
                },
                {
                  type: "text",
                  title: "Payment amount",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "payment_amount_role",
                },
                {
                  type: "dropdown",
                  title: "Payment frequency",
                  required: true,
                  meta: {
                    options: ["Annually", "Biannually", "Quarterly", "Monthly", "Weekly"],
                    width: "1/4",
                    placeholder: "Select option",
                  },
                  role: "payment_frequency_role",
                },
                {
                  type: "date",
                  title: "Maturity date",
                  required: true,
                  meta: {
                    width: "1/4",
                  },
                  role: "maturity_date_role",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  collateral: [
    {
      id: 1,
      page: 1,
      pageActions: [
        {
          label: "Invite Collaborator",
          bgColor: "",
          section: "overview",
          questions: [],
          page: 3,
        },
      ],
      questions: [
        {
          id: "446b01f8-310d-404a-88d1-759d28a33310",
          type: "single",
          title: "Please list any equipment loans, leases, or payment plans:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 12,
              documentYear: "2023",
              title: "Equipment and/or vehicle loans or leases",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
        {
          id: "c285319a-e5c1-4038-a4ad-17f766550d62",
          type: "multiple",
          title: "Or provide the details manually.",
          dynamicSubQuestions: true,
          actionLabel: "Add loan",
          subQuestions: [
            {
              id: "23a6e534-5097-493d-96e7-7839d6a9056d",
              type: "single",
              title: "",
              inputs: [
                {
                  type: "text",
                  title: "Vehicle/equipment name",
                  required: true,
                  meta: {
                    placeholder: "Vehicle/equipment name",
                    width: "1/4",
                  },
                  role: "Vehicle/equipment name",
                },
                {
                  type: "text",
                  title: "Bank/lender",
                  required: true,
                  meta: {
                    placeholder: "Bank/lender name",
                    width: "1/4",
                  },
                  role: "Bank/lender",
                },
                {
                  type: "text",
                  title: "Original loan balance",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "Original loan balance",
                },
                {
                  type: "text",
                  title: "Current balance due",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "Current balance due",
                },
                {
                  type: "text",
                  title: "Interest rate",
                  required: true,
                  meta: {
                    placeholder: "%",
                    width: "1/4",
                  },
                  role: "Interest rate",
                },
                {
                  type: "text",
                  title: "Payment amount",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "Payment amount",
                },
                {
                  type: "dropdown",
                  title: "Payment frequency",
                  required: true,
                  meta: {
                    placeholder: "Select option",
                    options: ["Annually", "Bi-annually", "Quarterly", "Monthly", "Weekly"],
                    width: "1/4",
                  },
                  role: "Payment frequency",
                },
                {
                  type: "date",
                  title: "Maturity date",
                  required: true,
                  meta: {
                    width: "1/4",
                  },
                  role: "Maturity date",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      page: 2,
      pageActions: [
        {
          label: "Invite Collaborator",
          bgColor: "",
          section: "overview",
          questions: [],
          page: 3,
        },
      ],
      questions: [
        {
          id: "a250b04f-3486-4097-8a1e-068cd7b0fa7c",
          type: "single",
          title: "Please provide a purchase invoice for the equipment or vehicle(s):",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 13,
              documentYear: "2023",
              title: "Purchase invoice(s) for the equipment",
              required: true,
              role: "filepicker_input",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
  ],
  borrower: [
    {
      id: 1,
      page: 1,
      pageActions: [
        {
          label: "Invite Collaborator",
          bgColor: "",
          section: "overview",
          questions: [],
          page: 3,
        },
      ],
      questions: [
        {
          id: "4fdccd07-a2a3-4741-ba31-9acf19d4d1ca",
          type: "single",
          title: "Please provide the latest 3 years of corporate tax returns, as applicable:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 17,
              documentYear: "2022",
              title: "2022 | Tax return",
              required: true,
              role: "2022 | Tax return",
            },
            {
              type: "filepicker",
              documentTypeId: 18,
              documentYear: "2021",
              title: "2021 | Tax return",
              required: true,
              role: "2021 | Tax return",
            },
            {
              type: "filepicker",
              documentTypeId: 19,
              documentYear: "2020",
              title: "2020 | Tax return",
              required: true,
              role: "2020 | Tax return",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 2,
      page: 2,
      pageActions: [
        {
          label: "Invite Collaborator",
          bgColor: "",
          section: "overview",
          questions: [],
          page: 3,
        },
      ],
      questions: [
        {
          id: "22f24489-fc23-4c7b-9b1a-2b0542a024b7",
          type: "single",
          title:
            "Please provide a projected income statement (P&L) for the first year of operations:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 7,
              documentYear: "2023",
              title: "Projected income statement (P&L)",
              required: true,
              role: "Projected income statement (P&L)",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 3,
      page: 3,
      pageActions: [
        {
          label: "Invite Collaborator",
          bgColor: "",
          section: "overview",
          questions: [],
          page: 3,
        },
      ],
      questions: [
        {
          id: "53883954-13c4-4460-9bb1-24105cbf0b14",
          type: "single",
          title:
            "Please provide year to-date (YTD) financial statements (Income Statement, Balance Sheet) for the current year, and for the same period last year:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 8,
              documentYear: "2023",
              title: "2023 | YTD financial statements",
              required: true,
              // TODO: remove. Is this even being used?
              role: "2023 | YTD financial statements",
            },
            {
              type: "filepicker",
              documentTypeId: 9,
              documentYear: "2022",
              title: "2022 | Financial statements for the same period YTD",
              required: true,
              // TODO: remove. Is this even being used?
              role: "2022 | Financial statements for the same period YTD",
            },
          ],
          allFilePickers: true,
        },
        {
          id: "01c58a92-1f00-449c-8ad4-6d58a314c1a0",
          type: "single",
          title: "Please provide year-end financial statements for last year (as of December 31):",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 10,
              documentYear: "2022",
              title: "2022 | Year-end financial statements",
              required: true,
              // TODO: remove. Is this even being used?
              role: "2022 | Year-end financial statements (as of December 31, 2021)",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 4,
      page: 4,
      pageActions: [
        {
          label: "Invite Collaborator",
          bgColor: "",
          section: "overview",
          questions: [],
          page: 3,
        },
      ],
      questions: [
        {
          id: "e4ec8dae-2748-447e-a7d6-f98277be62a3",
          type: "single",
          title: "Please list all debts associated with the borrower.",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 11,
              documentYear: "2023",
              title: "Business debt schedule",
              required: true,
              role: "Business debt schedule",
            },
          ],
          allFilePickers: true,
        },
        {
          id: "1c97f058-1189-44a6-b9fa-18559ae4cdaf",
          type: "multiple",
          title: "Or provide the details manually.",
          dynamicSubQuestions: true,
          actionLabel: "Add loan",
          subQuestions: [
            {
              id: "1c97f058-1189-44a6-b9fa-29559ae4cdae",
              type: "single",
              title: "",
              inputs: [
                {
                  type: "text",
                  title: "Bank/lender",
                  role: "Bank/lender",
                  required: true,
                  meta: {
                    placeholder: "Bank/lender name",
                    width: "1/4",
                  },
                },
                {
                  type: "text",
                  title: "Original loan balance",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "Original loan balance",
                },
                {
                  type: "text",
                  title: "Current balance due",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "Current balance due",
                },
                {
                  type: "text",
                  title: "Interest rate",
                  required: true,
                  meta: {
                    placeholder: "%",
                    width: "1/4",
                  },
                  role: "Interest rate",
                },
                {
                  type: "text",
                  title: "Payment amount",
                  required: true,
                  meta: {
                    placeholder: "$",
                    width: "1/4",
                  },
                  role: "Payment amount",
                },
                {
                  type: "dropdown",
                  title: "Payment frequency",
                  required: true,
                  meta: {
                    placeholder: "Select option",
                    options: ["Annually", "Bi-annually", "Quarterly", "Monthly", "Weekly"],
                    width: "1/4",
                  },
                  role: "Payment frequency",
                },
                {
                  type: "date",
                  title: "Maturity date",
                  required: true,
                  meta: {
                    width: "1/4",
                  },
                  role: "Maturity date",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 5,
      page: 5,
      pageActions: [
        {
          label: "Invite Collaborator",
          bgColor: "",
          section: "overview",
          questions: [],
          page: 3,
        },
      ],
      questions: [
        {
          id: "5114e11d-f9eb-4472-9068-79af3ee302fd",
          type: "single",
          title: "Please provide the most recent accounts receivable and payable aging schedule:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 14,
              documentYear: "2023",
              title: "Most recent accounts receivable aging schedule",
              required: true,
              role: "Most recent accounts receivable aging schedule",
            },
            {
              type: "filepicker",
              documentTypeId: 15,
              documentYear: "2023",
              title: "Most recent accounts payable aging schedule",
              required: true,
              role: "Most recent accounts payable aging schedule",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
    {
      id: 6,
      page: 6,
      pageActions: [
        {
          label: "Invite Collaborator",
          bgColor: "",
          section: "overview",
          questions: [],
          page: 3,
        },
      ],
      questions: [
        {
          id: "63d6534c-53f3-498b-9dcc-ed581c9043d2",
          type: "single",
          title: "Provide the most recent Work in Process inventory report, if applicable:",
          inputs: [
            {
              type: "filepicker",
              documentTypeId: 16,
              documentYear: "2023",
              title: "Work in Process inventory report",
              required: true,
              role: "dummy_value",
            },
          ],
          allFilePickers: true,
        },
      ],
    },
  ],
} satisfies BorrowerIntakeForm;

export default sections;
