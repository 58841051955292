import { CustomCellEditorProps } from "ag-grid-react";
import React from "react";
import { TextInput } from "@mantine/core";
import { formulaBarSelector } from "src/redux/selectors/spread.selector";
import { useDispatch, useSelector } from "react-redux";
import { SetFormulaBar } from "src/redux/actions/spread.action";

export const HFCellEditor: React.FC<CustomCellEditorProps> = (params) => {
  const rowNode = params.node.data;
  const onCellValueChanged = params.onValueChange;
  const dispatch = useDispatch();
  const displayValue = useSelector(formulaBarSelector);

  if (!rowNode) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    dispatch(SetFormulaBar(newValue));
    const node = params?.node;
    const colId = params?.column?.getColId();
    const initialType = params.value?.type || "text";

    if (
      (initialType === "number" || initialType === "percentage" || initialType === "ratio") &&
      !newValue.startsWith("=")
    ) {
      const numericValue = Number(newValue);
      const updateValue = !isNaN(numericValue) ? numericValue : newValue;
      node.setDataValue(colId, { ...params.value, value: updateValue });
    } else {
      const updateGridObj = { ...params.value, value: newValue };
      node.setDataValue(colId, updateGridObj);
    }
    if (onCellValueChanged) {
      onCellValueChanged(newValue);
    }
  };

  return (
    <TextInput
      size="md"
      value={displayValue?.toString() ?? ""}
      onChange={handleChange}
      classNames={{
        root: "w-full h-full",
        input: "!border-none !rounded-none !p-0 !m-0 !bg-transparent",
      }}
      autoFocus
    />
  );
};
