/* eslint absolute-imports-only/only-absolute-imports: 0 */
import _ from "lodash";
import moment from "moment";
import { z } from "zod";
import { DocumentUploadStatus } from "@prisma/client";
import { RemotePath, RequestsByEntityByAnnualReview } from "cmd/push-files-to-sftp-server/main";
import {
  documentTypePeriodFromDbDocumentType,
  formatPeriodTitleFromFrequency,
} from "src/models/DocumentStatusFrequency";
import { statusTransitionMatrix } from "src/page-components/investment-detail/InvestmentActionTable/documentStatusHelper";
import { getDocumentPeriodText } from "src/models/DocumentRequest";
import { EscapedHtml, escapeHtml } from "src/utils/html";
import { RequestDocumentsByEmployeeDto } from "src/backend/dtos/document-request.dto";
import { formatDocumentTypeName } from "src/data/EntityToDocumentMapping";

// Enum for email templates
export enum EmailTemplate {
  INVITE_TO_COLLABORATE = "invite_to_collaborate",
  INVITE_TO_COLLABORATE_FOR_REVIEW = "invite_to_collaborate_for_review",
  DOCUMENT_REQUEST = "document_request",
  ANNUAL_REVIEW = "annual_review",
  LOAN_ORIGINATION = "loan_origination",
  FCN_SFTP = "fcn_sftp",
  DOCUMENT_STATUS_UPDATE_COLLECTED = "document_status_update_collected",
  DOCUMENT_STATUS_UPDATE_REVIEWED = "document_status_update_reviewed",
}

// Email payload Models
export const CollaborateInvitationModel = z.object({
  name: z.string().optional(),
  emailAddress: z.string().email(),
  role: z.string().optional(),
  companyName: z.string().optional(),
  senderName: z.string().optional(),
  code: z.string().optional(),
  domain: z.string().optional(),
  lenderId: z.number(),
});

export const CollaborateInvitationForReviewModel = z.object({
  name: z.string().optional(),
  emailAddress: z.string().email(),
  companyName: z.string().optional(),
  senderDetails: z.object({
    name: z.string().optional(),
    email: z.string(),
    employerName: z.string().optional(),
    employerLogo: z.string().optional(),
  }),
  code: z.string().optional(),
  domain: z.string().optional(),
});

export const DocumentRequestModel = z.object({
  emails: z.array(z.string().email()),
  documents: z.array(
    z.object({
      name: z.string(),
      year: z.number(),
      due_date: z.coerce.date(),
    }),
  ),
  loanId: z.number(),
  domain: z.string(),
});

export const AnnualReviewModel = z.object({
  body: z.string(),
  code: z.string(),
  documentsByEntityId: z.record(
    z.number(),
    z.array(
      z.object({
        documentRequestAttributes: z.any(),
        documentTypeName: z.string(),
        documentTypeYear: z.number(),
        entityName: z.string(),
        endDate: z.coerce.date(),
        startDate: z.coerce.date(),
        notificationCreatedOn: z.coerce.date().optional(),
      }),
    ),
  ),
  senderName: z.string(),
  senderLogoUrl: z.string(),
  senderTitle: z.string(),
  senderCustom: z.record(z.string(), z.any()),
  domain: z.string(),
  showDocumentDueDates: z.boolean(),
});

export const LoanOriginationModel = z.object({
  body: z.string(),
  code: z.string(),
  documentsByEntityId: z.record(
    z.number(),
    z.array(
      z.object({
        documentRequestAttributes: z.any(),
        documentTypeName: z.string(),
        documentTypeYear: z.number(),
        entityName: z.string(),
        dueDate: z.coerce.date().optional(),
      }),
    ),
  ),
  senderName: z.string(),
  senderLogoUrl: z.string(),
  senderTitle: z.string(),
  senderCustom: z.record(z.string(), z.any()),
  domain: z.string(),
  showDocumentDueDates: z.boolean(),
});

export const DocumentUploadStatusModel = z.object({
  documentStatus: z.nativeEnum(DocumentUploadStatus),
  cutOffDate: z.string(),
  loanOrReviewList: z.array(
    z.object({
      type: z.union([z.literal("loan"), z.literal("annual_review")]),
      borrowers: z.string(),
      entities: z.array(
        z.object({
          entityName: z.string(),
          documents: z.array(
            z.object({
              documentType: z.string(),
              documentYear: z.number(),
            }),
          ),
        }),
      ),
    }),
  ),
});

// TODO
export const FcnSftpModel = z.object({});

export type FcnSftpPayload = {
  requestsByEntityByAnnualReviewForLenderEmployee: RequestsByEntityByAnnualReview;
  remotePathByDocumentUploadId: Record<number, RemotePath[]>;
  cifByEntityId: Record<number, string>;
};

// Payload and Email type Mapping
export const templatePayloadMapping: Record<EmailTemplate, any> = {
  [EmailTemplate.INVITE_TO_COLLABORATE]: CollaborateInvitationModel,
  [EmailTemplate.INVITE_TO_COLLABORATE_FOR_REVIEW]: CollaborateInvitationForReviewModel,
  [EmailTemplate.LOAN_ORIGINATION]: LoanOriginationModel,
  [EmailTemplate.DOCUMENT_REQUEST]: DocumentRequestModel,
  [EmailTemplate.ANNUAL_REVIEW]: AnnualReviewModel,
  [EmailTemplate.FCN_SFTP]: FcnSftpModel,
  [EmailTemplate.DOCUMENT_STATUS_UPDATE_COLLECTED]: DocumentUploadStatusModel,
  [EmailTemplate.DOCUMENT_STATUS_UPDATE_REVIEWED]: DocumentUploadStatusModel,
};

// Consolidating our subject lines to one place to ensure uniformity and a single source of truth.
// Any updates to the subject lines should be made here.
export function getEmailSubjectLine(template: EmailTemplate, arg?: string): string {
  // We can override subject line with environment variables
  const envName = `SUBJECT_LINE_${template.toUpperCase()}`;

  // TODO(pras): See if there is better way than ts-ignore here
  // @ts-ignore
  const subjectLinePlaceholder = process.env[envName] || "";
  if (subjectLinePlaceholder) {
    return subjectLinePlaceholder.replace("{{arg}}", arg || "");
  }

  switch (template) {
    case EmailTemplate.INVITE_TO_COLLABORATE: {
      return "Invite to Collaborate";
    }
    case EmailTemplate.LOAN_ORIGINATION: {
      return `Loan request for ${arg}`;
    }
    case EmailTemplate.DOCUMENT_REQUEST: {
      return "Request to Upload Document(s)";
    }
    case EmailTemplate.ANNUAL_REVIEW: {
      return `Action required: upload new documents for ${arg}`;
    }
    case EmailTemplate.FCN_SFTP: {
      return `Interfold file share for ${arg}`;
    }
    case EmailTemplate.INVITE_TO_COLLABORATE_FOR_REVIEW: {
      return `Invited to collaborate: ${arg} via Interfold`;
    }
    case EmailTemplate.DOCUMENT_STATUS_UPDATE_COLLECTED: {
      return `New documents uploaded - Interfold: ${arg}`;
    }
    case EmailTemplate.DOCUMENT_STATUS_UPDATE_REVIEWED: {
      return `New documents reviewed - Interfold: ${arg}`;
    }
  }
}

export const LOAN_DEFAULT_TEMPLATE_NAMES = {
  FIRST: "FIRST",
  SECOND: "SECOND",
  THIRD: "THIRD",
};

export const ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES = {
  FIRST: "FIRST",
  SECOND: "SECOND",
  THIRD: "THIRD",
  FOURTH: "FOURTH",
  FIFTH: "FIFTH",
};

// TODO: The product ask here is to include the first name in this message
// we are unable to do that at the moment but should implement it in the future.
// https://linear.app/interfold/issue/INT-804/update-e-mail-content
export const AnnualReviewDefaultTemplates = {
  [ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.FIRST]: `Hello,

It's that time of year again where we need to collect updated financials to keep our files current here at the Bank. We recommend having the following documents ready - a full list is included below.

If your tax returns are on extension, please contact us so we can anticipate when it can be received.

We can collaborate using the secure portal here. Please make sure to sign all provided documents including tax returns and financials (on the front page) as these are your financial representation that the bank is depending on. If you would like your accountant to provide the files directly, please share their name and e-mail address with us to add them as a collaborator.`,
  [ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.SECOND]: `Hello,

Sending over a reminder that we will need the following documents as part of the regular bank process. Please note the due dates listed and let us know if you have any questions or concerns.

Use your personal link here to provide the files. If you would like your accountant to provide the files directly, please share their name and e-mail address with us to add them as a collaborator.`,
  [ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.THIRD]: `Hello,

Checking in on the below documents - please upload the below documents by the due dates listed.

Don't hesitate to reach out if you have any questions or concerns. As a reminder, these documents are a requirement as part of the loan agreement and you may enter default if the documents are not provided on a timely basis.

Use your personal link here to provide the files. If you would like your accountant to provide the files directly, please share their name and e-mail address with us to add them as a collaborator.`,
  [ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.FOURTH]: `Hello,

Your documents are coming due soon, on the due dates listed below. As a reminder, these documents are required as part of the loan agreement and must be provided promptly. You may enter technical default if the documents are not provided on time.

Use your personal link here to provide the files. If you would like your accountant to provide the files directly, please share their name and e-mail address with us to add them as a collaborator.`,
  [ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.FIFTH]: `Hello,

The financial reporting required in your loan documents is now past due. It is essential that we receive updated financial information to protect the Bank and better serve your needs. If there is some reason you are unable to provide the requested information at this time, please contact us promptly to discuss the situation. The lack of updated financial reporting is a technical default under your loan agreements.

Please upload the files to resolve the issue.`,
};

const TEMPLATE_DEFAULT = "Default template";

export type AnnualReviewMessagingCadence = {
  message: string;
  remainder: string;
};

export type AnnualReviewMessagingCadenceObject = {
  [key: string]: AnnualReviewMessagingCadence[];
};

export type LoanOriginationMessageCadence = {
  message: string;
  remainder: string;
};

export type LoanOriginationMessageCadenceObject = {
  [key: string]: LoanOriginationMessageCadence[];
};

export const ANNUAL_REVIEW_DEFAULT_CADENCE: AnnualReviewMessagingCadenceObject = {
  [TEMPLATE_DEFAULT]: [
    {
      message: AnnualReviewDefaultTemplates[ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.FIRST],
      remainder: "",
    },
    {
      message: AnnualReviewDefaultTemplates[ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.SECOND],
      remainder: "7",
    },
    {
      message: AnnualReviewDefaultTemplates[ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.THIRD],
      remainder: "7",
    },
    {
      message: AnnualReviewDefaultTemplates[ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.FOURTH],
      remainder: "7",
    },
    {
      message: AnnualReviewDefaultTemplates[ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.FIFTH],
      remainder: "7",
    },
  ],
};

export const LoanOriginationDefaultTemplates = {
  [LOAN_DEFAULT_TEMPLATE_NAMES.FIRST]: `Hello,

Nice connecting today to talk about your loan request. Looking forward to working with you and getting you funded.

We can collaborate using the secure portal below. Access your personal link below.

To get the ball rolling, you will want to gather the following documents:`,
  [LOAN_DEFAULT_TEMPLATE_NAMES.SECOND]: `Hello,

Checking in, how are these things going?

As a reminder, here is a link to the portal and the requisite documents. Feel free to let me know if you have any questions or if I can be helpful in any way!`,
  [LOAN_DEFAULT_TEMPLATE_NAMES.THIRD]: `Sending over a quick reminder - we will need these documents to get going with your funding.

Please take a look below:`,
};

export const LOAN_ORIGINATION_DEFAULT_CADENCE: LoanOriginationMessageCadenceObject = {
  [TEMPLATE_DEFAULT]: [
    {
      message: LoanOriginationDefaultTemplates[LOAN_DEFAULT_TEMPLATE_NAMES.FIRST],
      remainder: "",
    },
    {
      message: LoanOriginationDefaultTemplates[LOAN_DEFAULT_TEMPLATE_NAMES.SECOND],
      remainder: "5",
    },
    {
      message: LoanOriginationDefaultTemplates[LOAN_DEFAULT_TEMPLATE_NAMES.THIRD],
      remainder: "7",
    },
  ],
};
// Sanitizers used to render email templates to the lender and keep the original message.
export const messageSanitizer: {
  [key: string]: {
    sanitizedValue: (arg: string) => string;
    displayValue: (arg: string, templateName?: string) => string;
  };
} = {
  [EmailTemplate.ANNUAL_REVIEW]: {
    sanitizedValue: (msg: string): string => {
      const lines = msg.split("\n");
      const idx = lines.findIndex((item) => item === "${link}");
      return lines.slice(0, (lines.length - idx) * -1).join("\n");
    },
    displayValue: (msg: string, templateName?: string): string => {
      if (templateName === ANNUAL_REVIEW_DEFAULT_TEMPLATE_NAMES.FIFTH) {
        return msg + "\n\n${emailSignature}";
      }
      return msg + "\n${link}\n\n${documentChecklist}\n\n${emailSignature}";
    },
  },
  [EmailTemplate.DOCUMENT_REQUEST]: {
    sanitizedValue: (msg: string): string => {
      return msg;
    },
    displayValue: (msg: string): string => {
      return msg;
    },
  },
  [EmailTemplate.FCN_SFTP]: {
    sanitizedValue: (msg: string): string => {
      return msg;
    },
    displayValue: (msg: string): string => {
      return msg;
    },
  },
  [EmailTemplate.INVITE_TO_COLLABORATE]: {
    sanitizedValue: (msg: string): string => {
      return msg;
    },
    displayValue: (msg: string): string => {
      return msg;
    },
  },
  [EmailTemplate.LOAN_ORIGINATION]: {
    sanitizedValue: (msg: string): string => {
      const lines = msg.split("\n");
      const idx = lines.findIndex((item) => item === "${link}");
      return lines.slice(0, (lines.length - idx) * -1).join("\n");
    },
    displayValue: (msg: string, _templateName?: string): string => {
      return msg + "\n${link}\n\n${documentChecklist}\n\n${emailSignature}";
    },
  },
  [EmailTemplate.INVITE_TO_COLLABORATE_FOR_REVIEW]: {
    sanitizedValue: (msg: string): string => {
      return msg;
    },
    displayValue: (msg: string): string => {
      return msg;
    },
  },
};

function getDeduplicatedDocumentUnorderedListString(
  documents: {
    documentTypeName: string;
    documentTypeYear: number;
    entityName: string;
    endDate: Date;
    startDate: Date;
    documentRequestAttributes?: any;
    notificationCreatedOn?: Date | undefined;
  }[],
  showDocumentDueDates: boolean,
): string[] {
  const deDupedItems = new Set<string>();
  for (const d of documents) {
    // If an email has already been sent, this is the date of that email.
    // Otherwise, this is today's date.
    const customerUserFirstNotifiedOn = moment(d.notificationCreatedOn ?? new Date()).utc();
    // For documents in the past,
    // the date this document is "due" from the borrower's perspective
    // isn't the same as the "end date" in the lender view;
    // from their perspective, the clock starts ticking on the day they receive this email,
    // and they have 30 days to upload the document.
    //
    // This is especially important for the first email,
    // which (unique among the annual review emails) is sent for all document requests
    // in the past, no matter how far back.
    // We don't want to show these docs as overdue.
    // (For example, many of FCN's docs were due in April 2023,
    // but we first onboarded them and ran the monitoring job in September 2023.)
    const dueDate = customerUserFirstNotifiedOn.isAfter(d.startDate)
      ? customerUserFirstNotifiedOn.clone().add(1, "month")
      : moment.utc(d.endDate);
    const dueDateLabel = showDocumentDueDates ? ` (due ${dueDate.format("MM/DD/YYYY")})` : "";

    const documentType = {
      year: d.documentTypeYear,
      ...(typeof d.documentRequestAttributes === "object" ? d.documentRequestAttributes : {}),
    };
    const item = escapeHtml(
      `${getDocumentPeriodText({
        ...documentType,
        name: d.documentTypeName,
      })} ${_.get(
        d,
        "documentRequestAttributes.displayName",
        formatDocumentTypeName(d.documentTypeName),
      )}`.trimStart(),
    );
    deDupedItems.add(`${item}${dueDateLabel}`);
  }
  return [...deDupedItems];
}

// TODO: We can modify this later when we implement actual email templates
// Rendering HTML data as per email template
export async function getEmailTemplate(
  template: EmailTemplate,
  payload: z.infer<(typeof templatePayloadMapping)[EmailTemplate]>,
) {
  switch (template) {
    // TODO: THIS IS LEFT
    case EmailTemplate.INVITE_TO_COLLABORATE: {
      const personalLink = `${payload.domain}/?c=${payload.code}`;
      return EscapedHtml`Hi ${payload.name},<br><br>${payload.senderName} is inviting you to collaborate on the loan request for ${payload.companyName}.<br><br>Access your personal link here: <a href="${escapeHtml(personalLink)}">${personalLink}</a>`;
    }
    case EmailTemplate.INVITE_TO_COLLABORATE_FOR_REVIEW: {
      const personalLink = `${payload.domain}/?c=${payload.code}`;
      const companyMessage = payload.companyName ? ` for ${payload.companyName}` : "";
      return EscapedHtml`Hi ${payload.name},<br><br>${payload.senderDetails.name} from ${payload.senderDetails.employerName} is inviting you to collaborate on the request${companyMessage}.<br><br>Access your personal link here: <a href="${escapeHtml(personalLink)}">${personalLink}</a><br><br>Best,<br>${payload.senderDetails.name}<br><br><img src="${payload.senderDetails.employerLogo}" alt="Lender Logo" width="100">`;
    }
    case EmailTemplate.DOCUMENT_REQUEST: {
      return EscapedHtml`Request to upload the following documents with reference to loanId: ${
        payload.loanId
      }.${payload.documents.map((doc: RequestDocumentsByEmployeeDto, i: number) => {
        return `${i + 1}. ${doc.name} for the year ${doc.year} (before ${moment(
          doc.due_date,
        ).format("YYYY-MM-DD")}).`;
      })}
    `;
    }
    case EmailTemplate.ANNUAL_REVIEW: {
      const p = payload as z.infer<typeof AnnualReviewModel>;
      const showDocumentDueDates = p.showDocumentDueDates;
      const loginUrl = `${p.domain}/?c=${p.code}`;
      const linkHtml = `<a href="${escapeHtml(loginUrl)}">${escapeHtml(loginUrl)}</a>`;
      const entriesArray = Object.entries(p.documentsByEntityId);
      entriesArray.sort((a, b) => {
        // entityName present in all entries, we are just picking the first one to take it out
        return a[1][0].entityName.localeCompare(b[1][0].entityName);
      });
      const documentListHtml = entriesArray.reduce((result, [_entityId, documents]) => {
        return (
          result +
          `<h4 style="padding-bottom: 0; margin-bottom: 0;">For ${escapeHtml(
            documents[0].entityName,
          )}:</h4>` +
          `<ul style="padding-top: 0; margin-top: 0;">${getDeduplicatedDocumentUnorderedListString(
            documents,
            showDocumentDueDates,
          )
            .map((d) => `<li>${d}</li>`)
            .join("")}</ul>`
        );
      }, "");

      const customFieldsHtml = p.senderCustom
        ? Object.entries(p.senderCustom)
            .map(([key, value]) => `${escapeHtml(`${key}: ${value}`)}<br>`)
            .join("")
        : "";

      const emailSignatureComponents = [
        "Best,",
        p.senderName,
        p.senderTitle,
        customFieldsHtml,
        `<br><img src="${p.senderLogoUrl}" alt="Bank Logo" width="100">`,
      ]
        .filter(Boolean)
        .join("<br>");

      return `${escapeHtml(p.body)}${linkHtml ? `<br>${linkHtml}` : ""}${
        documentListHtml && documentListHtml.length ? `<br>${documentListHtml}` : ""
      }${emailSignatureComponents ? `<br>${emailSignatureComponents}` : ""}` as EscapedHtml;
    }
    case EmailTemplate.LOAN_ORIGINATION: {
      const p = payload as z.infer<typeof LoanOriginationModel>;
      const showDocumentDueDates = p.showDocumentDueDates;
      const loginUrl = `${p.domain}/?c=${p.code}`;
      const linkHtml = `<a href="${escapeHtml(loginUrl)}">${escapeHtml(loginUrl)}</a>`;
      const documentListHtml = Object.entries(p.documentsByEntityId).reduce(
        (result, [_entityId, documents]) => {
          return (
            result +
            `<h4 style="padding-bottom: 0; margin-bottom: 0;">For ${escapeHtml(
              documents[0].entityName,
            )}:</h4>` +
            `<ul style="padding-top: 0; margin-top: 0;">${documents
              .map((d) => {
                const dueDate = d.dueDate ? moment.utc(d.dueDate) : null;
                const dueDateLabel =
                  showDocumentDueDates && dueDate ? ` (due ${dueDate.format("MM/DD/YYYY")})` : "";

                const documentType = {
                  year: d.documentTypeYear,
                  ...(typeof d.documentRequestAttributes === "object"
                    ? d.documentRequestAttributes
                    : {}),
                };
                return `<li>${escapeHtml(
                  `${getDocumentPeriodText({
                    ...documentType,
                    name: d.documentTypeName,
                  })} ${_.get(
                    d,
                    "documentRequestAttributes.displayName",
                    formatDocumentTypeName(d.documentTypeName),
                  )}`.trimStart(),
                )}${dueDateLabel}</li>`;
              })
              .join("")}</ul>`
          );
        },
        "",
      );

      const customFieldsHtml = p.senderCustom
        ? Object.entries(p.senderCustom)
            .map(([key, value]) => `${escapeHtml(`${key}: ${value}`)}<br>`)
            .join("")
        : "";

      const emailSignatureComponents = [
        "Best,",
        p.senderName,
        p.senderTitle,
        customFieldsHtml,
        `<br><img src="${p.senderLogoUrl}" alt="Bank Logo" width="100">`,
      ]
        .filter(Boolean)
        .join("<br>");

      return `${escapeHtml(p.body)}${linkHtml ? `<br>${linkHtml}` : ""}${
        documentListHtml && documentListHtml.length ? `<br>${documentListHtml}` : ""
      }${emailSignatureComponents ? `<br>${emailSignatureComponents}` : ""}` as EscapedHtml;
    }
    case EmailTemplate.FCN_SFTP: {
      const { remotePathByDocumentUploadId, requestsByEntityByAnnualReviewForLenderEmployee } =
        payload as FcnSftpPayload;
      let annualReviewListHtml = "";
      for (const { requestEntities, entities } of Object.values(
        requestsByEntityByAnnualReviewForLenderEmployee,
      )) {
        const annualReviewForLabel = entities
          .filter((e) =>
            requestEntities.some((re) => re.role === "BORROWER" && re.entityId === e.id),
          )
          .map((e) => e.name)
          .join(", ");

        annualReviewListHtml += `<h2 style="padding-bottom: 0; margin-bottom: 0;">Annual review for ${annualReviewForLabel}</h2>`;

        entities.forEach(({ name, documentRequests, cifLabel }) => {
          const entityCifLabel = cifLabel ? ` (CIF: ${cifLabel})` : "";

          const skipEntity = documentRequests.every(({ request }) => {
            let skipRequest = false;
            if (
              typeof request.cadence === "object" &&
              request.cadence !== null &&
              "periods" in request.cadence
            ) {
              const startDate = moment(
                (request.cadence as { [key: string]: any })["periods"][0]?.start,
              );
              if (startDate.isValid() && startDate.isAfter(moment())) {
                skipRequest = true;
              }
            }
            return skipRequest;
          });

          if (skipEntity) {
            return; // foreach entity
          }

          annualReviewListHtml += `<h4 style="padding-bottom: 0; margin-bottom: 0;">For ${escapeHtml(
            name,
          )}${escapeHtml(entityCifLabel)}:</h4>`;

          annualReviewListHtml += '<ul style="padding-top: 0; margin-top: 0;">';
          documentRequests.forEach(({ request, upload }) => {
            // Format of each <li>:
            //  - 2022 Personal tax return (due 09/22/2023) Received (file: basename.pdf)

            const periodLabel = formatPeriodTitleFromFrequency(
              documentTypePeriodFromDbDocumentType(request.type),
            );

            const documentTypeNameLabel = request.type.name;

            let dueLabel = "";
            if (
              typeof request.cadence === "object" &&
              request.cadence !== null &&
              "end" in request.cadence
            ) {
              const endDate = (request.cadence as { [key: string]: any })["end"];
              if (typeof endDate === "string") {
                const finalDueDate = moment(endDate);

                if (finalDueDate.isValid()) {
                  dueLabel = `(due ${finalDueDate.format("MM/DD/YYYY")})`;
                }
              }
            }

            let effectiveUpload = upload;
            // If upload is null
            // and some other request in this review with the same type has a non-null upload
            // and that upload has a remote path whose cif matches this entity's cif,
            // set upload to that non-null upload.
            if (
              upload === null &&
              entities
                .flatMap((e) => e.documentRequests)
                .some(
                  (dr) =>
                    dr.request.type.id === request.type.id &&
                    dr.upload !== null &&
                    remotePathByDocumentUploadId[dr.upload.id]?.some((rp) => rp.cif === cifLabel),
                )
            ) {
              effectiveUpload =
                entities
                  .flatMap((e) => e.documentRequests)
                  .find(
                    (dr) =>
                      dr.request.type.id === request.type.id &&
                      dr.upload !== null &&
                      remotePathByDocumentUploadId[dr.upload.id]?.some((rp) => rp.cif === cifLabel),
                  )?.upload ?? null;
            }

            const statusLabel =
              statusTransitionMatrix[effectiveUpload?.status ?? DocumentUploadStatus.REQUESTED]
                ?.label ?? "";

            const statusLabelHtml =
              statusLabel === "Pending"
                ? `<span style="color: orange;">${statusLabel}</span>`
                : `<span style="color: green;">${statusLabel}</span>`;

            const filenameLabel = (() => {
              if (effectiveUpload?.id && remotePathByDocumentUploadId[effectiveUpload.id]) {
                const uploadedBasenamesWithMyCif = remotePathByDocumentUploadId[effectiveUpload.id]
                  .filter((rp) => rp.cif === cifLabel)
                  .map((rp) => rp.basename);

                if (uploadedBasenamesWithMyCif.length < 2) {
                  return `(file: ${uploadedBasenamesWithMyCif[0]})`;
                } else {
                  return `(files: ${uploadedBasenamesWithMyCif.join(", ")})`;
                }
              }
              return "";
            })();

            let skipRequest = false;
            if (
              typeof request.cadence === "object" &&
              request.cadence !== null &&
              "periods" in request.cadence
            ) {
              const startDate = moment(
                (request.cadence as { [key: string]: any })["periods"][0]?.start,
              );
              if (startDate.isValid() && startDate.isAfter(moment())) {
                skipRequest = true;
              }
            }

            if (!skipRequest) {
              const documentRequestLabels = [
                escapeHtml(periodLabel),
                escapeHtml(documentTypeNameLabel),
                escapeHtml(dueLabel),
                statusLabelHtml,
                escapeHtml(filenameLabel),
              ]
                .filter((str) => str !== "")
                .join(" ");
              annualReviewListHtml += `<li>${documentRequestLabels}</li>`;
            }
          });
          annualReviewListHtml += "</ul>";
        });
      }

      return `New documents have been uploaded for the following reviews:${
        "\n" + annualReviewListHtml
      }` as EscapedHtml;
    }
    case EmailTemplate.DOCUMENT_STATUS_UPDATE_COLLECTED:
    case EmailTemplate.DOCUMENT_STATUS_UPDATE_REVIEWED: {
      const incoming = payload as z.infer<typeof DocumentUploadStatusModel>;
      let documentStatusUpdateHtml = "";
      for (const { type, borrowers, entities } of incoming.loanOrReviewList) {
        const loanOrReview = type === "loan" ? "Loan" : "Annual Review";
        documentStatusUpdateHtml += `<h2 style="padding-bottom: 0; margin-bottom: 0;">${loanOrReview} for ${escapeHtml(
          borrowers,
        )}</h2>`;
        documentStatusUpdateHtml += "<br/>";
        entities.forEach((e) => {
          documentStatusUpdateHtml += `<h4 style="padding-bottom: 0; margin-bottom: 0;">For ${escapeHtml(
            e.entityName,
          )}:</h4>`;
          documentStatusUpdateHtml += '<ul style="padding-top: 0; margin-top: 0;">';
          e.documents.forEach((d) => {
            documentStatusUpdateHtml += `<li>${d.documentYear} ${d.documentType}</li>`;
          });
          documentStatusUpdateHtml += "</ul>";
        });
      }
      return `Documents in ${incoming.documentStatus} status on or after ${incoming.cutOffDate}:${
        "\n\n" + documentStatusUpdateHtml
      }` as EscapedHtml;
    }
  }
}
