import { DocumentRequestWithUploadsAndSchedule } from "src/backend/services/document-request";
import { baseApiService } from "src/services/api-services/BaseApiService";

export class DocumentService {
  static getInstance(): DocumentService {
    return new DocumentService();
  }

  async fetchAllDocuments(
    entityIds: number[],
  ): Promise<Record<string, DocumentRequestWithUploadsAndSchedule[]>> {
    const params = new URLSearchParams();
    entityIds.forEach((entityId) => {
      params.append("entityIds", entityId.toString());
    });

    const returnedDocuments: Record<string, DocumentRequestWithUploadsAndSchedule[]> = (
      await baseApiService.get(`/api/documents?${params.toString()}`)
    ).data;

    return returnedDocuments;
  }
}

export const documentService = DocumentService.getInstance();
