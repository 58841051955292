class LocalStorageService {
  private static _instance: LocalStorageService;

  static getInstance(): LocalStorageService {
    if (!this._instance) {
      this._instance = new LocalStorageService();
    }
    return this._instance;
  }

  isKeyPresent(key: string): boolean {
    return key in localStorage;
  }

  deleteValue(key: string): void {
    if (this.isKeyPresent(key)) {
      localStorage.removeItem(key);
    }
  }

  setValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  getValue(key: string): string | null {
    if (this.isKeyPresent(key)) {
      return localStorage.getItem(key);
    }
    return null;
  }
}

export const localStorageService = LocalStorageService.getInstance();
