import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import { PersonalCashFlowData } from "src/interfaces/FinancialFormData/PersonalCashFlow";

export type PersonalCashFlowDataWithName = PersonalCashFlowData & {
  "First Name": string;
  "Last Name": string;
};

export class PersonalCashFlowAdapter {
  adapt(extractedData: PersonalCashFlowDataWithName): Form1040 {
    const form1040: Form1040 = {
      form: "1040",
      lenderId: extractedData.lenderId,
      entityName: `${extractedData["First Name"]} ${extractedData["Last Name"]}`,
      year: extractedData["Year"],
      taxpayer: {
        firstName: extractedData["First Name"],
        lastName: extractedData["Last Name"],
      },
      income: {
        wages: extractedData["Wages and Salaries"],
        interestAndDividends: extractedData["Interest and Dividends"],
        socialSecurityBenefits: extractedData["Social Security Benefits"],
      },
      schedules: {
        scheduleC: [
          {
            businessIncome: extractedData["Schedule C EBIDA"],
            businessExpenses: 0,
            businessName: "",
            netProfit: 0,
            depletion: 0,
            depreciation: 0,
            mortgageInterest: 0,
            otherInterest: 0,
            amortization: 0,
          },
        ],
      },
    };

    return form1040;
  }
}
