import { baseApiService } from "./BaseApiService";
import { FileReference, GetOCrParams, OcrResponse } from "src/pages/api/documents/ocr/common";
import { AnalysisType } from "src/page-components/investment-detail/tabs/SpreadsTab/SpreadsTabConstants";

function cleanDataNeeded(dataNeeded: FileReference[]): FileReference[] {
  // even though file reference does not have some types defined, these come in from frontend/state/saga?
  // for now, nullify them. This will be a problem if we add more fields
  return dataNeeded.map((d) => ({
    ...d,
    documentType: { ...d.documentType, output: undefined, confidence: undefined },
    output: undefined,
    confidence: undefined,
  }));
}
class OcrService {
  static getInstance(): OcrService {
    return new OcrService();
  }

  async startOcr(dataNeeded: FileReference[], analysisType: AnalysisType): Promise<OcrResponse> {
    const url = "/api/documents/ocr/v3";
    const dataNeededWithNoOutput = cleanDataNeeded(dataNeeded);
    return await baseApiService.post(url, {
      dataNeeded: dataNeededWithNoOutput,
      analysisType,
    });
  }

  async checkOcrStatus(params: GetOCrParams): Promise<OcrResponse> {
    const url = "/api/documents/ocr/v3?jobIdsSerialized=";
    const cleanedParams = {
      dataNeeded: cleanDataNeeded(params.dataNeeded),
      analysisType: params.analysisType,
    };
    const jobIdsSerialized = encodeURIComponent(JSON.stringify(cleanedParams));
    return await baseApiService.get(url + jobIdsSerialized);
  }
}

export const ocrService = OcrService.getInstance();
