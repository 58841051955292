import produce from "immer";
import { Reducer } from "redux";
import { ContactAction } from "../actions/actions.constants";
import { CustomerUser } from "src/models/CustomerUser";

export interface ContactState {
  list: CustomerUser[];
  data: any;
  isLoading: boolean;
  error?: string;
}

export const initialContactState: ContactState = {
  list: [],
  data: {},
  isLoading: false,
};

export const contactReducer: Reducer<ContactState> = (
  state: ContactState = initialContactState,
  action,
) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case ContactAction.FETCH_CONTACTS:
      case ContactAction.FETCH_CONTACT:
      case ContactAction.FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID: {
        draft.error = undefined;
        draft.isLoading = true;
        break;
      }
      case ContactAction.FETCH_CONTACTS_ERROR:
      case ContactAction.FETCH_CONTACT_ERROR:
      case ContactAction.FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID_ERROR: {
        draft.isLoading = false;
        draft.error = action.payload;
        break;
      }
      case ContactAction.FETCH_CONTACTS_COMPLETED:
      case ContactAction.FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID_COMPLETED: {
        draft.list = action.payload;
        draft.data = {};
        draft.isLoading = false;
        break;
      }
      case ContactAction.FETCH_CONTACT_COMPLETED: {
        draft.data = action.payload;
        draft.list = [];
        draft.isLoading = false;
        break;
      }
    }
  });
