import produce from "immer";
import { Reducer } from "redux";
import { CompletedDocumentUpload } from "src/contracts/intake/answer";
import borrowerIntakePages from "src/data/BorrowerIntakePages";
import { BorrowerIntakeSection } from "src/models/BorrowerIntakePage";
import { Answer, Inputs } from "src/models/BorrowerIntakePage/answer";
import { UploadDocumentResponse } from "src/models/DocumentRequest";
import {
  createQuestionAction,
  fetchAnswerCompletedAction,
  saveFetchedAnswerAction,
} from "src/redux/actions/intake.actions";
import { BorrowerIntakeAction } from "../actions/actions.constants";
import { AddAnyDocumentState } from "src/page-components/investment-detail/add-document/AddAnyDocument";
import { DocumentUpload } from "@prisma/client";

interface UploadingFile {
  file: File;
  progress: number;
}

// BorrowerIntakeState is the form state held by the client
// while filling in the form.
// (TODO: this is what it should be, but it also holds documents uploaded by the lender!)
//
// It holds answers keyed by sectionId;
// each section ID has its own BorrowerIntakeSectionAnswers object,
// which in turn holds answers keyed by pageNumber within the section.
// We use section and pageNumber to look up answers
// when navigating back to a page the borrower has already filled out.
// (To keep track of which questions have already been answered,
// it's sufficient to look at the questionId of each answer.)
export interface BorrowerIntakeState {
  entities: { [section: string]: BorrowerIntakeSectionAnswers };
  loadingOne?: boolean;
  loadingList?: boolean;
  error?: string;
  fetchedAnswers: Answer[];
  fetchedDocumentUploads?: CompletedDocumentUpload[];
  initialEntityName: string;
  borrowerEntityId?: number;
  loanId?: number;
  originalResponses: { [section in BorrowerIntakeSection]?: { [pageNumber: number]: Inputs } };
  loadingFile?: UploadingFile;
  // The documents that have been uploaded in the current session,
  // by the section they were uploaded in.
  // I don't know what the keys of the inner object represent;
  // they are variously:
  //         let key = document.documentTypeId;
  //         if (!key) {
  //           key = document.documentTitle + "_" + document.documentYear;
  //         }
  // But documentTypeId is not a document type ID! It is actually things like
  // src/utils/helpers.ts => genUploadedDocumentKey(args: DocumentAttributesForKeyGeneration): number
  // (see calls to dropUploadingDocument)
  // that is, a number formed by concatenating the page number, the real document type ID, and some third thing which may be unused.
  // (see the uploadDocument saga)
  documents: { [key in BorrowerIntakeSection]?: { [key: number | string]: any } };
  isUploading: boolean;
  guarantorPage: number;
  areAllSectionsCompleted: boolean;
  isOverviewCompleted: boolean;
  navBarLoading: boolean;
  addAnyDocumentState: AddAnyDocumentState;
}

type BorrowerIntakeSectionAnswers = { [pageNumber: number | string]: Answer[] };

export const initialIntakeState: BorrowerIntakeState = {
  fetchedAnswers: [],
  fetchedDocumentUploads: [],
  entities: {},
  originalResponses: {},
  documents: {},
  guarantorPage: 1,
  isUploading: false,
  areAllSectionsCompleted: false,
  isOverviewCompleted: false,
  navBarLoading: false,
  initialEntityName: "",
  addAnyDocumentState: {
    addAnyFileModalOpen: false,
    enableFileUpload: true,
    defaultEntity: undefined,
    allowedDocTypes: undefined,
    allowDuplicateDocTypes: false,
  },
};

export const intakeReducer: Reducer<BorrowerIntakeState> = (state = initialIntakeState, action) =>
  produce(state, (draft: BorrowerIntakeState) => {
    switch (action.type) {
      case BorrowerIntakeAction.FETCH_ANSWER: {
        draft.loadingOne = true;
        break;
      }
      case BorrowerIntakeAction.FETCH_ANSWER_COMPLETED: {
        if (fetchAnswerCompletedAction.match(action)) {
          const { answers, initialEntityName, loanId, borrowerEntityId, documentUploads } =
            action.payload;

          draft.fetchedAnswers = answers;
          draft.fetchedDocumentUploads = documentUploads;
          draft.initialEntityName = initialEntityName;
          draft.loanId = loanId;
          draft.borrowerEntityId = borrowerEntityId;
          draft.loadingOne = false;
          draft.error = undefined;
          break;
        }
        throw new Error(
          "BUG: type was CREATE_QUESTION but createQuestionAction.match(action) failed",
        );
      }
      case BorrowerIntakeAction.FETCH_ANSWER_ERROR: {
        draft.loadingOne = false;
        draft.error = action.payload as string;
        break;
      }
      case BorrowerIntakeAction.CREATE_QUESTION: {
        if (createQuestionAction.match(action)) {
          const { section, page, response, answers } = action.payload;
          if (section === "overview" && page === 2) {
            // Invalidate previous entity contact info (Overview/3) answers
            // when a new entity structure (Overview/2) answer is added;
            // this prevents stale data from being shown
            if (draft.fetchedAnswers?.length > 0) {
              draft.fetchedAnswers = draft.fetchedAnswers.filter(
                (a) => a.questionId !== borrowerIntakePages.overview[2].questions[0].id,
              );
            }
          }
          if (!draft.entities[section]) {
            draft.entities[section] = {};
          }
          if (!draft.originalResponses[section]) {
            draft.originalResponses[section] = {};
          }
          draft.entities[section][page] = answers;
          draft.originalResponses[section]![page] = response;
          break;
        }
        throw new Error(
          "BUG: type was CREATE_QUESTION but createQuestionAction.match(action) failed",
        );
      }
      case BorrowerIntakeAction.UPLOAD_DOCUMENT: {
        draft.loadingOne = true;
        break;
      }
      case BorrowerIntakeAction.UPLOAD_DOCUMENT_PROGRESS: {
        const progress = action.payload;
        if (draft.loadingFile) {
          draft.loadingFile.progress = progress as number;
        }
        break;
      }
      case BorrowerIntakeAction.UPLOAD_DOCUMENT_COMPLETED: {
        const { section, documentTypeId, document } = action.payload as UploadDocumentResponse & {
          section: BorrowerIntakeSection;
        } & { documentTypeId: number } & { document: any };
        draft.documents[section] = {
          ...draft.documents[section],
          [documentTypeId]: {
            // @ts-ignore
            ...draft.documents[document.documentRequestId],
            ...document,
          },
        };
        const { file } = action.meta as { file: UploadingFile; progress: number };
        draft.loadingFile = file;
        draft.loadingOne = false;
        break;
      }
      case BorrowerIntakeAction.UPLOAD_DOCUMENT_ERROR: {
        draft.loadingOne = false;
        draft.error = action.payload as string;
        break;
      }
      case BorrowerIntakeAction.BULK_UPLOAD_DOCUMENT: {
        draft.isUploading = true;
        break;
      }
      case BorrowerIntakeAction.BULK_UPLOAD_DOCUMENT_COMPLETE: {
        draft.isUploading = false;
        break;
      }
      // TODO: move this to a separate slice/reducer
      case BorrowerIntakeAction.LENDER_SIDE_UPLOAD_DOCUMENT_COMPLETE: {
        const { section } = action.payload as { section: BorrowerIntakeSection };
        const document = action.payload as UploadDocumentResponse;
        draft.isUploading = false;
        draft.documents[section] = {
          ...draft.documents[section],
          [document.documentType.id]: {
            ...document,
          },
        };
        break;
      }
      case BorrowerIntakeAction.DROP_UPLOADING_DOCUMENT: {
        const { documentTypeId, documentTitle, documentYear, section } = action.payload as {
          documentTypeId: number | null;
          documentTitle: string | null;
          documentYear: string | null;
          documentName: string;
          section: BorrowerIntakeSection;
        };
        const document = action.payload as DocumentUpload;
        let key = documentTypeId?.toString();
        if (!key) {
          key = documentTitle + "_" + documentYear;
        }
        draft.documents[section] = {
          ...draft.documents[section],
          [key]: {
            ...document,
          },
        };
        break;
      }
      case BorrowerIntakeAction.GUARANTOR_PAGE_UPDATE: {
        draft.guarantorPage = action.payload as number;
        break;
      }
      case BorrowerIntakeAction.ARE_ALL_SECTIONS_COMPLETED_UPDATE: {
        draft.areAllSectionsCompleted = action.payload as boolean;
        break;
      }
      case BorrowerIntakeAction.IS_OVERVIEW_COMPLETED: {
        draft.isOverviewCompleted = action.payload as boolean;
        break;
      }
      case BorrowerIntakeAction.SAVE_FETCHED_ANSWER: {
        if (saveFetchedAnswerAction.match(action)) {
          const { section, page, response, answers } = action.payload;
          if (!draft.entities[section]) {
            draft.entities[section] = {};
          }
          if (!draft.originalResponses[section]) {
            draft.originalResponses[section] = {};
          }
          draft.entities[section][page] = answers;
          draft.originalResponses[section]![page] = response;
        }
        break;
      }
      case BorrowerIntakeAction.NAVBAR_LOADING: {
        draft.navBarLoading = action.payload as boolean;
        break;
      }
      case BorrowerIntakeAction.SAVE_LOAN_DESCRIPTION: {
        draft.originalResponses["overview"]!["4"] = action.payload as any;
        break;
      }

      case BorrowerIntakeAction.OPEN_ADD_ANY_FILE_MODAL: {
        draft.addAnyDocumentState = action.payload as AddAnyDocumentState;
        break;
      }

      case BorrowerIntakeAction.CLOSE_ADD_ANY_FILE_MODAL: {
        draft.addAnyDocumentState.addAnyFileModalOpen = false;
        break;
      }

      case BorrowerIntakeAction.DISABLE_ADD_ANY_FILE_UPLOAD: {
        draft.addAnyDocumentState.enableFileUpload = false;
        break;
      }

      case BorrowerIntakeAction.ENABLE_ADD_ANY_FILE_UPLOAD: {
        draft.addAnyDocumentState.enableFileUpload = true;
        break;
      }

      case BorrowerIntakeAction.SET_DEFAULT_ADD_ANY_ENTITY: {
        draft.addAnyDocumentState.defaultEntity = action.payload as any;
        break;
      }

      default: {
        break;
      }
    }
  });
