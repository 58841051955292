import sections from "src/data/BorrowerIntakePages";
import _ from "lodash";
import { isIndividual } from "src/data/helpers";
import * as Intake from "src/models/BorrowerIntakePage";
import z from "zod";
import MultipleSection = Intake.QuestionNS.MultipleSection;
import { EntityHydrated } from "src/models/entity";

type OwnerTypeAndName = {
  owner_type: string;
  owner_name: string;
};

//This is a recursive function which takes data as object of one element of top level person/business tile.
//It recursively called until it pushes all nested subForm Owners in its 2nd parameter arr i.e. array of string.
//This is a helper function for getOwners function.
const businessOwners = (data: any, arr: OwnerTypeAndName[]) => {
  data.subRec?.map((e: any) => arr.push({ owner_type: e?.owner_type, owner_name: e?.owner_name }));
  for (let i = 0; i < data.subRec?.length; i++) {
    businessOwners(data.subRec[i], arr);
  }
};

//getOwners funtion returns all owners from all subForm in recursive way of overview-2 form sigle element, steps followed:-
//1-> created a resultArr initial array for to used to collect all owners further.
//2-> condition if parameter data is not undefined, owner type is business and subForm have length then it will call a recursive function
//    businessOwners to push owner in resultArr upto all nestings.
//3-> else subForm does not have length or owner type is person/Household then owner will pushed in resultArr.
//4-> After all runs of pushing owners in resultArr function will return reslultArr.
const getOwners = (data: any, includeBusinessOwners: boolean = true): OwnerTypeAndName[] => {
  const resultArr: OwnerTypeAndName[] = [];
  if (
    data !== undefined &&
    data?.owner_type === "business" &&
    data?.subRec?.length &&
    includeBusinessOwners
  ) {
    businessOwners(data, resultArr);
  } else {
    resultArr.push({ owner_type: data.owner_type, owner_name: data.owner_name });
  }
  return resultArr;
};

const genAvailableOptions = (
  overview2FormikValues: any,
  contextualValue?: string,
): Intake.QuestionInput.CheckboxOptions[] => {
  const owners: OwnerTypeAndName[] = overview2FormikValues
    .flatMap((value: any) => getOwners(value, false))
    .filter((value: any) => value.owner_type === "Person/Household");
  return [
    ...owners.map((owner) => {
      return {
        value: owner.owner_name,
        label: `${owner.owner_name}${owner.owner_type === "business" ? "" : " (Personal)"}`,
        disabled: false,
        checked: contextualValue === owner.owner_name,
      };
    }),
  ];
};

export const jsonFormatter = (data: any, entityName?: string): Intake.BorrowerIntakePage => {
  if (data === undefined) {
    return formatOverview2(entityName || "");
  }
  if (Array.isArray(data)) {
    return formatOverview3FromOverview2(data, entityName!);
  }
  if (isIndividual(data["entity-type"])) {
    const borrowerName = entityName || data["entity-name"];
    return formatOverview3FromOverview1(borrowerName);
  }
  return formatOverview2(entityName!);
};

export const formatOverview2 = (entityName: string): Intake.BorrowerIntakePage => {
  return {
    id: 2,
    page: 2,
    questions: [
      {
        ...sections.overview[1].questions[0],
        subtitle: `Please list any owners with > 20% ownership in ${entityName}.`,
      },
    ],
  };
};

// TODO: this could be a special case of formatOverview3FromOverview2
export const formatOverview3FromOverview1 = (borrowerName: string) => {
  {
    const subQuestionId = "c290419a-e5c1-4038-a4ad-17f892550d62";
    return {
      id: 3,
      page: 3,
      questions: [
        {
          ...sections.overview[2].questions[0],
          validationSchema: {
            [subQuestionId]: z.array(
              z.object({
                name: z.string(),
                email: z.string().email(),
                role: z.string(),
                access: z.array(z.string()),
              }),
            ),
          },
          subQuestions: [
            {
              ...(sections.overview[2].questions[0] as MultipleSection).subQuestions[0],
              id: subQuestionId,
              title: borrowerName,
              icon: "",
              prefilledValues: [borrowerName],
              availableOptions: {
                access: [
                  {
                    value: borrowerName,
                    label: `${borrowerName} (Personal)`,
                    disabled: false,
                    checked: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    } as Intake.BorrowerIntakePage;
  }
};

export const formatOverview3FromOverview2 = (
  data: any[],
  entityName: string,
  lenderCreatedEntities: EntityHydrated[] = [],
) => {
  const concatenatingSubQuestionsId = "c287819a-e5c1-4038-a4ad-17f766550d60";

  let availableAccessOptions: {
    value: string;
    label: string;
    checked?: boolean;
    disabled?: boolean;
    default?: boolean;
  }[] = [
    ...lenderCreatedEntities.map((entity) => {
      return {
        value: entity.asIndividual?.name || entity.asCompany?.name || "",
        label: entity.asIndividual?.name
          ? `${entity.asIndividual?.name} (Personal)`
          : entity.asCompany?.name || "",
        checked: false,
        disabled: false,
        default: false,
      };
    }),
    {
      value: entityName,
      label: entityName,
      checked: true,
      disabled: false,
      default: true,
    },
    ...genAvailableOptions(data, ""),
  ];

  return {
    id: 3,
    page: 3,
    questions: [
      {
        ...sections.overview[2].questions[0],
        validationSchema: {
          [concatenatingSubQuestionsId]: z.array(
            z.object({
              name: z.string(),
              email: z.string().email(),
              role: z.string(),
              access: z.array(z.string()),
            }),
          ),
        },
        subQuestions: [
          {
            ...(sections.overview[2].questions[0] as MultipleSection).subQuestions[0],
            id: concatenatingSubQuestionsId,
            title: entityName,
            icon: "",
            prefilledValues: [],
            availableOptions: {
              access: _.uniqBy(availableAccessOptions, "value"),
            },
          },
        ],
      },
    ],
  } as Intake.BorrowerIntakePage;
};

export const objectHasData = (data: any) => {
  return !!data && typeof data === "object" && Object.keys(data).length > 0;
};
