import { RawCellContent } from "hyperformula";
import {
  FinancialItemComputed,
  FinancialItemType,
  FinancialPeriodItem,
  FinancialStatementItems,
  CashFlowCandidates,
} from "src/backend/services/ocr/abstractions/financial-statement";
import { GridRow } from "src/classes/GridState";
import { parseTaxCellAsFloatOrUndefined } from "src/backend/services/ocr/utils/parseTaxCellAsFloat";
import { colNumberToExcelCol } from "src/classes/RenderedDoc";
import { sanitizeTabName } from "./utils";
import { AutoRenderedSheetBuilder } from "./AutoRenderedSheetBuilder";

const similarItem = (target: string, searchItem: string) => {
  return target.toLowerCase().startsWith(searchItem.toLowerCase());
};

const getCellReference = (colIndex: number, rowIndex: number): string => {
  const colLetter = colNumberToExcelCol(colIndex + 2);
  return `${colLetter}${rowIndex + 1}`;
};

export const combineFinancialStatementItems = (
  financialStatementItems: FinancialStatementItems[],
): FinancialStatementItems | null => {
  if (!financialStatementItems.length) {
    return null;
  }

  const sortedItems = [...financialStatementItems].sort((a, b) => {
    const aLatest = Math.max(...a.availablePeriods.map((p) => parseInt(p)));
    const bLatest = Math.max(...b.availablePeriods.map((p) => parseInt(p)));
    return bLatest - aLatest;
  });

  const combined: FinancialStatementItems = {
    ...sortedItems[0],
    availablePeriods: [],
    items: [],
  };

  const allPeriods = new Set<string>();
  sortedItems.forEach((statement) => {
    statement.availablePeriods.forEach((period) => allPeriods.add(period));
  });
  combined.availablePeriods = Array.from(allPeriods).sort();

  const mergePeriodValues = (
    existingValues: Record<string, string | null> | null = null,
    newValues: Record<string, string | null> | null = null,
  ) => {
    if (!existingValues && !newValues) {
      return null;
    }
    if (!existingValues) {
      return newValues;
    }
    if (!newValues) {
      return existingValues;
    }
    return { ...newValues, ...existingValues };
  };

  const mergeItems = (items: FinancialPeriodItem[]): FinancialPeriodItem[] => {
    const mergedItems = new Map<string, FinancialPeriodItem>();

    // newer period document items take precedence
    [...items].reverse().forEach((item) => {
      const existing = mergedItems.get(item.name);
      if (!existing) {
        mergedItems.set(item.name, { ...item });
      } else {
        existing.periodValues = mergePeriodValues(item.periodValues, existing.periodValues);

        if (item.nestedItems?.length) {
          existing.nestedItems = mergeItems([...item.nestedItems, ...(existing.nestedItems || [])]);
        }

        if (item.total) {
          existing.total = existing.total
            ? {
                ...item.total,
                periodValues: mergePeriodValues(
                  item.total.periodValues,
                  existing.total.periodValues,
                ),
              }
            : item.total;
        }
      }
    });

    return Array.from(mergedItems.values());
  };

  const allItems = sortedItems.flatMap((statement) => statement.items);
  combined.items = mergeItems(allItems);

  return combined;
};

export const cashFlowCandidateRows = (rows: GridRow[]): CashFlowCandidates => {
  const netIncomeRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.NetIncome))
    .filter((row) => row.rowDataArray.slice(1).some((cell) => cell))
    .map((row) => ({
      rowIndex: row.index,
      rowKey: row.rowDataArray[0] as string,
    }))
    .slice(0, 1);
  const depreciationRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Depreciation))
    .map((row) => ({
      rowIndex: row.index,
      rowKey: row.rowDataArray[0] as string,
    }));

  const interestRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Interest))
    .map((row) => ({
      rowIndex: row.index,
      rowKey: row.rowDataArray[0] as string,
    }));

  const amortizationRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Amortization))
    .map((row) => ({
      rowIndex: row.index,
      rowKey: row.rowDataArray[0] as string,
    }));

  return {
    netIncome: netIncomeRows,
    depreciation: depreciationRows,
    interest: interestRows,
    amortization: amortizationRows,
  };
};

export const buildCashFlowFromFinancialsTab = (
  candidateRows: CashFlowCandidates,
  allPeriods: string[],
  sourceTabName: string,
) => {
  const sanitizedTabName = sanitizeTabName(sourceTabName);
  const rendered = new AutoRenderedSheetBuilder({}, {}, 0, "B");

  const periodCellRefs: Record<number, string[]> = {};
  allPeriods.forEach((_, i) => (periodCellRefs[i] = []));

  rendered.addRow(() => ["", ...allPeriods], "text", "highlighted");

  Object.entries(candidateRows).forEach(([_key, rows]) => {
    if (rows.length === 0) {
      return;
    }
    rows.forEach((row) => {
      const newRowIndex = rendered.body.length;
      rendered.addRow(() => {
        const rowData = [
          row.rowKey,
          ...allPeriods.map((_, i) => {
            const cellRef = `='${sanitizedTabName}'!${getCellReference(i, row.rowIndex)}`;
            periodCellRefs[i].push(`${getCellReference(i, newRowIndex)}`);
            return cellRef;
          }),
        ];
        return rowData;
      });
    });
    rendered.addRow(() => [""]);
  });

  rendered.addRow(
    () => [
      FinancialItemComputed.BusinessCashFlowBeforeTax,
      ...allPeriods.map((_, i) =>
        periodCellRefs[i].length ? `=${periodCellRefs[i].join("+")}` : "",
      ),
    ],
    "number",
    "highlighted",
  );

  return rendered;
};

export const convertToGridRows = (jsonData: FinancialStatementItems): GridRow[] => {
  let rowIndex = 0;

  const processItem = (
    item: FinancialPeriodItem,
    allPeriods: string[],
    levelIndex: number,
  ): GridRow[] => {
    const gridRows: GridRow[] = [];

    const skipItemLevelValues = item.total || (item.nestedItems && item.nestedItems.length > 0);
    const rowDataArray: RawCellContent[] = [
      item.name,
      ...allPeriods.map((period) => {
        if (skipItemLevelValues) {
          return "";
        } else {
          return parseTaxCellAsFloatOrUndefined(item.periodValues?.[period] || "");
        }
      }),
    ];
    const gridRow: GridRow = {
      rowDataArray,
      rowDataType: "number",
      rowStyle: levelIndex === 0 ? "highlighted" : "standard",
      rowMetadata: {
        levelIndex,
      },
      isManagedByApp: false,
      index: rowIndex++,
    };
    gridRows.push(gridRow);

    item.nestedItems?.forEach((nestedItem) => {
      gridRows.push(...processItem(nestedItem, allPeriods, levelIndex + 1));
    });

    if (item.total) {
      const totalRowDataArray: RawCellContent[] = [
        item.total.name,
        ...allPeriods.map((period) =>
          parseTaxCellAsFloatOrUndefined(item.total?.periodValues?.[period] || ""),
        ),
      ];

      const totalGridRow: GridRow = {
        rowDataArray: totalRowDataArray,
        rowDataType: "number",
        rowStyle: "highlighted",
        rowMetadata: {
          levelIndex: levelIndex + 1,
        },
        isManagedByApp: false,
        index: rowIndex++,
      };
      gridRows.push(totalGridRow);
    }

    return gridRows;
  };

  const gridRows: GridRow[] = [];
  const allPeriods = [...jsonData.availablePeriods];

  gridRows.push({
    rowDataArray: ["", ...allPeriods],
    rowDataType: "text",
    rowStyle: "highlighted",
    rowMetadata: {
      levelIndex: 0,
    },
    isManagedByApp: false,
    index: rowIndex++,
  });

  jsonData.items.forEach((item) => {
    gridRows.push(...processItem(item, allPeriods, 0));
  });

  return gridRows;
};
