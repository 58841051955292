import { LoanWorkflows } from "src/redux/actions/actions.constants";
import { WorkflowSettings } from "src/models/WorkflowSettings";

export const fetchLoanWorkflows = () => {
  return {
    type: LoanWorkflows.FETCH_LOAN_WORKFLOWS,
  };
};

export const setLoanWorkflows = (payload: { workflows: (WorkflowSettings & { id: number })[] }) => {
  return {
    type: LoanWorkflows.SET_LOAN_WORKFLOWS,
    payload: payload,
  };
};

export const createNewLoanWorkflow = (payload: WorkflowSettings) => {
  return {
    type: LoanWorkflows.CREATE_NEW_LOAN_WORKFLOW,
    payload: payload,
  };
};

export const updateLoanWorkflow = (payload: WorkflowSettings & { id: number }) => {
  return {
    type: LoanWorkflows.UPDATE_LOAN_WORKFLOW,
    payload: payload,
  };
};

export const deleteLoanWorkflow = (payload: { id: number }) => {
  return {
    type: LoanWorkflows.DELETE_LOAN_WORKFLOW,
    payload: payload,
  };
};
