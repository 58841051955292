import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import { Form1065IncomeStatementGrouped } from "./Form1065IncomeStatementGrouped";
import { Form1065CashFlowGrouped } from "./Form1065CashFlowGrouped";
import { Form1065BalanceSheetGrouped } from "./Form1065BalanceSheetGrouped";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";

export const Form1065RatiosRowHeadings = {
  DebtServiceCoverageRatio: "Debt service coverage ratio",
  ROA: "ROA",
  ROE: "ROE",
  LiquidityRatio: "Liquidity ratio",
  QuickRatio: "Quick ratio",
  CurrentRatio: "Current ratio",
  DebtToEquityRatio: "Debt-to-equity ratio",
  AssetRatio: "Asset ratio",
  DaysPayable: "Days payable",
  DaysReceivable: "Days receivable",
  DaysInventory: "Days inventory",
};

export function buildForm1065Ratios(
  data: Form1065,
  startingColumn: string,
  startingRow: number,

  incomeStatementTabName: string,
  cashFlowTabName: string,
  balanceSheetTabName: string,

  incomeStatement: Form1065IncomeStatementGrouped,
  cashFlow: Form1065CashFlowGrouped,
  balanceSheet: Form1065BalanceSheetGrouped,
): AutoRenderedSheetBuilder<Form1065, typeof Form1065RatiosRowHeadings> {
  const balanceSheetTab = (row: number) => `'${balanceSheetTabName}'!${startingColumn}${row}`;
  const incomeStatementTab = (row: number) => `'${incomeStatementTabName}'!${startingColumn}${row}`;
  const cashFlowTab = (row: number) => `'${cashFlowTabName}'!${startingColumn}${row}`;

  const netIncome = incomeStatementTab(incomeStatement.indexOfHeader("NetIncome"));
  const totalAssets = balanceSheetTab(balanceSheet.indexOfHeader("TotalAssets"));
  const totalLiabilities = balanceSheetTab(balanceSheet.indexOfHeader("TotalLiabilities"));
  const cash = balanceSheetTab(balanceSheet.indexOfHeader("Cash"));
  const receivables = balanceSheetTab(balanceSheet.indexOfHeader("AccountsReceivableNet"));
  const inventory = balanceSheetTab(balanceSheet.indexOfHeader("Inventory"));
  const marketableSecurities = balanceSheetTab(balanceSheet.indexOfHeader("MarketableSecurities"));
  const currentLiabilities = balanceSheetTab(balanceSheet.indexOfHeader("TotalCurrentLiabilities"));
  const currentAssets = balanceSheetTab(balanceSheet.indexOfHeader("TotalCurrentAssets"));
  const accountsPayable = balanceSheetTab(balanceSheet.indexOfHeader("AccountsPayable"));
  const costOfGoods = incomeStatementTab(incomeStatement.indexOfHeader("LessCostOfGoodsSold"));
  const grossRevenues = incomeStatementTab(incomeStatement.indexOfHeader("GrossRevenues"));

  return new AutoRenderedSheetBuilder(data, Form1065RatiosRowHeadings, startingRow, startingColumn)
    .addRow(({ labels }) => [
      labels.DebtServiceCoverageRatio,
      `=${cashFlowTab(cashFlow.indexOfHeader("DebtServiceCoverageRatio"))}`,
    ])
    .addRow(({ labels }) => [
      labels.ROA,
      `=IF(${netIncome} / ${totalAssets} < 0, "Negative", ${netIncome} / ${totalAssets})`,
    ])
    .addRow(({ labels }) => [
      labels.ROE,
      `=IF(${netIncome} / ${totalLiabilities} < 0, "Negative", ${netIncome} / ${totalLiabilities})`,
    ])
    .addRow(({ labels }) => [
      labels.LiquidityRatio,
      `=(${cash} + ${marketableSecurities}) / ${currentLiabilities}`,
    ])
    .addRow(({ labels }) => [
      labels.QuickRatio,
      `=(${cash} + ${receivables} + ${marketableSecurities}) / ${currentLiabilities}`,
    ])
    .addRow(({ labels }) => [labels.CurrentRatio, `=${currentAssets} / ${currentLiabilities}`])
    .addRow(({ labels }) => [labels.DebtToEquityRatio, `=${totalLiabilities} / ${totalAssets}`])
    .addRow(({ labels }) => [labels.AssetRatio, `=${totalAssets} / ${totalLiabilities}`])
    .addRow(({ labels }) => [labels.DaysPayable, `=${accountsPayable} / ${costOfGoods} * 365`])
    .addRow(({ labels }) => [labels.DaysReceivable, `=${receivables} / ${grossRevenues} * 365`])
    .addRow(({ labels }) => [labels.DaysInventory, `=${inventory} / ${costOfGoods} * 365`]);
}
