import { Reducer } from "redux";
import produce from "immer";
import { ManagementAction } from "src/redux/actions/actions.constants";
import { ManagementDetails } from "src/components/ManagementDetailsTable/ManagementDetailsTable";
import { DEFAULT_PAGINATION_PARAMS_LOAN, PaginationParams } from "src/utils/pagination";

export interface ManagementState {
  data: ManagementDetails[];
  isDataLoading: boolean;
  isCsvLoading: boolean;
  filters: PaginationParams;
}

export const initialManagementState: ManagementState = {
  data: [],
  isDataLoading: false,
  isCsvLoading: false,
  filters: DEFAULT_PAGINATION_PARAMS_LOAN,
};

export const managementReducer: Reducer<ManagementState> = (
  state: ManagementState = initialManagementState,
  action,
) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case ManagementAction.GET_MANAGEMENT_DATA_FOR_TABLE: {
        draft.isDataLoading = true;
        break;
      }
      case ManagementAction.SET_MANAGEMENT_DATA_FOR_TABLE: {
        draft.data = action.payload;
        draft.isDataLoading = false;
        break;
      }
      case ManagementAction.GET_MANAGEMENT_DATA_FOR_CSV: {
        draft.isCsvLoading = true;
        break;
      }
      case ManagementAction.MANAGEMENT_DATA_FOR_CSV_COMPLETED: {
        draft.isCsvLoading = false;
        break;
      }
      case ManagementAction.SET_MANAGEMENT_SEARCH_PARAMS: {
        draft.filters = action.payload;
        break;
      }
    }
  });
