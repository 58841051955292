import type { RawCellContent } from "hyperformula";
import {
  EXCEL_JS_INVALID_SHEET_NAME_CHARACTERS_REGEX,
  MAX_TAB_CHARACTERS,
} from "src/utils/constants";

export const shortenPropertyName = (name: RawCellContent) =>
  typeof name === "string" ? name.split(" ").slice(0, 4).join(" ") : name;

export const sanitizeTabName = (name: string) => {
  const sliced = name.slice(0, Math.min(MAX_TAB_CHARACTERS, name.length));
  // Illegal character in worksheet name: asterisk (*), question mark (?),
  // colon (:), forward slash (/ \), or bracket ([])
  // replace illegal characters with underscore
  return sliced.replace(EXCEL_JS_INVALID_SHEET_NAME_CHARACTERS_REGEX, " ").trim();
};
