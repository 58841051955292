import { Rendered } from "src/classes/RenderedDoc";
import { RawCellContent } from "hyperformula";
import { GridState } from "src/classes/GridState";
import { TaxProperty } from "src/interfaces/TaxFormData/schedules/ScheduleE";
import { shortenPropertyName } from "src/classes/RenderedDocuments/utils";
import _ from "lodash";
import { getSourceFromFormType } from "./helpers";

type PropertyWithYearsLabel =
  | "Source"
  | "Entity"
  | "Property"
  | "Gross Rents"
  | ""
  | "Net Income"
  | "Total Expenses"
  | "Interest"
  | "Depreciation"
  | "Amortization"
  | "NOI"
  | "Existing Debt"
  | "New Debt"
  | "Total Debt Service"
  | "Debt Service Coverage Ratio"
  | "Excess/Deficit";

export class PropertyWithYearsTable extends Rendered<TaxProperty[], PropertyWithYearsLabel> {
  constructor(
    public propertyName: string,
    public yearlyData: Record<string, TaxProperty>,
    public entityName: string,
  ) {
    super(Object.values(yearlyData));
    this.underlying = Object.values(yearlyData);
    this.yearHeaders = Object.keys(yearlyData);
  }

  get numberOfColumns(): number {
    return this.yearHeaders.length + 1;
  }

  addByPropertyKeys<T extends keyof TaxProperty>(
    propertyNames: T[],
    defaultValue: number = 0,
  ): RawCellContent[] {
    const partialRowDataArray = this.underlying.reduce((acc, _property) => {
      const property = _property as TaxProperty;
      const values = propertyNames.map((propertyName) => property[propertyName] ?? defaultValue);
      const combinedValue: number = values.reduce((acc, value) => {
        return acc + (_.isNumber(value) ? value : defaultValue);
      }, 0);

      return [...acc, Number(combinedValue.toFixed(2))];
    }, [] as number[]);
    return partialRowDataArray;
  }

  getByPropertyKey<T extends keyof TaxProperty>(
    propertyName: T,
    defaultValue: RawCellContent,
    fallbackPropertyName?: T,
  ): RawCellContent[] {
    const partialRowDataArray = this.underlying.reduce((acc, _property) => {
      const property = _property as TaxProperty;
      const fallbackValue = fallbackPropertyName
        ? (property[fallbackPropertyName] as RawCellContent)
        : undefined;
      const value: RawCellContent = property[propertyName] ?? fallbackValue ?? defaultValue;
      return [...acc, value];
    }, [] as RawCellContent[]);
    return partialRowDataArray;
  }

  get source(): string {
    return getSourceFromFormType(this.underlying[0]?.form);
  }

  getAmortization(): RawCellContent[] {
    return this.underlying.map((property) => property?.amortization?.statementValue ?? "");
  }

  get initialGridState(): GridState {
    const numCols = this.numberOfColumns;
    let index = 0;
    return {
      // Row 1 - Source, e.g. "Rent Roll"
      source: {
        rowDataArray: ["Source", ...this.yearHeaders.map((year) => `${year} ${this.source}`)],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 2 - Entity
      entity: {
        // TODO --> Remove hard-coded value
        rowDataArray: ["Entity", ...this.generateRepeatedValues(numCols, this.entityName)],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 3 - Property
      propertyName: {
        rowDataArray: [
          "Property",
          ...this.getByPropertyKey("propertyName", "", "propertyAddress").map(shortenPropertyName),
        ],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 4 - Gross Rents
      grossRents: {
        rowDataArray: ["Gross Rents", ...this.getByPropertyKey("grossRents", 0)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 5 - Total Expenses
      totalExpenses: {
        rowDataArray: ["Total Expenses", ...this.getByPropertyKey("totalExpenses", 0)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 6 - Interest
      interest: {
        rowDataArray: [
          "Interest",
          ...this.addByPropertyKeys(["mortgageInterest", "otherInterest", "interest"]),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 7 - Depreciation
      depreciation: {
        rowDataArray: ["Depreciation", ...this.getByPropertyKey("depreciation", 0)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 8 - Amortization
      amortization: {
        rowDataArray: ["Amortization", ...this.getAmortization()],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 9 - Net Operating Income (Net Income + Interest + Depreciation + Amortization)
      noi: {
        rowDataArray: [
          "Net Operating Income (NOI)",
          ...this.generateFormulaByColumnPattern(
            numCols,
            (colLabel: string) =>
              `=${colLabel}4-${colLabel}5+${colLabel}6+${colLabel}7+${colLabel}8`,
          ),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 10 - Existing Debt entered by user)
      existingDebt: {
        rowDataArray: ["Existing Debt", ...this.generateRepeatedValues(numCols, 0)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 11 - New Debt
      newDebt: {
        rowDataArray: ["New Debt", ...this.generateRepeatedValues(numCols, 0)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 12 - Total Debt Service (Existing Debt + New Debt)
      totalDebtService: {
        rowDataArray: [
          "Total Debt Service",
          ...this.generateFormulaByColumnPattern(
            numCols,
            (colLabel: string) => `=${colLabel}10+${colLabel}11`,
          ),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 13 - Debt Service Coverage Ratio, a.k.a. DSCR (NOI / Total Debt Service)
      dscr: {
        // TODO --> Add new data type for 1.06x
        rowDataArray: [
          "Debt Service Coverage Ratio",
          ...this.generateFormulaByColumnPattern(
            numCols,
            (colLabel: string) => `=${colLabel}9/${colLabel}12`,
          ),
        ],
        rowDataType: "ratio",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 14 - Excess/Deficit (NOI - Total Debt Service)
      excessDeficit: {
        rowDataArray: [
          "Excess/Deficit",
          ...this.generateFormulaByColumnPattern(
            numCols,
            (colLabel: string) => `=${colLabel}9-${colLabel}12`,
          ),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
    } as GridState;
  }

  yearHeaders: string[];
  defaultRowLabels: PropertyWithYearsLabel[] = [];
  highlightedRowLabels: PropertyWithYearsLabel[] = [];
  percentageRowLabels: PropertyWithYearsLabel[] = [];
  metadataRowLabels: PropertyWithYearsLabel[] = [];
}
