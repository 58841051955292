import { baseApiService } from "./BaseApiService";
import { CanonicalRentRoll } from "src/models/CanonicalRentRoll";
import { RawCellContent } from "hyperformula";
import { LLMCommandType } from "src/pages/api/llm/LLMCommandType";
import { ParseRentRollsOutput } from "src/pages/api/llm/commands/ParseRentRolls";
import { LLMApiRequestBody } from "src/pages/api/llm/index.api";

class LLMApiService {
  static getInstance = () => {
    return new LLMApiService();
  };

  async parseRentRollFromCSV(
    csv: RawCellContent[][],
    lineData: string[] = [],
  ): Promise<CanonicalRentRoll[]> {
    const csvString = csv.map((row) => row.map((cell) => cell?.toString()).join(",")).join("\n");
    return this.parseRentRollFromCSVString(csvString, lineData);
  }

  async parseRentRollFromCSVString(
    csvString: string,
    lineData: string[] = [],
  ): Promise<CanonicalRentRoll[]> {
    const apiRequest: LLMApiRequestBody = {
      commandType: LLMCommandType.PARSE_RENTROLL,
      tableData: [csvString],
      lineData,
    };
    const response: ParseRentRollsOutput = (await baseApiService.post(
      `/api/llm`,
      apiRequest,
    )) as ParseRentRollsOutput;
    return response.content.data;
  }
}

export const llmApiService = LLMApiService.getInstance();
