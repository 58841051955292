import { Reducer } from "redux";
import produce from "immer";
import { ReportingDetails } from "src/components/ReportingDetailsTable/ReportingDetailsTable";
import { ReportingAction } from "src/redux/actions/actions.constants";
import { ReportingFilter } from "@prisma/client";
import { TableHeader } from "src/models/ReportingFilter";

export interface ReportingState {
  data: ReportingDetails[];
  headers: TableHeader[];
  isDataLoading: boolean;
  allDataLoaded: boolean;
  isHeadersLoading: boolean;
  isFilterLoading: boolean;
  filters: ReportingFilter[];
}

export const initialReportingState: ReportingState = {
  data: [],
  headers: [],
  isDataLoading: false,
  allDataLoaded: false,
  isHeadersLoading: false,
  isFilterLoading: false,
  filters: [],
};

export const reportingReducer: Reducer<ReportingState> = (
  state: ReportingState = initialReportingState,
  action,
) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case ReportingAction.GET_REPORTING_DATA_FOR_TABLE: {
        draft.isDataLoading = true;
        break;
      }
      case ReportingAction.RESET_IS_LOADING: {
        draft.isDataLoading = false;
        break;
      }
      case ReportingAction.SET_REPORTING_ALL_DATA_LOADED: {
        draft.allDataLoaded = action.payload;
        break;
      }
      case ReportingAction.GET_REPORTING_HEADERS: {
        draft.isHeadersLoading = true;
        break;
      }
      case ReportingAction.SET_REPORTING_DATA_FOR_TABLE: {
        draft.data = action.payload;
        draft.isDataLoading = false;
        break;
      }
      case ReportingAction.SET_REPORTING_HEADERS: {
        draft.headers = action.payload;
        draft.isHeadersLoading = false;
        break;
      }
      case ReportingAction.GET_REPORTING_DATA_FOR_CSV: {
        draft.isDataLoading = true;
        break;
      }
      case ReportingAction.REPORTING_DATA_FOR_CSV_COMPLETED: {
        draft.isDataLoading = false;
        break;
      }
      case ReportingAction.GET_REPORTING_FILTERS: {
        draft.isFilterLoading = true;
        break;
      }
      case ReportingAction.SET_REPORTING_FILTERS: {
        draft.filters = action.payload;
        draft.isFilterLoading = false;
        break;
      }
      case ReportingAction.CREATE_REPORTING_FILTER: {
        draft.isFilterLoading = true;
        break;
      }
      case ReportingAction.UPDATE_REPORTING_FILTER: {
        draft.isFilterLoading = true;
        break;
      }
      case ReportingAction.DELETE_REPORTING_FILTER: {
        draft.isFilterLoading = true;
        break;
      }
    }
  });
