import { ManagementAction } from "src/redux/actions/actions.constants";
import { PaginationParams } from "src/utils/pagination";

export const getManagementDataForTable = (payload: { fetchNextPage: boolean }) => ({
  type: ManagementAction.GET_MANAGEMENT_DATA_FOR_TABLE,
  payload: payload,
});

export const setManagementDataForTable = (data: any) => ({
  type: ManagementAction.SET_MANAGEMENT_DATA_FOR_TABLE,
  payload: data,
});

export const getManagementDataForCsv = (payload: { filters: PaginationParams }) => ({
  type: ManagementAction.GET_MANAGEMENT_DATA_FOR_CSV,
  payload: payload,
});

export const setManagementSearchParams = (data: any) => ({
  type: ManagementAction.SET_MANAGEMENT_SEARCH_PARAMS,
  payload: data,
});

export const managementDataForCsvActionCompleted = () => ({
  type: ManagementAction.MANAGEMENT_DATA_FOR_CSV_COMPLETED,
});
