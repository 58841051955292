import { createAction } from "@reduxjs/toolkit";
import { AnnualReviewAction } from "src/redux/actions/actions.constants";

export const storeAnnualReviewData = createAction<{
  step: number;
  payload: any;
}>(AnnualReviewAction.SAVE_STEP_DATA);

export const createAnnualReviewData = (data: any) => {
  return {
    type: AnnualReviewAction.CREATE_ANNUAL_REVIEW,
    payload: data,
  };
};

export const createAnnualReviewDataCompleted = (data: any) => {
  return {
    type: AnnualReviewAction.CREATE_ANNUAL_REVIEW_COMPLETED,
    payload: data,
  };
};
