import { DocumentTypes } from "src/data/EntityToDocumentMapping";

// TODO: this is just a dump of the document_type table to JSON

export const documentTypes = {
  1: {
    id: 1,
    name: "Personal tax return",
    year: 2022,
  },
  2: {
    id: 2,
    name: "Personal tax return",
    year: 2021,
  },
  3: {
    id: 3,
    name: "Personal tax return",
    year: 2020,
  },
  4: {
    id: 4,
    name: "Schedule K-1's / Corporate tax return",
    year: 2021,
  },
  5: {
    id: 5,
    name: "Personal financial statement",
    year: 2022,
  },
  6: {
    id: 6,
    name: "Personal bank statement",
    year: 2022,
  },
  7: {
    id: 7,
    name: "Projected income statement",
    year: 2021,
  },
  8: {
    id: 8,
    name: "YTD financial statements",
    year: 2023,
  },
  9: {
    id: 9,
    name: "Financial statements for the same period YTD",
    year: 2022,
  },
  10: {
    id: 10,
    name: "Year end financial statements",
    year: 2022,
  },
  11: {
    id: 11,
    name: "Business debt schedule",
    year: 2022,
  },
  12: {
    id: 12,
    name: "Equipment and/or vehicle loans or leases",
    year: 2022,
  },
  13: {
    id: 13,
    name: "Purchase invoice(s)",
    year: 2022,
  },
  14: {
    id: 14,
    name: "AR aging schedule",
    year: 2022,
  },
  15: {
    id: 15,
    name: "AP aging schedule",
    year: 2022,
  },
  16: {
    id: 16,
    name: "Work in Process inventory report",
    year: 2022,
  },
  17: {
    id: 17,
    name: "Corporate tax return",
    year: 2022,
  },
  18: {
    id: 18,
    name: "Corporate tax return",
    year: 2021,
  },
  19: {
    id: 19,
    name: "Corporate tax return",
    year: 2020,
  },
  20: {
    id: 20,
    name: "Certificate of good standing",
    year: 2023,
  },
  21: {
    id: 21,
    name: "Insurance",
    year: 2023,
  },
  22: {
    id: 22,
    name: "Borrowing base certificate (BBC)",
    year: 2023,
  },
  23: {
    id: 23,
    name: "Personal tax return and affiliated Schedule K-1's",
    year: 2023,
  },
  24: {
    id: 24,
    name: "Income Statement",
    year: 2023,
  },
  25: {
    id: 25,
    name: "Balance Sheet",
    year: 2023,
  },
  26: {
    id: 26,
    name: "Fiscal year end Income Statement",
    year: 2023,
  },
  27: {
    id: 27,
    name: "Fiscal year end Balance Sheet",
    year: 2023,
  },
  28: {
    id: 28,
    name: "YTD Income Statement",
    year: 2023,
  },
  29: {
    id: 29,
    name: "YTD Balance Sheet",
    year: 2023,
  },
  30: {
    id: 30,
    name: "Inventory List",
    year: 2023,
  },
  31: {
    id: 31,
    name: "Rent Roll",
    year: 2023,
  },
  32: {
    id: 32,
    name: "Leases",
    year: 2023,
  },
  33: {
    id: 33,
    name: "Personal bank statement",
    year: 2023,
  },
  35: {
    id: 35,
    name: "Financials",
    year: 2023,
  },
} as const satisfies DocumentTypes;

export const documentTypeNames = Object.values(documentTypes).map(
  (documentType) => documentType.name,
);
