import { TaxPropertiesRendered } from "src/classes/RenderedDocuments/TaxPropertyBreakoutRendered";
import { Form8825 } from "src/interfaces/TaxFormData/attachments/Form8825";

export class Form8825BreakoutRendered extends TaxPropertiesRendered {
  constructor(underlying: Form8825, columnId: string = "B", rowStart: number = 0) {
    if (!underlying.propertyData) {
      throw new Error("Form8825BreakoutRendered: propertyData is undefined");
    }
    super(underlying.propertyData, columnId, rowStart, {
      "Net Income": 17,
      Depreciation: 14,
      Interest: 9,
    });
  }
}
