import type { ColDef } from "ag-grid-community";
import { TabName } from "src/redux/reducers/types";
import { GridState } from "src/classes/GridState";
export abstract class RenderedWorkflow {
  abstract colDefs: Map<TabName, ColDef[]>;
  abstract gridStates: Map<TabName, GridState>;

  columnDefs(): Record<TabName, ColDef[]> {
    return Object.fromEntries(this.colDefs.entries());
  }
}
