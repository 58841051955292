import { PersonalCashFlowData } from "src/interfaces/FinancialFormData/PersonalCashFlow";
import { AutoRendered } from "src/classes/AutoRendered";
import type { RawCellContent } from "hyperformula";
import { Form1040 } from "src/interfaces/TaxFormData/Form1040";

export class PersonalCashFlowRendered extends AutoRendered<PersonalCashFlowData> {
  metadataRowLabels: string[] = [];

  asColumns(): RawCellContent[][] {
    const data = this.underlying;
    const colId = this.columnId;
    const row = this.rowStart;
    const topPart = [
      ["Wages and Salaries", data["Wages and Salaries"]],
      ["Interest and Dividends", data["Interest and Dividends"]],
      ["Social Security Benefits", data["Social Security Benefits"]],
      ["Schedule C EBIDA", data["Schedule C EBIDA"]],
      ["Schedule D Capital Gains", data["Schedule D Capital Gains"]],
      ["Schedule E Net Income", data["Schedule E Net Income"]],
      ["Plus: Interest Expense", data["Plus: Interest Expense"]],
      ["Plus: Depreciation & Amort. Expense", data["Plus: Depreciation & Amort. Expense"]],
      ["K-1 Distributions, Net Contributions*", data["K-1 Distributions, Net Contributions*"]],
      ["Subtotal", `=SUM(${colId}${row}:${colId}${row + 8})`],
      ["Less: Federal Taxes", data["Less: Federal Taxes"]],
      ["Less: Schedule A State & Local Taxes", data["Less: Schedule A State & Local Taxes"]],
      [
        "Cash Flow Available for Debt Service",
        `=${colId}${row + 9} - SUM(${colId}${row + 10}:${colId}${row + 12})`,
      ],
    ];

    const lenderSpecificPart = Object.entries(data.lenderSpecificDebtLineItems || {});
    const rowNum = topPart.length + lenderSpecificPart.length + row;
    const debtServiceEq = `=SUM(${colId}${rowNum}:${colId}${rowNum + lenderSpecificPart.length})`;
    const dscrEq = `=${colId}${topPart.length}/${colId}${rowNum}`;
    const excessCashFlowEq = `=${colId}${rowNum - 1} - ${colId}${rowNum}`;

    const bottomPart: string[][] = [
      ["Total Debt Service", lenderSpecificPart.length ? debtServiceEq : "0"],
      ["Personal DSCR", dscrEq],
      ["Excess Cash Flow", excessCashFlowEq],
    ];

    const lenderSpecificRows = lenderSpecificPart.map(([key, value]) => [key, value?.toString()]);

    return [...topPart, ...lenderSpecificRows, ...bottomPart];
  }
  get highlightedRowLabels() {
    return [
      "Subtotal",
      "Cash Flow Available for Debt Service",
      "Total Debt Service",
      "Personal DSCR",
      "Excess Cash Flow",
    ];
  }

  get percentageRowLabels() {
    return [];
  }

  constructor(public taxData: Form1040) {
    const scheduleCEbida = taxData.schedules?.scheduleC?.reduceRight((acc, curr) => {
      const vals = Object.values(curr).filter((v) => typeof v === "number") as number[];
      const sum = vals.reduce((acc, curr) => acc + curr, 0);
      return acc + sum;
    }, 0);

    const scheduleENetIncome =
      taxData.schedules?.scheduleE?.properties?.reduceRight(
        (acc, curr) => acc + curr.netIncome,
        0,
      ) || 0;

    const underlying: PersonalCashFlowData = {
      lenderId: taxData.lenderId,
      "Wages and Salaries": taxData.income?.wages || 0,
      "Interest and Dividends": 0,
      "Pensions and Annuities": 0,
      "Social Security Benefits": taxData.income?.socialSecurityBenefits || 0,
      "Schedule C EBIDA": scheduleCEbida || 0,
      "Schedule D Capital Gains":
        taxData.schedules?.scheduleD?.capitalGainsAndLosses || taxData.income?.capitalGains || 0,
      "Schedule E Net Income": scheduleENetIncome,
      "Plus: Interest Expense": 0,
      "Plus: Depreciation & Amort. Expense": 0,
      "K-1 Distributions, Net Contributions*": 0,
      "Less: Federal Taxes": taxData.tax?.totalTax || 0,
      "Less: Schedule A State & Local Taxes": taxData.schedules?.scheduleA?.stateAndLocalTaxes || 0,
      "Less: FICA/Other Employer Deductions": 0.0765 * (taxData.income?.wages || 0),
      Year: taxData.year,
      "Less: Interest & dividend Income from K-1's *": 0,
      "Less: Capital Gains or Add Back Capital Losses from K-1's *": 0,
      "Schedule F Cash Flow": 0,
    };

    super(underlying);
  }
}
