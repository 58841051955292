import Select, { components, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { FC, InputHTMLAttributes } from "react";
import { SelectOption } from "@mui/base";

export interface Option {
  label: JSX.Element | string;
  value: string | number;
  email?: string;
  isCompany?: boolean;
  customerEmails?: string[];
  customerNames?: string[];
  customerRoles?: string[];
  spouseName?: string;
  externalData?: {
    sourceName: string;
    dataType: string;
    dataValue: any;
  }[];
}

interface AppFormAutoCompleteSelectFieldProp extends InputHTMLAttributes<HTMLSelectElement> {
  name?: string;
  placeholder?: string;
  label?: string;
  options?: Option[];
  onChange?: any;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  onInputChange?: any;
  defaultValue?: any;
  isCreatable?: boolean;
  formatOptionLabel?: (arg: Option, context: { context: string }) => JSX.Element | string;
  customDropdownIcon?: (props: any) => JSX.Element;
  selectedOption?: Option;
}

const AppFormAutoCompleteSelectField: FC<AppFormAutoCompleteSelectFieldProp> = (props) => {
  const {
    name,
    placeholder,
    label,
    options,
    onChange,
    closeMenuOnSelect,
    hideSelectedOptions,
    onInputChange,
    defaultValue,
    isCreatable = false,
    formatOptionLabel,
    customDropdownIcon,
    disabled = false,
    selectedOption,
  } = props;

  const customOptionContainer = (optionProps: any) => {
    const { label: optionLabel } = optionProps;

    return (
      <div>
        <components.Option
          {...optionProps}
          {...props}
          className="flex flex-row items-center justify-start"
        >
          <label htmlFor="react-select-option ">{optionLabel}</label>
        </components.Option>
      </div>
    );
  };

  const customStyles = {
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color: "black",
        backgroundColor: state?.data?.color || "white",
        minWidth: "12rem",
        width: isCreatable ? "32rem" : "auto",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      };
    },
    valueContainer: (provided: any, state: any) => {
      return {
        ...provided,
        minWidth: "14rem",
        maxWidth: "14rem",
        whiteSpace: "nowrap",
        height: "43px",
        overflow: "hidden",
        flexWrap: "nowrap",
      };
    },
    menu: (styles: any) => {
      return {
        ...styles,
        minWidth: "12rem",
        width: isCreatable ? "30rem" : "auto",
        overflow: isCreatable ? "visible" : "hidden",
        zIndex: "9999 !important",
        position: "absolute",
        top: "100%",
      };
    },
    menuPortal: (styles: any) => ({ ...styles, zIndex: 9999 }),
    input: (styles: any) => ({
      ...styles,
      "input:focus": {
        boxShadow: "none",
      },
    }),
  };

  const onChangeOption = (newValue: SingleValue<Option>, actionMeta: { action: any }) => {
    const { action } = actionMeta;
    if (action === "clear") {
      onChange(null, actionMeta);
      return;
    }

    const updatedOptionValue = (newValue as SelectOption<any>).value;
    onChange(updatedOptionValue, actionMeta);
  };

  const customIndicatorSeparator = () => {
    return null;
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const handleCreate = (inputValue: string) => {
    const newOption = createOption(inputValue);
    onChange(newOption.value, { action: "create-option" });
  };

  return (
    <div className="flex flex-col space-y-2">
      {label && (
        <span className="text-sm font-medium">
          <span>{label}</span>
        </span>
      )}
      {isCreatable ? (
        <CreatableSelect
          name={name}
          id={`createable-select-${name}`}
          className={props.className}
          defaultValue={defaultValue}
          placeholder={placeholder}
          options={options}
          value={selectedOption}
          formatOptionLabel={formatOptionLabel}
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={hideSelectedOptions}
          menuPlacement="bottom"
          components={{
            DropdownIndicator: customDropdownIcon,
            IndicatorSeparator: customIndicatorSeparator,
          }}
          menuPortalTarget={document.body}
          styles={customStyles}
          onChange={onChangeOption}
          onCreateOption={handleCreate}
          onInputChange={(a) => {
            onInputChange(a);
          }}
          isClearable={false}
          isSearchable={true}
          isDisabled={disabled}
        />
      ) : (
        <Select
          name={name}
          className={props.className}
          defaultValue={defaultValue}
          value={selectedOption}
          placeholder={placeholder}
          options={options}
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={hideSelectedOptions}
          menuPlacement="bottom"
          components={{
            Option: customOptionContainer,
            IndicatorSeparator: customIndicatorSeparator,
          }}
          menuPortalTarget={document.body}
          styles={customStyles}
          onChange={onChangeOption}
          onInputChange={onInputChange}
          isClearable={false}
          isSearchable={true}
          isDisabled={disabled}
        />
      )}
    </div>
  );
};

export default AppFormAutoCompleteSelectField;
