import { all, takeLatest } from "redux-saga/effects";
import { BootStrapActionType } from "../actions/actions.constants";

function* fetchInitialRequiredValues(): any {
  // all sagas which we call on app load will be called here
}

function* bootStrapSaga() {
  yield all([
    takeLatest(BootStrapActionType.FETCH_INITIAL_REQUIRED_VALUES, fetchInitialRequiredValues),
  ]);
}

export default bootStrapSaga;
