import { call, put, takeEvery } from "redux-saga/effects";
import { LoanWorkflows } from "../actions/actions.constants";
import { loanWorkflowService } from "src/services/loan-workflow/LoanWorkflowService";
import { setLoanWorkflows } from "src/redux/actions/loan-workflow.action";
import { WorkflowSettings, ZodWorkflowSchema } from "src/models/WorkflowSettings";
import { toastService } from "src/services/ToastService";
import { LenderWorkflowSettings } from "@prisma/client";

function* fetchLoanWorkflows(): any {
  try {
    const response: LenderWorkflowSettings[] = yield call(loanWorkflowService.getLoanWorkflows);
    yield put(
      setLoanWorkflows({
        workflows: response.map((r) => ({
          ...ZodWorkflowSchema.parse(r.settings),
          id: r.id,
        })),
      }),
    );
  } catch (e: any) {
    console.log(e);
  }
}

function* createNewLoanWorkflow(action: { type: string; payload: WorkflowSettings }): any {
  try {
    yield call(loanWorkflowService.createNewLoanWorkflow, action.payload);
    yield call(fetchLoanWorkflows);
    toastService.showSuccess("Workflow saved successfully");
  } catch (e: any) {
    console.error(e);
    toastService.showError("Failed to save workflow");
  }
}

function* updateLoanWorkflow(action: {
  type: string;
  payload: WorkflowSettings & { id: number };
}): any {
  try {
    yield call(loanWorkflowService.updateNewLoanWorkflow, action.payload);
    yield call(fetchLoanWorkflows);
    toastService.showSuccess("Workflow updated successfully");
  } catch (e: any) {
    console.error(e);
    toastService.showError("Failed to update workflow");
  }
}

function* deleteLoanWorkflow(action: { type: string; payload: { id: number } }): any {
  try {
    yield call(loanWorkflowService.deleteLoanWorkflow, action.payload);
    yield call(fetchLoanWorkflows);
    toastService.showSuccess("Workflow removed successfully");
  } catch (e: any) {
    console.error(e);
    toastService.showError("Failed to delete workflow");
  }
}

function* loanWorkflows() {
  yield takeEvery(LoanWorkflows.FETCH_LOAN_WORKFLOWS, fetchLoanWorkflows);
  yield takeEvery(LoanWorkflows.CREATE_NEW_LOAN_WORKFLOW, createNewLoanWorkflow);
  yield takeEvery(LoanWorkflows.UPDATE_LOAN_WORKFLOW, updateLoanWorkflow);
  yield takeEvery(LoanWorkflows.DELETE_LOAN_WORKFLOW, deleteLoanWorkflow);
}

export default loanWorkflows;
