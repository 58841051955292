import { createSelector } from "@reduxjs/toolkit";
import { managementStateSelector } from "src/redux/selectors/app.selectors";

export const managementDataSelector = createSelector(
  [managementStateSelector],
  (management) => management.data,
);

export const managementTableIsLoading = createSelector(
  [managementStateSelector],
  (management) => management.isDataLoading,
);

export const managementCsvDataLoading = createSelector(
  [managementStateSelector],
  (management) => management.isCsvLoading,
);

export const managementSearchParamsSelector = createSelector(
  [managementStateSelector],
  (management) => management.filters,
);
