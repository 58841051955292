import { createHash } from "crypto";
import { Readable } from "stream";

// Functions to appease typescript in some cases.
export const isDefined = <T>(value: T | undefined): value is T => {
  return value !== undefined;
};

export async function hashFileFromReadStream(readStream: Readable): Promise<string> {
  const hash = createHash("sha256");
  return new Promise((resolve, reject) => {
    readStream.on("data", (data) => hash.update(data));
    readStream.on("end", () => resolve(hash.digest("hex")));
    readStream.on("error", reject);
  });
}

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));
