import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AnalysisType } from "src/page-components/investment-detail/tabs/SpreadsTab/SpreadsTabConstants";
import { ExtractOutput } from "src/backend/services/ocr/spread-data-aggregation/strategy/data-aggregation-strategy";
import { Table } from "src/backend/services/ocr/abstractions/table";
import { extraCellTable2CSV } from "src/backend/services/ocr/abstractions/table";
import { llmApiService } from "src/services/api-services/LLMService";

export interface PostProcessingState {
  analysisStatus: Record<string, AnalysisStatus>;
  results: Record<string, any>;
  processedData: Record<string, ExtractOutput>;
}

export interface AnalysisStatus {
  isProcessing: boolean;
  error: string | null;
}

export const initialPostProcessingState: PostProcessingState = {
  analysisStatus: {},
  results: {},
  processedData: {},
};

export const performExtraAnalysis = createAsyncThunk<
  { documentId: string; result: any; extractOutput: ExtractOutput },
  ExtraAnalysisParams,
  { rejectValue: { documentId: string; error: string } }
>(
  "postProcessing/performExtraAnalysis",
  async ({ analysisType, documentId, extractOutput }, { rejectWithValue }) => {
    try {
      switch (analysisType) {
        case AnalysisType.NOI_ANALYSIS_NEW_LOAN:
        case AnalysisType.NOI_ANALYSIS_PORTFOLIO_MANAGEMENT:
          const tables: Table[] = extractOutput.output as Table[];
          const datas = tables.map(extraCellTable2CSV);
          const lineData = extractOutput.lines ?? [];
          const response = await llmApiService.parseRentRollFromCSV(datas.flat(), lineData);
          return { documentId, result: response, extractOutput };
        default:
          throw new Error(
            `Unsupported analysis type: ${analysisType} for documentId: ${documentId} in performExtraAnalysis`,
          );
      }
    } catch (error) {
      return rejectWithValue({ documentId, error: (error as Error).message });
    }
  },
);

export const postProcessingSlice = createSlice({
  name: "postProcessing",
  initialState: initialPostProcessingState,
  reducers: {
    resetPostProcessing: (state) => {
      state.analysisStatus = {};
      state.results = {};
      state.processedData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(performExtraAnalysis.pending, (state, action) => {
        const documentId = action.meta.arg.documentId;
        state.analysisStatus[documentId] = { isProcessing: true, error: null };
      })
      .addCase(
        performExtraAnalysis.fulfilled,
        (
          state,
          action: PayloadAction<{ documentId: string; result: any; extractOutput: ExtractOutput }>,
        ) => {
          const { documentId, result, extractOutput } = action.payload;
          state.analysisStatus[documentId] = { isProcessing: false, error: null };
          state.results[documentId] = result;

          // We drop geometry and confidence after the LLM analysis as its lost its context
          // Alternative implementation below is commented out
          const { source, geometry, confidence } = extractOutput;

          state.processedData[documentId] = {
            output: result,
            source,
          };

          /*
           * //@ts-expect-error `Type error: Type instantiation is excessively deep and possibly infinite.`
           * state.processedData[documentId] = {
           *   ...extractOutput,
           *   output: result,
           * };
           */
        },
      )
      .addCase(performExtraAnalysis.rejected, (state, action) => {
        if (action.payload) {
          const { documentId, error } = action.payload;
          state.analysisStatus[documentId] = { isProcessing: false, error };
        }
      });
  },
  selectors: {
    selectProcessedData: (state: PostProcessingState) => state.processedData,
    selectIsAllPostProcessingComplete: (state: PostProcessingState) =>
      Object.keys(state.analysisStatus).length &&
      Object.values(state.analysisStatus).every((status) => !status.isProcessing),
    selectPostProcessingErrors: (state: PostProcessingState) =>
      Object.entries(state.analysisStatus)
        .filter(([, status]) => status.error !== null)
        .reduce((acc, [documentId, status]) => ({ ...acc, [documentId]: status.error }), {}),
    selectIsDocIdProcessing: (state: PostProcessingState, documentId: string) =>
      state.analysisStatus[documentId].isProcessing,
    selectAnalysisStatus: (state: PostProcessingState, documentId: string) =>
      state.analysisStatus[documentId],
  },
});

export const { resetPostProcessing } = postProcessingSlice.actions;

export default postProcessingSlice.reducer;

// Helper types
export interface ExtraAnalysisParams {
  analysisType: AnalysisType;
  documentId: string;
  extractOutput: ExtractOutput;
}
