import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  documentReminderService,
  DocumentAndRemindersFetchResponse,
} from "src/services/document-reminder/DocumentReminderService";
import { DocumentAndRemindersActions } from "src/redux/actions/actions.constants";
import {
  fetchDocumentAndRemindersCompleted,
  fetchDocumentAndRemindersFailed,
} from "src/redux/actions/document-reminder.action";
import { toastService } from "src/services/ToastService";

export function* fetchDocumentAndReminders(): Generator {
  try {
    const response = yield call(documentReminderService.fetchDocumentAndReminders);

    yield put(fetchDocumentAndRemindersCompleted(response as DocumentAndRemindersFetchResponse));
  } catch (error) {
    toastService.showError(
      "An error occurred while fetching documents and reminders. Please try again.",
    ),
      yield put(
        fetchDocumentAndRemindersFailed(new Error("Could not fetch documents and reminders")),
      );
  }
}

export function* documentAndReminderSaga(): Generator {
  yield all([
    takeLatest(DocumentAndRemindersActions.FETCH_DOCUMENT_AND_REMINDERS, fetchDocumentAndReminders),
  ]);
}

export default documentAndReminderSaga;
