import { PropertyWithYearsTable } from "src/classes/RenderedDocuments/PropertyWithYears";
import { NOIAnalysisYearRollup } from "src/classes/RenderedDocuments/NOIAnalysisYearRollup";
import { NOIGroupedAssetsTable } from "../NOIGroupedAssetsTable";
import { NOIGroupedAsset } from "../NOIGroupedAsset";
import { NOITaxReturnTotalTable } from "src/classes/RenderedDocuments/NOITaxReturnTotalTable";
import { RentRollTable, RentRollTableData } from "src/classes/RenderedDocuments/RentRoll";
import { RentRollTotalTable } from "src/classes/RenderedDocuments/RentRollTotal";
import {
  formatNameForRentRoll,
  formatNameForSubject,
  getSourceFromFormType,
  TabGroup,
  NOIAnalysisTabGroupEnum,
  NOIAnalysisTabTypeEnum,
} from "src/classes/RenderedDocuments/helpers";
import { NOIAnalysisProps, TabName, TaxFormDataByYear } from "src/redux/reducers/types";
import { RenderedDoc } from "src/classes/RenderedDoc";
import { GridState } from "src/classes/GridState";
import { TaxProperty } from "src/interfaces/TaxFormData/schedules/ScheduleE";
import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { ColDef } from "ag-grid-community";
import { isDefined } from "src/backend/utils/tsutils";
import { LoanCalculatorData } from "src/classes/RenderedDocuments/LoanCalculatorRendered";
import { CanonicalRentRoll } from "src/models/CanonicalRentRoll";
import { canonicalRentRoll2RentRollData } from "src/classes/RenderedDocuments/canonicalRentRoll2RentRollData";
import spreadConfig from "src/spreads-config";
import { spreadablePropertyLabel } from "src/redux/selectors/spreadablePropertyLabel";
import { sanitizeTabName } from "src/classes/RenderedDocuments/utils";
import { PropertyTaxReturn } from "src/backend/services/ocr/abstractions/property";
import { INDIVIDUAL_PROPERTIES } from "src/redux/selectors/spread.selector";
import { JointlyOwnedPropertiesFromMultipleForms } from "src/page-components/investment-detail/tabs/SpreadsTab/prepareSpreadCalculationInputs";
import { RentalRealEstateIncomeAndExpenses } from "src/interfaces/TaxFormData/attachments/Form8825";
import { SpreadableProperty } from "src/redux/selectors/ocr.selector";
import _ from "lodash";
import {
  LenderSpreadSettings,
  SupportedLenderId,
} from "src/interfaces/SpreadsConfig/SpreadsConfig";

export type PropertyGroup = {
  properties: string[];
  name: string;
};

type Year = string;

export enum NOIAnalysisType {
  NEW_LOAN = "NEW_LOAN",
  PORTFOLIO_MANAGEMENT = "PORTFOLIO_MANAGEMENT",
}

export class RenderedNOIAnalysis {
  rentRollTotalDocs: Record<string, RentRollTotalTable> = {};
  rentRollDocs: Record<string, RentRollTable> = {};
  subjectDocs: Record<string, RentRollTable> = {};
  subjectTotalDocs: Record<string, RentRollTotalTable> = {};
  taxReturnTotalDocs: Record<string, NOITaxReturnTotalTable> = {};
  taxReturnByYearDocs: Record<Year, NOIAnalysisYearRollup> = {};
  taxReturnByPropertyDocs: Record<string, PropertyWithYearsTable> = {};
  groupedAssetsByYearDocs: Record<string, NOIGroupedAssetsTable> = {};
  gridStates: Map<TabName, GridState> = new Map();
  loanCalculatorData: LoanCalculatorData | undefined;
  groupedAssets: string[] = [];
  tabGroups: TabGroup[] = [];
  config: LenderSpreadSettings;
  lenderId: SupportedLenderId;

  constructor(
    public historicalData: NOIAnalysisProps,
    public noiAnalysisType: NOIAnalysisType,
  ) {
    // Place 2b out of 3 to unwind 'useLLMForRentRoll'
    this.lenderId = historicalData.lenderId;
    this.config = spreadConfig.lenderSettings[this.lenderId];
    const useLLMForRentRoll: boolean = this.config.useLLMForRentRoll;
    this.groupedAssets = Object.entries(historicalData.propertyGroups ?? {})
      .filter(([groupName]) => groupName !== INDIVIDUAL_PROPERTIES)
      .flatMap(([_, group]) => group.properties);

    if (useLLMForRentRoll) {
      this.processCanonicalRentRolls(historicalData);
    } else {
      this.processLegacyRentRolls(historicalData);
    }
    this.processPersonalTaxReturns();
    this.processBusinessTaxReturns();
    this.processJointlyOwnedProperties();
    this.processSubjectAssets(historicalData);
    this.processGroupedAssets();

    this.gridStates = new Map();
    for (const [docName, doc] of Object.entries(this.getDocs())) {
      this.gridStates.set(docName as TabName, doc.gridState);
    }
  }

  private formatTabName(
    tabType: NOIAnalysisTabTypeEnum,
    descriptiveName: string,
    year: string,
  ): TabName {
    switch (tabType) {
      case NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN:
        return `PTR - ${descriptiveName || year}` as TabName;
      case NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN:
        return `BTR - ${descriptiveName || year}` as TabName;
      case NOIAnalysisTabTypeEnum.CUSTOM_GROUPING:
        return `${descriptiveName} - ${year}` as TabName;
      case NOIAnalysisTabTypeEnum.JOINTLY_OWNED_PROPERTIES:
        return `JO - ${descriptiveName || year}` as TabName;
      case NOIAnalysisTabTypeEnum.SUBJECT_ASSETS:
        return formatNameForSubject(descriptiveName) as TabName;
      case NOIAnalysisTabTypeEnum.RENT_ROLL:
        return formatNameForRentRoll(descriptiveName) as TabName;
      default:
        throw new Error(`Unknown tab type: ${tabType}`);
    }
  }

  private addTab(
    tabName: TabName,
    content:
      | NOITaxReturnTotalTable
      | NOIAnalysisYearRollup
      | PropertyWithYearsTable
      | NOIGroupedAssetsTable
      | RentRollTable
      | RentRollTotalTable,
    group: NOIAnalysisTabGroupEnum,
    tabType: NOIAnalysisTabTypeEnum,
  ) {
    const sanitizeTabNameValue = sanitizeTabName(tabName);
    switch (group) {
      case NOIAnalysisTabGroupEnum.PERSONAL_TAX_RETURN_TOTAL:
        this.taxReturnTotalDocs[sanitizeTabNameValue] = content as NOITaxReturnTotalTable;
        break;
      case NOIAnalysisTabGroupEnum.BUSINESS_TAX_RETURN_TOTAL:
        this.taxReturnTotalDocs[sanitizeTabNameValue] = content as NOITaxReturnTotalTable;
        break;
      case NOIAnalysisTabGroupEnum.JOINTLY_OWNED_PROPERTIES_TOTAL:
        this.taxReturnTotalDocs[sanitizeTabNameValue] = content as NOITaxReturnTotalTable;
        break;
      case NOIAnalysisTabGroupEnum.PERSONAL_TAX_RETURN_BY_PROPERTY:
        this.taxReturnByPropertyDocs[sanitizeTabNameValue] = content as PropertyWithYearsTable;
        break;
      case NOIAnalysisTabGroupEnum.BUSINESS_TAX_RETURN_BY_PROPERTY:
        this.taxReturnByPropertyDocs[sanitizeTabNameValue] = content as PropertyWithYearsTable;
        break;
      case NOIAnalysisTabGroupEnum.JOINTLY_OWNED_PROPERTIES_BY_YEAR:
        this.taxReturnByYearDocs[sanitizeTabNameValue] = content as NOIAnalysisYearRollup;
        break;
      case NOIAnalysisTabGroupEnum.PERSONAL_TAX_RETURN_BY_YEAR:
        this.taxReturnByYearDocs[sanitizeTabNameValue] = content as NOIAnalysisYearRollup;
        break;
      case NOIAnalysisTabGroupEnum.BUSINESS_TAX_RETURN_BY_YEAR:
        this.taxReturnByYearDocs[sanitizeTabNameValue] = content as NOIAnalysisYearRollup;
        break;
      case NOIAnalysisTabGroupEnum.JOINTLY_OWNED_PROPERTIES:
        this.taxReturnByPropertyDocs[sanitizeTabNameValue] = content as PropertyWithYearsTable;
        break;
      case NOIAnalysisTabGroupEnum.GROUPED_ASSETS_BY_YEAR:
        this.groupedAssetsByYearDocs[sanitizeTabNameValue] = content as NOIGroupedAssetsTable;
        break;
      case NOIAnalysisTabGroupEnum.SUBJECT_ASSETS:
        this.subjectDocs[sanitizeTabNameValue] = content as RentRollTable;
        break;
      case NOIAnalysisTabGroupEnum.SUBJECT_ASSETS_TOTAL:
        this.subjectTotalDocs[sanitizeTabNameValue] = content as RentRollTotalTable;
        break;
      case NOIAnalysisTabGroupEnum.RENT_ROLL:
        this.rentRollDocs[sanitizeTabNameValue] = content as RentRollTable;
        break;
      case NOIAnalysisTabGroupEnum.RENT_ROLL_TOTAL:
        this.rentRollTotalDocs[sanitizeTabNameValue] = content as RentRollTotalTable;
        break;
    }

    this.tabGroups.push({
      tabName: sanitizeTabName(tabName) as TabName,
      fullTabName: tabName,
      group,
      tabType,
    });
  }

  private groupPersonalTaxReturnProperty(
    propertyName: string,
    groupName: string,
    processedGroupedProperties: Record<string, Record<string, NOIGroupedAsset[]>>,
  ): void {
    const taxReturnsByYear = Object.entries(this.historicalData.personalTaxReturns).find(
      ([_year, forms]) => {
        return forms.some((form) =>
          form.schedules?.scheduleE?.properties?.some(
            (property) => property.propertyAddress === propertyName,
          ),
        );
      },
    );

    if (!taxReturnsByYear) {
      return;
    }

    const [year, forms] = taxReturnsByYear;
    const form = forms.find((form) =>
      form.schedules?.scheduleE?.properties?.some(
        (property) => property.propertyAddress === propertyName,
      ),
    );
    const property = form?.schedules?.scheduleE?.properties?.find(
      (property) => property.propertyAddress === propertyName,
    );

    if (!property) {
      return;
    }

    if (!processedGroupedProperties[groupName]) {
      processedGroupedProperties[groupName] = {};
    }
    const source = getSourceFromFormType(form?.form);

    const groupedAsset = NOIGroupedAsset.fromTaxProperty(
      property,
      Number(year),
      form?.entityName ?? "",
      source,
    );

    processedGroupedProperties[groupName][year] = [
      ...(processedGroupedProperties[groupName][year] ?? []),
      groupedAsset,
    ];
  }

  private groupBusinessTaxReturnProperty(
    propertyName: string,
    groupName: string,
    processedGroupedProperties: Record<string, Record<string, NOIGroupedAsset[]>>,
  ): void {
    const btrProperty = Object.entries(this.historicalData.businessTaxReturns).find(
      ([_year, forms]) => {
        return forms.some((form) =>
          form.form8825?.propertyData?.some(
            (property) => property.propertyAddress === propertyName,
          ),
        );
      },
    );

    if (!btrProperty) {
      return;
    }

    const [year, forms] = btrProperty;
    const form = forms.find((form) =>
      form.form8825?.propertyData?.some((property) => property.propertyAddress === propertyName),
    );
    const property = form?.form8825?.propertyData?.find(
      (property) => property.propertyAddress === propertyName,
    );

    if (!property) {
      return;
    }

    if (!processedGroupedProperties[groupName]) {
      processedGroupedProperties[groupName] = {};
    }
    const source = getSourceFromFormType(form?.form);

    const groupedAsset = NOIGroupedAsset.fromRentalRealEstateIncomeAndExpenses(
      property,
      Number(year),
      form?.entityName ?? "",
      source,
    );

    processedGroupedProperties[groupName][year] = [
      ...(processedGroupedProperties[groupName][year] ?? []),
      groupedAsset,
    ];
  }

  private groupJointlyOwnedProperty(
    propertyName: string,
    groupName: string,
    processedGroupedProperties: Record<string, Record<string, NOIGroupedAsset[]>>,
  ) {
    if (!this.historicalData.jointlyOwnedProperties) {
      return;
    }
    const formWithProperties = Object.entries(this.historicalData.jointlyOwnedProperties).find(
      ([year, forms]) => {
        return forms.some((form) =>
          form.properties.some((property) => property.propertyAddress === propertyName),
        );
      },
    );
    if (!formWithProperties) {
      return;
    }

    const [year, forms] = formWithProperties;
    const form = forms.find((form) =>
      form.properties.some((property) => property.propertyAddress === propertyName),
    );
    const property = form?.properties.find((property) => property.propertyAddress === propertyName);
    if (!property) {
      return;
    }

    if (!processedGroupedProperties[groupName]) {
      processedGroupedProperties[groupName] = {};
    }
    const source = getSourceFromFormType(form?.form);

    let groupedAsset: NOIGroupedAsset;
    if (form?.propertyType === "TaxProperty") {
      groupedAsset = NOIGroupedAsset.fromTaxProperty(
        property,
        Number(year),
        form?.entityName ?? "",
        source,
      );
    } else {
      groupedAsset = NOIGroupedAsset.fromRentalRealEstateIncomeAndExpenses(
        property,
        Number(year),
        form?.entityName ?? "",
        source,
      );
    }
    processedGroupedProperties[groupName][year] = [
      ...(processedGroupedProperties[groupName][year] ?? []),
      groupedAsset,
    ];
  }

  private groupRentRollProperty(
    propertyName: string,
    groupName: string,
    processedGroupedProperties: Record<string, Record<string, NOIGroupedAsset[]>>,
  ): void {
    const rentRoll = Object.entries(this.historicalData.rentRolls).find(([_year, properties]) => {
      return properties.some(
        (property) =>
          spreadablePropertyLabel(property as SpreadableProperty)
            .trim()
            .toLowerCase() === propertyName.trim().toLowerCase(),
      );
    });
    if (rentRoll) {
      const [year, properties] = rentRoll;
      const rentRollProperty = properties.find(
        (property) => spreadablePropertyLabel(property) === propertyName,
      );
      if (rentRollProperty) {
        if (!processedGroupedProperties[groupName]) {
          processedGroupedProperties[groupName] = {};
        }
        processedGroupedProperties[groupName][year] = [
          ...(processedGroupedProperties[groupName][year] ?? []),
          NOIGroupedAsset.fromCanonicalRentRoll(rentRollProperty),
        ];
      }
    }
    const legacyRentRoll = Object.entries(this.historicalData.legacyRentRolls).find(
      ([_year, properties]) => {
        return properties.some((property) => property.propertyName === propertyName);
      },
    );
    if (legacyRentRoll) {
      const [year, properties] = legacyRentRoll;
      const legacyRentRollProperty = properties.find(
        (property) => property.propertyName === propertyName,
      );
      if (legacyRentRollProperty) {
        if (!processedGroupedProperties[groupName]) {
          processedGroupedProperties[groupName] = {};
        }
        processedGroupedProperties[groupName][year] = [
          ...(processedGroupedProperties[groupName][year] ?? []),
          NOIGroupedAsset.fromLegacyRentRoll(legacyRentRollProperty),
        ];
      }
    }
  }

  private processGroupedAssets(): void {
    interface YearProperties {
      [year: string]: NOIGroupedAsset[];
    }
    interface GroupedProperties {
      [groupName: string]: YearProperties;
    }

    const processedGroupedProperties: GroupedProperties = {};
    Object.entries(this.historicalData.propertyGroups ?? {}).forEach(([groupName, group]) => {
      if (groupName === INDIVIDUAL_PROPERTIES) {
        return;
      }
      const propertiesInCurrentGroup = group.properties;
      propertiesInCurrentGroup.map((propertyName) => {
        this.groupPersonalTaxReturnProperty(propertyName, groupName, processedGroupedProperties);
        this.groupBusinessTaxReturnProperty(propertyName, groupName, processedGroupedProperties);
        this.groupJointlyOwnedProperty(propertyName, groupName, processedGroupedProperties);
        this.groupRentRollProperty(propertyName, groupName, processedGroupedProperties);
      });
    });

    Object.entries(processedGroupedProperties).forEach(([groupName, yearProperties]) => {
      Object.entries(yearProperties).forEach(([year, properties]) => {
        const formattedName = this.formatTabName(
          NOIAnalysisTabTypeEnum.CUSTOM_GROUPING,
          groupName,
          year,
        );
        this.addTab(
          formattedName as TabName,
          new NOIGroupedAssetsTable(properties, this.lenderId, this.noiAnalysisType),
          NOIAnalysisTabGroupEnum.GROUPED_ASSETS_BY_YEAR,
          NOIAnalysisTabTypeEnum.CUSTOM_GROUPING,
        );
      });
    });
  }

  private processSubjectAssets(historicalData: NOIAnalysisProps): void {
    const subjectAssets = historicalData.subjectAssets;
    if (subjectAssets.length === 0) {
      return;
    }
    subjectAssets.forEach((asset) => {
      const formattedSubjectName = this.formatTabName(
        NOIAnalysisTabTypeEnum.SUBJECT_ASSETS,
        spreadablePropertyLabel(asset),
        "",
      );
      const computedSubjectDocs = new RentRollTable(canonicalRentRoll2RentRollData(asset));
      this.addTab(
        formattedSubjectName,
        computedSubjectDocs,
        NOIAnalysisTabGroupEnum.SUBJECT_ASSETS,
        NOIAnalysisTabTypeEnum.SUBJECT_ASSETS,
      );
    });

    if (Object.keys(this.subjectDocs).length > 1) {
      const formattedTotal = this.formatTabName(NOIAnalysisTabTypeEnum.SUBJECT_ASSETS, "Total", "");
      this.addTab(
        formattedTotal,
        new RentRollTotalTable(subjectAssets.map(canonicalRentRoll2RentRollData), []),
        NOIAnalysisTabGroupEnum.SUBJECT_ASSETS_TOTAL,
        NOIAnalysisTabTypeEnum.SUBJECT_ASSETS,
      );
    }
  }

  /* Process Rent Rolls
   * ==================
   * Assumptions:
   * - Show only the most recent year
   * - Create "RR - Total" only if more than one property
   * - Excel errors desired if property is missing
   */

  /* Part 2a/3 to unwind 'useLLMForRentRoll' */
  private processLegacyRentRolls(historicalData: NOIAnalysisProps): void {
    // Filter out subject assets from rent rolls
    const inScopeRentRolls = Object.entries(historicalData.legacyRentRolls).reduce(
      (acc, [year, properties]) => {
        acc[year] = properties.filter((rentRoll) => {
          return !historicalData.legacySubjectAssets.some(
            (subjectAsset) => subjectAsset.propertyName === rentRoll.propertyName,
          );
        }) as RentRollTableData[];
        return acc;
      },
      {} as Record<string, RentRollTableData[]>,
    );
    if (Object.keys(inScopeRentRolls).length === 0) {
      return;
    }

    const years = Object.keys(inScopeRentRolls).map(Number);
    const mostRecentYear = Math.max(...years);
    const mostRecentYearString = mostRecentYear.toString();

    // Get most recent year's rent rolls
    const rentRollData = inScopeRentRolls[mostRecentYearString];
    const rentRolls = (
      (Array.isArray(rentRollData) ? rentRollData : [rentRollData]) as RentRollTableData[]
    ).filter(isDefined);

    if (rentRolls.length === 0) {
      return;
    }

    const formattedTotal = this.formatTabName(NOIAnalysisTabTypeEnum.RENT_ROLL, "Total", "");

    const rentRollTotal = new RentRollTotalTable(
      rentRolls,
      this.historicalData.legacySubjectAssets,
    );
    this.addTab(
      formattedTotal as TabName,
      rentRollTotal,
      NOIAnalysisTabGroupEnum.RENT_ROLL_TOTAL,
      NOIAnalysisTabTypeEnum.RENT_ROLL,
    );

    inScopeRentRolls[mostRecentYearString].forEach((item) => {
      if (item.propertyName === undefined) {
        return;
      }
      const rentRollData = item as RentRollTableData;
      const formattedPropertyName = this.formatTabName(
        NOIAnalysisTabTypeEnum.RENT_ROLL,
        item.propertyName,
        "",
      );
      const computedRentRollDocs = new RentRollTable(rentRollData);
      this.addTab(
        formattedPropertyName as TabName,
        computedRentRollDocs,
        NOIAnalysisTabGroupEnum.RENT_ROLL,
        NOIAnalysisTabTypeEnum.RENT_ROLL,
      );
    });
  }

  private processCanonicalRentRolls(historicalData: NOIAnalysisProps): void {
    // Filter out subject assets from rent rolls
    const inScopeRentRolls = Object.entries(historicalData.rentRolls).reduce(
      (acc, [year, properties]) => {
        acc[year] = properties.filter((rentRoll) => {
          return !historicalData.subjectAssets.some(
            (subjectAsset) =>
              spreadablePropertyLabel(subjectAsset) === spreadablePropertyLabel(rentRoll),
          );
        }) as CanonicalRentRoll[];
        return acc;
      },
      {} as Record<string, CanonicalRentRoll[]>,
    );
    if (Object.keys(inScopeRentRolls).length === 0) {
      return;
    }

    const years = Object.keys(inScopeRentRolls).map(Number);
    const mostRecentYear = Math.max(...years);
    const mostRecentYearString = mostRecentYear.toString();

    // Get most recent year's rent rolls
    const rentRollData = inScopeRentRolls[mostRecentYearString];
    const rentRolls = (
      (Array.isArray(rentRollData) ? rentRollData : [rentRollData]) as CanonicalRentRoll[]
    ).filter(isDefined);

    if (rentRolls.length === 0) {
      return;
    }

    if (rentRolls.length > 1) {
      const formattedTotal = this.formatTabName(NOIAnalysisTabTypeEnum.RENT_ROLL, "Total", "");
      const computedRentRollTotalDoc = new RentRollTotalTable(
        rentRolls.map(canonicalRentRoll2RentRollData),
        historicalData.subjectAssets.map(canonicalRentRoll2RentRollData),
      );
      this.addTab(
        formattedTotal,
        computedRentRollTotalDoc,
        NOIAnalysisTabGroupEnum.RENT_ROLL_TOTAL,
        NOIAnalysisTabTypeEnum.RENT_ROLL,
      );
    }
    inScopeRentRolls[mostRecentYearString].forEach((item) => {
      const rentRollData = item as CanonicalRentRoll;
      const formattedPropertyName = this.formatTabName(
        NOIAnalysisTabTypeEnum.RENT_ROLL,
        spreadablePropertyLabel(rentRollData),
        "",
      );
      const computedRentRollDocs = RentRollTable.from(rentRollData);
      this.addTab(
        formattedPropertyName,
        computedRentRollDocs,
        NOIAnalysisTabGroupEnum.RENT_ROLL,
        NOIAnalysisTabTypeEnum.RENT_ROLL,
      );
    });
  }

  /* Process Personal Tax returns
   * ============================
   * Assumptions:
   * - Show all years
   * - Create "PTR - Total" and yearly breakdowns (i.e. "PTR - 2023") only if more than one property
   * - Excel errors desired if property or yearly data is missing
   */

  public formatNameForPersonalTaxReturn(name: string): string {
    return sanitizeTabName(`PTR - ${name}`);
  }

  public formatNameForBusinessTaxReturn(name: string): string {
    return sanitizeTabName(`BTR - ${name}`);
  }

  public formatNameForJointlyOwnedProperties(name: string): string {
    return sanitizeTabName(`JO - ${name}`);
  }

  coveredYearsForPersonalTaxReturns(): Year[] {
    return Object.keys(this.taxReturnByYearDocs);
  }

  // Creates, e.g. "PTR - 2023", where each column references a particular property
  private processPersonalTaxReturnsByYear(personalTaxReturns: TaxFormDataByYear<Form1040>): void {
    if (Object.entries(personalTaxReturns).length === 0) {
      return;
    }
    for (const [year, forms] of Object.entries(personalTaxReturns)) {
      if (forms.length === 0) {
        continue;
      }
      const entityName = forms[0].entityName;
      forms?.forEach((form) => {
        const yearColumn = Object.keys(personalTaxReturns).indexOf(year) + 2;
        const formattedName = this.formatTabName(
          NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN,
          `${year} - ${entityName}`,
          "",
        );
        this.addTab(
          formattedName as TabName,
          new NOIAnalysisYearRollup(
            year,
            (form?.schedules?.scheduleE?.properties || []).map((property) => ({
              ...property,
              form: form.form,
            })),
            yearColumn,
            this.formatNameForPersonalTaxReturn,
            this.lenderId,
            this.noiAnalysisType,
          ),
          NOIAnalysisTabGroupEnum.PERSONAL_TAX_RETURN_BY_YEAR,
          NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN,
        );
      });
    }
  }

  private processJointlyOwnedPropertiesByYear(
    allJointlyOwnedProperties: TaxFormDataByYear<JointlyOwnedPropertiesFromMultipleForms>,
  ) {
    for (const [year, forms] of Object.entries(allJointlyOwnedProperties)) {
      forms?.forEach((form) => {
        const yearColumn = Object.keys(allJointlyOwnedProperties).indexOf(year) + 2;
        const tabName = this.formatTabName(
          NOIAnalysisTabTypeEnum.JOINTLY_OWNED_PROPERTIES,
          "",
          year,
        );
        const taxFormByYear = new NOIAnalysisYearRollup(
          year,
          form.properties,
          yearColumn,
          this.formatNameForJointlyOwnedProperties,
          this.lenderId,
          this.noiAnalysisType,
        );
        this.addTab(
          tabName,
          taxFormByYear,
          NOIAnalysisTabGroupEnum.JOINTLY_OWNED_PROPERTIES_BY_YEAR,
          NOIAnalysisTabTypeEnum.JOINTLY_OWNED_PROPERTIES,
        );
      });
    }
  }

  private processBusinessTaxReturnsByYear(
    businessTaxReturns: TaxFormDataByYear<Form1065 | Form1120S>,
  ): void {
    if (Object.entries(businessTaxReturns).length === 0) {
      return;
    }
    for (const [year, forms] of Object.entries(businessTaxReturns)) {
      const yearColumn = Object.keys(businessTaxReturns).indexOf(year) + 2;
      const properties = forms
        .map((form) => form.form8825?.propertyData)
        .flat()
        .filter((property) => property !== undefined)
        .flat() as TaxProperty[];
      const entityName = forms[0].entityName;
      const formattedName = this.formatTabName(
        NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN,
        `${year} - ${entityName}`,
        "",
      );
      this.addTab(
        formattedName as TabName,
        new NOIAnalysisYearRollup(
          year,
          properties,
          yearColumn,
          this.formatNameForBusinessTaxReturn,
          this.lenderId,
          this.noiAnalysisType,
        ),
        NOIAnalysisTabGroupEnum.BUSINESS_TAX_RETURN_BY_YEAR,
        NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN,
      );
    }
  }

  private processBusinessTaxReturnsByProperty(
    businessTaxReturns: TaxFormDataByYear<Form1065 | Form1120S>,
  ): void {
    const byPropertyByYear: Record<string, Record<string, TaxProperty>> = {};
    const propertyToEntity: Record<string, string> = {};

    for (const [year, forms] of Object.entries(businessTaxReturns)) {
      forms?.forEach((form, _index) => {
        const formType = form.form;
        const entityName = form.entityName;
        form.form8825?.propertyData?.forEach((property) => {
          propertyToEntity[property.propertyAddress] = entityName;

          const propertyName = property.propertyAddress?.toString();
          if (!propertyName) {
            throw new Error("Property name is missing");
          }

          const formatted = this.formatTabName(
            NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN,
            propertyName,
            year,
          );

          if (!byPropertyByYear[formatted]) {
            byPropertyByYear[formatted] = {};
          }

          byPropertyByYear[formatted][year] = { form: formType, ...property };
        });
      });
    }

    // Now that we have a property-centric view, we can create PropertyByYearDoc objects
    for (const [tabName, yearlyData] of Object.entries(byPropertyByYear)) {
      const propertyAddresses = Object.values(yearlyData).map(
        (property) => property.propertyAddress,
      );
      //make sure all properties have the same address
      if (propertyAddresses.some((address) => address !== propertyAddresses[0])) {
        throw new Error("All properties must have the same address for a by-year document");
      }
      const propertyAddress = propertyAddresses[0];
      const entityName = propertyToEntity[propertyAddress];
      const doc = new PropertyWithYearsTable(propertyAddress, yearlyData, entityName);
      this.addTab(
        tabName as TabName,
        doc,
        NOIAnalysisTabGroupEnum.BUSINESS_TAX_RETURN_BY_PROPERTY,
        NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN,
      );
    }
  }

  // Creates, e.g. "PTR - 12 Ford St" and "PTR - 20 Acacia Ave"
  private processPersonalTaxReturnsByProperty(
    personalTaxReturns: TaxFormDataByYear<Form1040>,
  ): void {
    // Transforming the data structure to be property-centric rather than year-centric
    const byPropertyByYear: Record<string, Record<string, TaxProperty>> = {};
    const propertyToEntity: Record<string, string> = {};
    const propertyToLender: Record<string, number> = {};

    for (const [year, forms] of Object.entries(personalTaxReturns)) {
      forms?.forEach((form) => {
        const { entityName, lenderId } = form;
        form.schedules?.scheduleE?.properties?.forEach((property) => {
          propertyToEntity[property.propertyAddress] = entityName;
          propertyToLender[property.propertyAddress] = lenderId;

          const propertyName = property.propertyAddress?.toString();
          if (!propertyName) {
            throw new Error("Property name is missing");
          }

          const formatted = this.formatTabName(
            NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN,
            propertyName,
            year,
          );

          if (!byPropertyByYear[formatted]) {
            byPropertyByYear[formatted] = {};
          }

          byPropertyByYear[formatted][year] = { ...property, form: form.form };
        });
      });
    }

    // Now that we have a property-centric view, we can create PropertyByYearDoc objects
    for (const [tabName, yearlyData] of Object.entries(byPropertyByYear)) {
      const propertyAddresses = Object.values(yearlyData).map(
        (property) => property.propertyAddress,
      );
      //make sure all properties have the same address
      if (propertyAddresses.some((address) => address !== propertyAddresses[0])) {
        throw new Error("All properties must have the same address for a by-year document");
      }
      const propertyAddress = propertyAddresses[0];
      const entityName = propertyToEntity[propertyAddress];
      const lenderId = propertyToLender[propertyAddress];
      const asIncomeAssetParts = Object.entries(yearlyData).map(([year, property]) => {
        return [
          year,
          {
            ...PropertyTaxReturn.from(property, property.propertyAddress, lenderId),
            form: property.form,
          },
        ];
      });
      const data = Object.fromEntries(asIncomeAssetParts);
      const doc = new PropertyWithYearsTable(propertyAddress, data, entityName);
      this.addTab(
        tabName as TabName,
        doc,
        NOIAnalysisTabGroupEnum.PERSONAL_TAX_RETURN_BY_PROPERTY,
        NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN,
      );
    }
  }

  private processJointlyOwnedPropertiesByProperty(
    allJointlyOwnedProperties: TaxFormDataByYear<JointlyOwnedPropertiesFromMultipleForms>,
  ) {
    const byPropertyByYear: Record<
      string,
      Record<string, TaxProperty | RentalRealEstateIncomeAndExpenses>
    > = {};
    const propertyToEntity: Record<string, string> = {};
    const propertyToLender: Record<string, number> = {};

    for (const [year, forms] of Object.entries(allJointlyOwnedProperties)) {
      forms?.forEach((form) => {
        form.properties?.forEach((property) => {
          propertyToEntity[property.propertyAddress] = form.entityName;
          propertyToLender[property.propertyAddress] = form.lenderId;

          const propertyName = property.propertyAddress?.toString();
          if (!propertyName) {
            throw new Error("Property name is missing");
          }

          const formatted = this.formatNameForJointlyOwnedProperties(propertyName);

          if (!byPropertyByYear[formatted]) {
            byPropertyByYear[formatted] = {};
          }

          byPropertyByYear[formatted][year] = { ...property, propertyType: form.propertyType };
        });
      });
    }

    for (const [tabName, yearlyData] of Object.entries(byPropertyByYear)) {
      const sanitizedTabName = sanitizeTabName(tabName);
      const propertyAddresses = Object.values(yearlyData).map(
        (property) => property.propertyAddress,
      );
      //make sure all properties have the same address
      if (propertyAddresses.some((address) => address !== propertyAddresses[0])) {
        throw new Error("All properties must have the same address for a by-year document");
      }
      const propertyAddress = propertyAddresses[0];
      const entityName = propertyToEntity[propertyAddress];
      const lenderId = propertyToLender[propertyAddress];
      const asIncomeAssetParts = Object.entries(yearlyData).map(([year, property]) => {
        return [
          year,
          {
            // @ts-ignore
            ...(property.propertyType === "TaxProperty"
              ? PropertyTaxReturn.from(property, property.propertyAddress, lenderId)
              : property),
          },
        ];
      });
      const data = Object.fromEntries(asIncomeAssetParts);
      const doc = new PropertyWithYearsTable(propertyAddress, data, entityName);
      this.addTab(
        sanitizedTabName as TabName,
        doc,
        NOIAnalysisTabGroupEnum.JOINTLY_OWNED_PROPERTIES,
        NOIAnalysisTabTypeEnum.JOINTLY_OWNED_PROPERTIES,
      );
    }
  }

  private processBusinessTaxReturns(): void {
    const rawForms = Object.values(this.historicalData.businessTaxReturns).flat();
    const formsByEntity = _.groupBy(rawForms, "entityName");

    Object.entries(formsByEntity).forEach(([entityName, forms]) => {
      const formsByYear = _.groupBy(forms, "year");
      const properties = Object.values(formsByYear)
        .flatMap((forms) => forms.map((form) => form.form8825?.propertyData ?? []))
        .flat();
      if (properties.length === 0) {
        return;
      }

      // 1 - Get Total from yearly summary sheet for most recent year
      const formattedTotal = this.formatTabName(
        NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN,
        `Total - ${entityName}`,
        "Total",
      );
      const numberProperties = properties.length;

      const totalBusinessTaxReturn = new NOITaxReturnTotalTable(
        formsByYear,
        (year) => sanitizeTabName(`BTR - ${year} - ${entityName}`),
        numberProperties, // For determining the number of columns to sum in the other (yearly) sheet
        this.lenderId,
        "B",
        this.noiAnalysisType,
      );

      this.addTab(
        formattedTotal,
        totalBusinessTaxReturn,
        NOIAnalysisTabGroupEnum.BUSINESS_TAX_RETURN_TOTAL,
        NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN,
      );

      // Process yearly summaries, e.g. "BTR - 2023", "BTR -2022", etc.
      this.processBusinessTaxReturnsByYear(formsByYear);
    });

    const allBusinessTaxReturns = this.historicalData.businessTaxReturns;
    // Process property-specific docs, e.g. "BTR - 12 Ford St", "BTR - 20 Acacia Ave", etc.
    this.processBusinessTaxReturnsByProperty(allBusinessTaxReturns);
  }

  // Bind above function to create Total, Yearly Summaries, and Property-specific docs
  private processPersonalTaxReturns(): void {
    const rawForms = Object.values(this.historicalData.personalTaxReturns).flat();
    const formsByEntity = _.groupBy(rawForms, "entityName");

    Object.entries(formsByEntity).forEach(([entityName, forms]) => {
      const formsByYear = _.groupBy(forms, "year");
      const properties = Object.values(formsByYear)
        .flatMap((forms) => forms.map((form) => form.schedules?.scheduleE?.properties ?? []))
        .flat();
      if (properties.length === 0) {
        return;
      }

      const formattedTotal = this.formatTabName(
        NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN,
        `Total - ${entityName}`,
        "Total",
      );
      const numberProperties = properties.length;

      const totalPersonalTaxReturn = new NOITaxReturnTotalTable(
        formsByYear, //  to be replaced by all tax returns grouped by entity
        (year) => sanitizeTabName(`PTR - ${year} - ${entityName}`),
        numberProperties, // For determining the number of columns to sum in the other (yearly) sheet
        this.lenderId,
        "B",
        this.noiAnalysisType,
      );

      this.addTab(
        formattedTotal,
        totalPersonalTaxReturn,
        NOIAnalysisTabGroupEnum.PERSONAL_TAX_RETURN_TOTAL,
        NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN,
      );

      // Process yearly summaries, e.g. "PTR - <Entity Name> 2023", "PTR - <Entity Name> 2022", etc.
      this.processPersonalTaxReturnsByYear(formsByYear);
    });

    const allPersonalTaxReturns = this.historicalData.personalTaxReturns;
    // Process property-specific docs, e.g. "PTR - 12 Ford St", "PTR - 20 Acacia Ave", etc.
    this.processPersonalTaxReturnsByProperty(allPersonalTaxReturns);
  }

  /**
   * Separated because the properties represented here are jointly owned
   * and should not be represented in the ungrouped personal or business tax returns
   */
  private processJointlyOwnedProperties() {
    const allJointlyOwnedProperties = this.historicalData.jointlyOwnedProperties ?? {};
    const properties = Object.values(allJointlyOwnedProperties)
      .flatMap((forms) => forms.map((form) => form.properties ?? []))
      .flat();
    if (properties.length === 0) {
      return;
    }

    const tabName = this.formatTabName(
      NOIAnalysisTabTypeEnum.JOINTLY_OWNED_PROPERTIES,
      "",
      "Total",
    );
    const numberProperties = properties.length;

    const totalJointlyOwnedProperties = new NOITaxReturnTotalTable(
      allJointlyOwnedProperties,
      this.formatNameForJointlyOwnedProperties,
      numberProperties,
      this.lenderId,
      "B",
      this.noiAnalysisType,
    );
    this.addTab(
      tabName,
      totalJointlyOwnedProperties,
      NOIAnalysisTabGroupEnum.JOINTLY_OWNED_PROPERTIES_TOTAL,
      NOIAnalysisTabTypeEnum.JOINTLY_OWNED_PROPERTIES,
    );
    this.processJointlyOwnedPropertiesByYear(allJointlyOwnedProperties);
    this.processJointlyOwnedPropertiesByProperty(allJointlyOwnedProperties);
  }

  public getDocs(): Record<TabName, RenderedDoc> {
    return {
      ...this.rentRollTotalDocs,
      ...this.rentRollDocs,
      ...this.taxReturnTotalDocs,
      ...this.taxReturnByYearDocs,
      ...this.taxReturnByPropertyDocs,
      ...this.subjectDocs,
      ...this.subjectTotalDocs,
      ...this.groupedAssetsByYearDocs,
    };
  }

  public columnDefs(): Record<TabName, ColDef[]> {
    return Object.entries(this.getDocs()).reduce(
      (acc, [key, value]) => {
        return { ...acc, [key]: value.columnDefs };
      },
      {} as Record<TabName, ColDef[]>,
    );
  }
}
