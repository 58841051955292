import { baseApiService } from "./api-services/BaseApiService";
import { DocumentQuality } from "src/Enums/DocumentQualityEnum";

type UploadFileArgs = {
  file: File | null;
  onUploadProgress: any;
  entityId: number;
  isReview: boolean;

  documentPeriodJson?: string;
  guarantorName?: string;
  index?: number;
  displayName?: string;
} & (
  | {
      documentTitle: string;
      documentYear: string;
      documentQuality?: DocumentQuality;
      documentQuarter?: string;
      documentMonth?: string;
      documentExtraDescription?: string;
    }
  | {
      documentTypeId: string;
      documentYear: string;
      documentQuality?: DocumentQuality;
      documentQuarter?: string;
      documentMonth?: string;
      documentExtraDescription?: string;
    }
);

type ReviewUploadFileArgs = {
  file: File;
  onUploadProgress: any;
  lenderId: number;
  documentRequestId: number;
};

type DeleteFileArgs = {
  isReview: boolean;
};

class UploadService {
  static getInstance(): UploadService {
    return new UploadService();
  }

  // Note: see document.api.ts for an explanation of how these fields are used.
  async uploadFile(url: string, args: UploadFileArgs) {
    const fileFormData = new FormData();

    if (args.file) {
      fileFormData.append("document", args.file);
    }

    fileFormData.append("entityId", String(args.entityId));
    fileFormData.append("isReview", String(args.isReview));
    if (args.index !== undefined) {
      fileFormData.append("subQuestionIndex", String(args.index));
    }
    if (args.guarantorName) {
      fileFormData.append("guarantorName", args.guarantorName);
    }
    if (args.documentPeriodJson) {
      fileFormData.append("documentPeriodJson", args.documentPeriodJson);
    }
    if ("documentTypeId" in args) {
      fileFormData.append("documentTypeId", args.documentTypeId);
    } else {
      fileFormData.append("documentTitle", args.documentTitle);
    }
    fileFormData.append("documentYear", args.documentYear);
    args.documentQuality && fileFormData.append("documentQuality", args.documentQuality);
    args.documentQuarter && fileFormData.append("documentQuarter", args.documentQuarter);
    args.documentMonth && fileFormData.append("documentMonth", args.documentMonth);
    args.displayName && fileFormData.append("displayName", args.displayName);
    args.documentExtraDescription &&
      fileFormData.append("documentExtraDescription", args.documentExtraDescription);
    return baseApiService.post(
      url,
      fileFormData,
      args.file
        ? {
            headers: {
              "Content-Type": args.file.type,
              "Content-Disposition": `filename=${(args.file.name || "").replace(/,/g, "")}`,
            },
            onUploadProgress: args.onUploadProgress,
          }
        : {},
    );
  }

  async deleteDocument(url: string, args: DeleteFileArgs) {
    return baseApiService.delete(url, {
      params: {
        isReview: args.isReview.toString(),
      },
    });
  }

  async reviewUploadFile(url: string, args: ReviewUploadFileArgs) {
    const fileFormData = new FormData();

    fileFormData.append("document", args.file);
    fileFormData.append("lenderId", String(args.lenderId));
    fileFormData.append("documentRequestId", String(args.documentRequestId));

    return baseApiService.post(url, fileFormData, {
      headers: {
        "Content-Type": args.file.type,
        "Content-Disposition": `filename=${(args.file.name || "").replace(/,/g, "")}`,
      },
    });
  }
}

export const uploadService = UploadService.getInstance();
