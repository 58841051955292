import { theme } from "src/themes/base";

export function applyTheme(selectedTheme: { [key: string]: string }) {
  const root = document.documentElement as HTMLElement;
  if (!selectedTheme) {
    selectedTheme = theme["1"];
  }
  Object.keys(selectedTheme).forEach((cssVar) => {
    root.style.setProperty(cssVar, selectedTheme[cssVar]);
  });
}
