import React, { FC, useEffect, useState } from "react";
import { Loader } from "@mantine/core";
import cx from "classnames";
import { GridLoader } from "react-spinners";

interface Props {
  variant: "oval" | "grid";
  dark?: boolean;
  className?: string;
  // Passed to react-spinners which handles size as follows:
  //  - If value is number, the loader will default to css unit px.
  //  - If value is string, the loader will verify the unit against valid css units.
  //    - If unit is valid, return the original value
  //    - If unit is invalid, output warning console log and default to px.
  //
  // (See: https://www.npmjs.com/package/react-spinners)
  size?: number | string;
}

// A generic animated loader in an Interfold color.
export const AppLoader: FC<Props> = ({ variant, dark, className, size }) => {
  // The loaders in react-spinners are not SSR friendly
  // (they put some kind of changing value into their props,
  // which causes this error: https://nextjs.org/docs/messages/react-hydration-error)
  // so we need to make sure we only render them on the client.
  const [isRenderingOnClient, setIsRenderingOnClient] = useState(false);

  useEffect(() => {
    setIsRenderingOnClient(true);
  }, []);

  // Note: add to this check if we add more variants!
  if (variant === "grid" && !isRenderingOnClient) {
    return null;
  }

  switch (variant) {
    case "grid":
      return (
        <GridLoader
          aria-label="Loading"
          className={cx("m-auto", className ? className : "")}
          color={dark ? "#434F5D" : "#E7EDF4"}
          size={size || 15}
        />
      );
    case "oval":
      return <Loader aria-label="Loading" size={size || "md"} className={className} />;
  }
};
