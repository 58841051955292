import { RawCellContent } from "hyperformula";
import {
  LenderSpreadSettings,
  SupportedLenderId,
} from "src/interfaces/SpreadsConfig/SpreadsConfig";
import spreadConfig from "src/spreads-config";
import { AutoRendered } from "../AutoRendered";

export interface DSCRCalculatorData {
  noiRow: number;
  debtServiceRows: number[];
  startingColumn: string;
  lenderId: SupportedLenderId;
  propertyColumnCount: number;
}

export class DSCRCalculatorRendered extends AutoRendered<DSCRCalculatorData> {
  private config: LenderSpreadSettings;
  constructor(
    public underlying: DSCRCalculatorData,
    startingRow: number,
  ) {
    super(underlying);
    this.rowStart = startingRow;
    this.config = spreadConfig.lenderSettings[underlying.lenderId];
  }
  asColumns(): RawCellContent[][] {
    const filler = Array(this.underlying.propertyColumnCount).fill(" ");
    return [
      [
        "Debt Service Coverage Ratio",
        `=B${this.underlying.noiRow} / (${this.underlying.debtServiceRows
          .map((row) => `B${row}`)
          .join(" + ")})`,
        ...filler,
      ],
      ["Target DSCR", this.config.defaultDSCR.toString(), ...filler],
      ["", `=IF(B${this.rowStart} >= B${this.rowStart + 1}, "Pass", "Fail")`, ...filler],
    ];
  }
  get highlightedRowLabels(): string[] {
    return ["Debt Service Coverage Ratio", "Target DSCR"];
  }
  get percentageRowLabels(): string[] {
    return [];
  }
  get metadataRowLabels(): string[] {
    return [];
  }
}
