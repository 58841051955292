import produce from "immer";
import { Reducer } from "redux";
import { AnnualReviewAction } from "src/redux/actions/actions.constants";

export interface AnnualReviewState {
  data: {
    step: number;
    payload: any;
  }[];
}

export const initialAnnualReviewState: AnnualReviewState = {
  data: [],
};

export const annualReviewReducer: Reducer<AnnualReviewState> = (
  state = initialAnnualReviewState,
  action,
) =>
  produce(state, (draft: AnnualReviewState) => {
    const payload = action.payload as any;
    switch (action.type) {
      case AnnualReviewAction.SAVE_STEP_DATA: {
        let idx = -1;
        draft.data.find((dt, index) => {
          if (dt.step === payload.step) {
            idx = index;
            return dt;
          } else {
            return null;
          }
        });
        if (idx > -1) {
          draft.data.splice(idx, 1);
        }
        draft.data.push({
          step: payload.step,
          payload: payload.payload,
        });
        return;
      }
      default: {
        break;
      }
    }
  });
