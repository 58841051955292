import { EntityType } from "src/types/prisma-enums";
import { z } from "zod";

export enum FinancialItemType {
  NetIncome = "Net Income",
  Depreciation = "Depreciation",
  Interest = "Interest",
  Amortization = "Amortization",
}

export enum FinancialItemComputed {
  BusinessCashFlowBeforeTax = "Business Cash Flow Before Tax",
}

export type GridRowReference = {
  rowIndex: number;
  rowKey: string;
};

export type CashFlowCandidates = {
  netIncome: GridRowReference[];
  depreciation: GridRowReference[];
  interest: GridRowReference[];
  amortization: GridRowReference[];
};

type FinancialPeriodItemSchema = {
  name: string;
  periodValues?: Record<string, string | null> | null;
  nestedItems?: FinancialPeriodItem[] | null;
  total?: {
    name: string;
    periodValues?: Record<string, string | null> | null;
  } | null;
};

export const ZFinancialPeriodItem: z.ZodSchema<FinancialPeriodItemSchema> = z.lazy(() =>
  z.object({
    name: z.string(),
    periodValues: z.record(z.string().nullable()).nullable().optional(),
    nestedItems: z.array(ZFinancialPeriodItem).nullable().optional(),
    total: z
      .object({
        name: z.string(),
        periodValues: z.record(z.string().nullable()).nullable().optional(),
      })
      .nullable()
      .optional(),
  }),
);

export type FinancialPeriodItem = z.infer<typeof ZFinancialPeriodItem>;

export const ZFinancialStatementItems = z.object({
  entityName: z.string().optional(),
  entityId: z.number().optional(),
  entityType: z.nativeEnum(EntityType).optional(),
  recentYear: z.number().optional(),
  availablePeriods: z.array(z.string()),
  items: z.array(ZFinancialPeriodItem),
});

export type FinancialStatementItems = z.infer<typeof ZFinancialStatementItems>;
