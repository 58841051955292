import { Reducer } from "redux";
import { LoanWorkflows } from "src/redux/actions/actions.constants";
import { WorkflowSettings } from "src/models/WorkflowSettings";

export interface LoanWorkflowState {
  workflows: WorkflowSettingsWithId[];
  isLoading: boolean;
}

export const initialLoanWorkflowState: LoanWorkflowState = {
  workflows: [],
  isLoading: false,
};

type WorkflowSettingsWithId = WorkflowSettings & { id: number };

export const loanWorkflowReducer: Reducer<LoanWorkflowState> = (
  state: LoanWorkflowState = initialLoanWorkflowState,
  action,
) => {
  switch (action.type) {
    case LoanWorkflows.SET_LOAN_WORKFLOWS:
      const setPayload = action.payload as { workflows: WorkflowSettingsWithId[] };
      return {
        isLoading: false,
        workflows: setPayload.workflows,
      };
    case LoanWorkflows.FETCH_LOAN_WORKFLOWS:
      return {
        isLoading: true,
        workflows: [],
      };
    case LoanWorkflows.CREATE_NEW_LOAN_WORKFLOW:
      return {
        ...state,
        workflows: [],
        isLoading: true,
      };
    case LoanWorkflows.UPDATE_LOAN_WORKFLOW:
      return {
        ...state,
        workflows: [],
        isLoading: true,
      };
    case LoanWorkflows.DELETE_LOAN_WORKFLOW:
      return {
        ...state,
        workflows: [],
        isLoading: true,
      };
    default:
      return state;
  }
};
