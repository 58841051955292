import { z } from "zod";
import { SQLQueryWhere, SQLQueryWhereConditions } from "src/utils/sql-query-builder";

export type FormikKeyFormFilterWithType = SQLQueryWhereConditions & { type: "AND" | "OR" };

export type FormikKeyFormFilter = (SQLQueryWhereConditions | FormikKeyFormFilterWithType)[];

export type FormikValues = {
  sortKey: string;
  sortAsc: boolean;
  columns: string[];
  whereQuery: SQLQueryWhere;
  filters: {
    [key: string]: FormikKeyFormFilter;
  };
};

// Used to create or update a reporting filter
export const ReportingFilterWriteSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  filters: z.string(),
});

export type ReportingFilterWriteDTO = {
  name: string;
  lenderId: number;
  description?: string;
  filters: FormikValues;
};

export type TableHeader = {
  label: string;
  key: string;
  type: FilterType;
  options?: { label: string; value: string }[];
};

export enum FilterType {
  STRING = "string",
  NUMBER = "number",
  DATE = "date",
  YEAR = "year",
  ENUM = "enum",
}
