import { createAction } from "@reduxjs/toolkit";
import { FileReference, OcrResponse } from "src/pages/api/documents/ocr/common";
import { OcrAction } from "./actions.constants";
import { ExtractOutput } from "src/backend/services/ocr/spread-data-aggregation/strategy/data-aggregation-strategy";

type DocumentRequestId = string;
export const StartOcrSaga = createAction<FileReference[]>(OcrAction.START_OCR);

export const PollOcrStatus = createAction(OcrAction.POLL_OCR_STATUS);

export const UpdateOcrData = createAction<OcrResponse>(OcrAction.UPDATE_OCR_DATA);

export const OcrCompletedAction = createAction<Record<DocumentRequestId, ExtractOutput>>(
  OcrAction.OCR_COMPLETED,
);

export const OcrErrorAction = createAction<Error>(OcrAction.OCR_ERROR);

export const OcrPollingCompletedAction = createAction(OcrAction.OCR_POLLING_COMPLETED);

export const SetAvailableUploadIds = createAction<number[]>(OcrAction.SET_AVAILABLE_UPLOAD_IDS);

export const SetSelectedUploadIds = createAction<number[]>(OcrAction.SET_SELECTED_UPLOAD_IDS);
export const SelectDocumentUploadId = createAction<number>(OcrAction.SELECT_DOCUMENT_UPLOAD_ID);
export const UnSelectDocumentUploadId = createAction<number>(OcrAction.UNSELECT_DOCUMENT_UPLOAD_ID);
export const ResetOcrData = createAction(OcrAction.RESET_OCR_DATA);
