import { baseApiService } from "src/services/api-services/BaseApiService";

export class ContactService {
  static getInstance(): ContactService {
    return new ContactService();
  }

  async fetchContacts(): Promise<any> {
    return (await baseApiService.get(`/api/contacts`)).data;
  }

  async fetchContact(customerUserId: string): Promise<any> {
    try {
      return await baseApiService.get(`/api/contacts/${customerUserId}`);
    } catch (error) {
      throw error;
    }
  }

  async fetchContactsByAnnualReviewId(annualReviewId: string): Promise<any> {
    return (await baseApiService.get(`/api/contacts/annual-review/${annualReviewId}`)).data;
  }

  async fetchContactsByLoanId(loanId: string): Promise<any> {
    return (await baseApiService.get(`/api/contacts/loan/${loanId}`)).data;
  }
}

export const contactService = ContactService.getInstance();
