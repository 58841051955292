import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";
import { AutoRenderedSheetBuilderWithConfidence } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";

export const Form1120sBalanceSheetRowHeadings = {
  Assets: "Assets",
  Cash: "Cash",
  AccountsAndNotesReceivable: "Accounts and notes receivable (current), net",
  AccountsAndNotesReceivableGrossAmount: "Accounts and notes receivable, gross amount",
  AllowanceForBadDebts: "Less: Allowance for bad debts",
  Inventory: "Inventory",
  MarketableSecurities: "Marketable securities",
  UsGovernmentObligations: "U.S. government obligations",
  TaxExemptSecurities: "Tax-exempt securities",
  OtherCurrentAssets: "Other current assets",
  TotalCurrentAssets: "Total current assets",
  NotesReceivable: "Notes receivable (non-current)",
  LoansToShareholders: "Loans to shareholders",
  OtherInvestments: "Other investments",
  LandNetOfAnyAmortization: "Land (net of any amortization)",
  BuildingsAndOtherDepreciableAssets: "Buildings and other depreciable assets, net of depreciation",
  DepletableAssets: "Depletable assets, net",
  IntangibleAssets: "Intangible assets, net",
  OtherAssets: "Other assets",
  TotalNonCurrentAssets: "Total non-current assets",
  TotalAssets: "Total assets",
  LiabilitiesAndShareholdersEquity: "Liabilities and shareholders' equity",
  AccountsPayable: "Accounts payable",
  ShortTermDebt: "Short term debt and current maturities of long term debt",
  OtherCurrentLiabilities: "Other current liabilities",
  TotalCurrentLiabilities: "Total current liabilities",
  LongTermDebt: "Long term debt",
  LoansFromShareholders: "Loans from shareholders",
  OtherNonCurrentLiabilities: "Other non-current liabilities",
  TotalNonCurrentLiabilities: "Total non-current liabilities",
  TotalLiabilities: "Total liabilities",
  CapitalStock: "Capital stock",
  AdditionalPaidInCapital: "Additional paid-in capital",
  RetainedEarnings: "Retained earnings",
  AdjustmentsToShareholdersEquity: "Adjustments to shareholders' equity",
  LessTreasuryStock: "Less: Treasury stock",
  TotalShareholdersEquity: "Total shareholders' equity",
  TotalLiabilitiesAndEquity: "Total liabilities and shareholders' equity",
};

export function build1120sBalanceSheet(
  data: RenderableWithConfidence<Form1120S>,
  startingColumn: string,
  startingRow: number,
) {
  return AutoRenderedSheetBuilderWithConfidence.from(
    data,
    Form1120sBalanceSheetRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRowFromData(
      "Assets",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.totalAssets?.endOfTaxYear ?? "",
    )
    .addRowFromData(
      "Cash",
      (data) => data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.cash?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "AccountsAndNotesReceivable",
      (data) =>
        (data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.tradeNotesAndAccountsReceivable
          ?.endOfTaxYear ?? 0) -
        Math.abs(
          data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.lessAllowanceForBadDebts
            ?.endOfTaxYear ?? 0,
        ),
    )
    .addRowFromData(
      "Inventory",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.inventories?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "MarketableSecurities",
      (data) =>
        (data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.usGovernmentObligations
          ?.endOfTaxYear ?? 0) +
        (data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.taxExemptSecurities
          ?.endOfTaxYear ?? 0),
    )
    .addRowFromData(
      "OtherCurrentAssets",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.otherCurrentAssets
          ?.endOfTaxYear ?? 0,
    )
    .addRowWithFormula(
      "TotalCurrentAssets",
      (finders) =>
        `=${finders.propertyReference("Cash")} + ${finders.propertyReference("AccountsAndNotesReceivable")} + ${finders.propertyReference("Inventory")} + ${finders.propertyReference("MarketableSecurities")} + ${finders.propertyReference("OtherCurrentAssets")}`,
    )
    .addRowFromData(
      "NotesReceivable",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.mortgageAndRealEstateLoans
          ?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "LoansToShareholders",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.loansToShareholdersOrPartners
          ?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "OtherInvestments",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.otherInvestments?.endOfTaxYear ??
        0,
    )
    .addRowFromData(
      "LandNetOfAnyAmortization",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.landNetOfAnyAmortization
          ?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "BuildingsAndOtherDepreciableAssets",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets
          ?.buildingsAndOtherDepreciableAssetsNetOfAccumulatedDepreciation?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "DepletableAssets",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets
          ?.depletableAssetsNetOfAccumulatedDepletion?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "IntangibleAssets",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets
          ?.intangibleAssetsNetOfAccumulatedAmortization?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "OtherAssets",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.otherAssets?.endOfTaxYear ?? 0,
    )
    .addRowWithFormula(
      "TotalNonCurrentAssets",
      (finders) =>
        `=${finders.propertyReference("NotesReceivable")} + ${finders.propertyReference("LoansToShareholders")} + ${finders.propertyReference("OtherInvestments")} + ${finders.propertyReference("LandNetOfAnyAmortization")} + ${finders.propertyReference("BuildingsAndOtherDepreciableAssets")} + ${finders.propertyReference("DepletableAssets")} + ${finders.propertyReference("IntangibleAssets")} + ${finders.propertyReference("OtherAssets")}`,
    )
    .addRowWithFormula(
      "TotalAssets",
      (finders) =>
        `=${finders.propertyReference("TotalCurrentAssets")} + ${finders.propertyReference("TotalNonCurrentAssets")}`,
    )
    .addRowFromData("LiabilitiesAndShareholdersEquity", () => "")
    .addRowFromData(
      "AccountsPayable",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity?.accountsPayable
          ?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "ShortTermDebt",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
          ?.mortgagesNotesAndBondsPayableLessThanOneYear?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "OtherCurrentLiabilities",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
          ?.otherCurrentLiabilities?.endOfTaxYear ?? 0,
    )
    .addRowWithFormula(
      "TotalCurrentLiabilities",
      (finders) =>
        `=${finders.propertyReference("AccountsPayable")} + ${finders.propertyReference("ShortTermDebt")} + ${finders.propertyReference("OtherCurrentLiabilities")}`,
    )
    .addRowFromData(
      "LongTermDebt",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
          ?.mortgagesNotesAndBondsPayableMoreThanOneYear?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "LoansFromShareholders",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
          ?.loansFromShareholdersOrPartners?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "OtherNonCurrentLiabilities",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity?.otherLiabilities
          ?.endOfTaxYear ?? 0,
    )
    .addRowWithFormula(
      "TotalNonCurrentLiabilities",
      (finders) =>
        `=${finders.propertyReference("LongTermDebt")} + ${finders.propertyReference("LoansFromShareholders")} + ${finders.propertyReference("OtherNonCurrentLiabilities")}`,
    )
    .addRowWithFormula(
      "TotalLiabilities",
      (finders) =>
        `=${finders.propertyReference("TotalCurrentLiabilities")} + ${finders.propertyReference("TotalNonCurrentLiabilities")}`,
    )
    .addRowFromData(
      "CapitalStock",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
          ?.capitalStockOrPartnersCapital?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "AdditionalPaidInCapital",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
          ?.additionalPaidInCapital?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "RetainedEarnings",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity?.retainedEarnings
          ?.endOfTaxYear ?? 0,
    )
    .addRowFromData(
      "AdjustmentsToShareholdersEquity",
      (data) =>
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
          ?.adjustmentsToShareholdersEquity?.endOfTaxYear ?? 0,
    )
    .addRowFromData("LessTreasuryStock", (data) =>
      Math.abs(
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity?.lessTreasuryStock
          ?.endOfTaxYear ?? 0,
      ),
    )
    .addRowWithFormula(
      "TotalShareholdersEquity",
      (finders) =>
        `=${finders.propertyReference("CapitalStock")} + ${finders.propertyReference("AdditionalPaidInCapital")} + ${finders.propertyReference("RetainedEarnings")} + ${finders.propertyReference("AdjustmentsToShareholdersEquity")} - ${finders.propertyReference("LessTreasuryStock")}`,
    )
    .addEmptyRow()
    .addRowWithFormula(
      "TotalLiabilitiesAndEquity",
      (finders) =>
        `=${finders.propertyReference("TotalLiabilities")} + ${finders.propertyReference("TotalShareholdersEquity")}`,
    );
}
