import _ from "lodash";

export type PaginationParams = {
  search: string;
  sortBy: string;
  sortOrder: "asc" | "desc";
  filterByStatus: string[];
  filterByAccountOwner: string[];
};

export const DEFAULT_LIMIT = 10;
export const DEFAULT_OFFSET = 0;
export const DEFAULT_SORT = "documentDueDate";
export const DEFAULT_SORT_DIRECTION = "desc";

const SEARCH = "search";
const SORT_BY = "sortBy";
const SORT_ORDER = "sortOrder";
const FILTER_BY_STATUS = "filterByStatus";
const FILTER_BY_ACCOUNT_OWNER = "filterByAccountOwner";

const DEFAULT_DELAY_IN_MS = 1000; // 1 second

// Currently, the sort params on the UI are as follows:
// 1. documentDueDate
// 2. closingDate
// 3. loanSize
// Since a review really only has a documentDueDate
// (the end date of the review), we can just revert to that.
// Why am I making this method if that's the case?
// Because in the future we may want to add more sort options,
// and we'll need to map those values to values that the API
// will understand.
// When making updates to this method please reference
// service/annual-review.ts :: getAnnualReviews method
// This will help you determine what values the query will accept
// in regard to sorting.

export const annualReviewSortByMapper = (sortBy: string) => {
  switch (sortBy) {
    case "documentDueDate":
      return "ar.end_date";
    case "closingDate":
      return "ar.end_date";
    case "loanSize":
      return "ar.end_date";
    default:
      return "ar.end_date";
  }
};

export const loanSortByMapper = (sortBy: string) => {
  switch (sortBy) {
    case "documentDueDate":
      return "l.document_due_date";
    case "closingDate":
      return "l.closing_date";
    case "loanSize":
      return "l.amount_cents";
    case "accountOwner":
      return "le.name";
    case "accountName":
      return "l.account_name";
    case "workFlowType":
      return "l.work_flow_type";
    case "loanStatus":
    case "status":
      return "l.status";
    default:
      return "l.closing_date";
  }
};

export class PaginationDebounce {
  private timeoutId: NodeJS.Timeout | null;
  private callback?: (fetchNextPage?: boolean) => void;
  private readonly delay: number;

  constructor(callback?: () => void) {
    this.timeoutId = null;
    if (callback) {
      this.callback = callback;
    }
    this.delay = DEFAULT_DELAY_IN_MS;
  }

  public setCallback(callback: () => void): void {
    this.callback = callback;
  }

  public setDebounceTimer(fetchNextPage?: boolean): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.timeoutId = null;
      if (this.callback) {
        this.callback(fetchNextPage);
      }
    }, this.delay);
  }

  public canMakeRequest(): boolean {
    return this.timeoutId === null;
  }
}

export class Pagination {
  private readonly limit: number;
  private offset: number;
  private lastFetchEmpty: boolean;

  constructor(limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET) {
    this.limit = limit;
    this.offset = offset;
    this.lastFetchEmpty = false;
  }

  public setLastFetchEmpty(value: boolean): void {
    this.lastFetchEmpty = value;
  }

  public wasLastFetchEmpty(): boolean {
    return this.lastFetchEmpty;
  }

  public getPage(): { limit: number; offset: number } {
    return {
      limit: this.limit,
      offset: this.offset,
    };
  }

  public setOffset(offset: number): void {
    this.offset = offset;
  }
}

export const PAGINATION_PARAMS_TO_CHECK = [
  SEARCH,
  SORT_BY,
  SORT_ORDER,
  FILTER_BY_STATUS,
  FILTER_BY_ACCOUNT_OWNER,
];

export const DEFAULT_PAGINATION_PARAMS: PaginationParams = {
  [SEARCH]: "",
  [SORT_BY]: annualReviewSortByMapper(DEFAULT_SORT),
  [SORT_ORDER]: DEFAULT_SORT_DIRECTION,
  [FILTER_BY_STATUS]: [],
  [FILTER_BY_ACCOUNT_OWNER]: [],
};

export const DEFAULT_PAGINATION_PARAMS_LOAN: PaginationParams = {
  [SEARCH]: "",
  [SORT_BY]: loanSortByMapper(DEFAULT_SORT),
  [SORT_ORDER]: DEFAULT_SORT_DIRECTION,
  [FILTER_BY_STATUS]: [],
  [FILTER_BY_ACCOUNT_OWNER]: [],
};

const parseParamInputs = (params: PaginationParams) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (PAGINATION_PARAMS_TO_CHECK.includes(key)) {
      if (Array.isArray(value)) {
        return { ...acc, [key]: [...value].sort() };
      }
      return { ...acc, [key]: value };
    } else {
      return acc;
    }
  }, {});
};
export const isEqual = (oldParams: PaginationParams, newParams: PaginationParams): boolean => {
  const oldParsedParams = parseParamInputs(oldParams);
  const newParsedParams = parseParamInputs(newParams);

  return _.isEqual(oldParsedParams, newParsedParams);
};
