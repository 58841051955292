import { LenderAction } from "./actions.constants";
import { LenderSettings } from "@prisma/client";

export const fetchLenderEmployeeAction = (payload: {
  query: string;
  lenderId: number | undefined;
}) => {
  return {
    type: LenderAction.FETCH_LENDER_EMPLOYEE_BY_QUERY,
    payload: payload,
  };
};

export const fetchLenderEmployeeActionComplete = (data: any) => {
  return {
    type: LenderAction.FETCH_LENDER_EMPLOYEE_BY_QUERY_COMPLETED,
    payload: data,
  };
};

export const fetchLenderEmployeeActionFailed = () => {
  return {
    type: LenderAction.FETCH_LENDER_EMPLOYEE_BY_QUERY_FAILED,
    payload: {},
  };
};

export const updateLoanLenderEmployeeAction = (payload: {
  loanId: number;
  lenders: {
    lenderId: number;
    isAccountOwner: boolean;
  }[];
}) => {
  return {
    type: LenderAction.UPDATE_LOAN_LENDER_EMPLOYEE,
    payload: payload,
  };
};

export const updateLoanLenderEmployeeActionComplete = (data: any) => {
  return {
    type: LenderAction.UPDATE_LOAN_LENDER_EMPLOYEE_COMPLETED,
    payload: data,
  };
};

export const updateLoanLenderEmployeeActionFailed = () => {
  return {
    type: LenderAction.UPDATE_LOAN_LENDER_EMPLOYEE_FAILED,
    payload: {},
  };
};

export const getLenderDataFromCodeAction = (data: any) => {
  return {
    type: LenderAction.FETCH_LENDER_BY_USER_CODE,
    payload: data,
  };
};

export const getLenderDataFromCodeActionComplete = (data: any) => {
  return {
    type: LenderAction.FETCH_LENDER_BY_USER_CODE_COMPLETE,
    payload: data,
  };
};

export const getLenderDataFromCodeActionFailed = () => {
  return {
    type: LenderAction.FETCH_LENDER_BY_USER_CODE_FAILED,
    payload: {},
  };
};

export const updateReviewLenderEmployeeAction = (payload: {
  reviewId: number;
  lenders: {
    lenderId: number;
    isAccountOwner: boolean;
  }[];
}) => {
  return {
    type: LenderAction.UPDATE_REVIEW_LENDER_EMPLOYEE,
    payload: payload,
  };
};

export const updateReviewLenderEmployeeActionComplete = (data: any) => {
  return {
    type: LenderAction.UPDATE_REVIEW_LENDER_EMPLOYEE_COMPLETED,
    payload: data,
  };
};

export const updateReviewLenderEmployeeActionFailed = () => {
  return {
    type: LenderAction.UPDATE_REVIEW_LENDER_EMPLOYEE_FAILED,
    payload: {},
  };
};

export const fetchLenderSettings = () => {
  return {
    type: LenderAction.FETCH_LENDER_SETTINGS,
  };
};

export const fetchLenderSettingsComplete = (data: LenderSettings[]) => {
  return {
    type: LenderAction.FETCH_LENDER_SETTINGS_COMPLETED,
    payload: data,
  };
};

export const updateLenderSettings = (data: LenderSettings) => {
  return {
    type: LenderAction.UPDATE_LENDER_SETTINGS,
    payload: data,
  };
};

export const updateLenderSettingsComplete = (data: LenderSettings) => {
  return {
    type: LenderAction.UPDATE_LENDER_SETTINGS_COMPLETED,
    payload: data,
  };
};
