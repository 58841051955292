import { SpreadableProperty } from "src/redux/selectors/ocr.selector";
import { faker } from "@faker-js/faker";
import { capitalizeFirstLetterOfEachWord } from "src/utils/helpers";
import { CanonicalRentRoll } from "src/models/CanonicalRentRoll";

export function spreadablePropertyLabel(property: SpreadableProperty): string {
  const ideal =
    (property as CanonicalRentRoll)?.streetAddressWithUnitOrSuite ??
    `${(property as CanonicalRentRoll).streetAddress ?? ""} ${(property as CanonicalRentRoll).unitOrSuite ?? ""}`.trim();
  if (ideal.length > 0 && ideal !== "undefined") {
    return ideal;
  } else {
    const objString = JSON.stringify(property);
    const hash = simpleHash(objString).toString(36).slice(0, 3);
    const identifier = generateIdentifierFromHash(hash);

    return `Property "${identifier}"`;
  }
}

function simpleHash(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return Math.abs(hash);
}
export function hashToSeed(hash: string): number {
  const seed = parseInt(hash.slice(0, 8), 16);
  return seed;
}
function generateIdentifierFromHash(hash: string): string {
  const seed = hashToSeed(hash);
  faker.seed(seed);

  const adjective = faker.word.adjective();
  const noun = faker.word.noun();
  const color = faker.color.human();

  return capitalizeFirstLetterOfEachWord(`${color} ${adjective} ${noun}`);
}
