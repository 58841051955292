import { z } from "zod";
import { createAction } from "@reduxjs/toolkit";
import { DocumentAction } from "src/redux/actions/actions.constants";
import { DocumentRequestUpdateSchema } from "src/pages/api/document-request/[documentRequestId]/documentRequest.contracts";

export type FileStructureAndDocumentUploadIds = {
  fileStructure: string[];
  documentUploadId: number;
}[];

export type DocumentRequestUpdatePayload = z.infer<typeof DocumentRequestUpdateSchema> & {
  documentRequestId: number;
} & ({ loanId: number } | { reviewId: number });

export interface DownloadAllDocumentsPayload {
  fileStructureAndDocumentUploadIds: FileStructureAndDocumentUploadIds;
}

export const downloadAllDocuments = createAction<DownloadAllDocumentsPayload>(
  DocumentAction.DOWNLOAD_ALL_DOCUMENTS,
);

export const downloadAllDocumentsCompleted = createAction(
  DocumentAction.DOWNLOAD_ALL_DOCUMENTS_COMPLETED,
);

export const downloadAllDocumentsFailed = createAction<Error>(
  DocumentAction.DOWNLOAD_ALL_DOCUMENTS_FAILED,
);

export const updateDocumentRequest = createAction<DocumentRequestUpdatePayload>(
  DocumentAction.UPDATE_DOCUMENT_REQUEST,
);

export const updateDocumentRequestCompleted = createAction(
  DocumentAction.UPDATE_DOCUMENT_REQUEST_COMPLETED,
);

export const updateDocumentRequestFailed = createAction<Error>(
  DocumentAction.UPDATE_DOCUMENT_REQUEST_FAILED,
);
