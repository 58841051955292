import { DocumentAction } from "src/redux/actions/actions.constants";
import { Reducer } from "redux";

export interface DocumentsState {
  loading: boolean;
  error: any;
}

export const initialDocumentsState: DocumentsState = {
  loading: false,
  error: null,
};

export const documentsReducer: Reducer<DocumentsState> = (
  state = initialDocumentsState,
  action: any,
) => {
  switch (action.type) {
    case DocumentAction.DOWNLOAD_ALL_DOCUMENTS:
    case DocumentAction.UPDATE_DOCUMENT_REQUEST:
      return { ...state, loading: true, error: null };
    case DocumentAction.DOWNLOAD_ALL_DOCUMENTS_COMPLETED:
    case DocumentAction.UPDATE_DOCUMENT_REQUEST_COMPLETED:
      return { ...state, loading: false };
    case DocumentAction.DOWNLOAD_ALL_DOCUMENTS_FAILED:
    case DocumentAction.UPDATE_DOCUMENT_REQUEST_FAILED:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
