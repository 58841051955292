import { call, put, takeLatest } from "redux-saga/effects";
import { DocumentAction } from "src/redux/actions/actions.constants";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  downloadAllDocuments,
  downloadAllDocumentsCompleted,
  downloadAllDocumentsFailed,
  updateDocumentRequest,
  updateDocumentRequestCompleted,
  updateDocumentRequestFailed,
} from "src/redux/actions/documents.action";
import { documentReminderService } from "src/services/document-reminder/DocumentReminderService";
import {
  annualReviewDetailsGetAction,
  loanDetailsGetAction,
} from "src/redux/actions/pipelineLoans.action";
import { toastService } from "src/services/ToastService";

function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

function* handleDownloadDocuments(action: ReturnType<typeof downloadAllDocuments>) {
  try {
    const { fileStructureAndDocumentUploadIds } = action.payload;
    const zip = new JSZip();
    let topLevelDirectoryName: string = "Documents";

    const FILE_PATH_COUNT: Record<string, number> = {};

    // Process files sequentially to avoid memory issues
    for (const fileStructure of fileStructureAndDocumentUploadIds) {
      try {
        // Get the file URL and make the request
        const fileUrl = `/api/documents/${fileStructure.documentUploadId}/document-preview`;
        const response: Response = yield call(fetch, fileUrl);

        // Check if the response is ok
        if (!response.ok) {
          throw new Error(`Failed to fetch ${fileUrl}: ${response.statusText}`);
        }

        // Get the blob from response
        const blob: Blob = yield call([response, "blob"]);

        // Ensure we have actual content
        if (blob.size === 0) {
          throw new Error(`Empty file received for ${fileUrl}`);
        }

        // Convert blob to ArrayBuffer
        const fileData: ArrayBuffer = yield call(blobToArrayBuffer, blob);

        // Update top level directory name if available
        if (fileStructure.fileStructure.length > 0) {
          topLevelDirectoryName = fileStructure.fileStructure[0] ?? topLevelDirectoryName;
        }

        // Create the full file path
        let filePath = fileStructure.fileStructure.slice(1).join("/");

        if (filePath) {
          // Check if file path already exists
          if (filePath in FILE_PATH_COUNT) {
            // Increment the count
            FILE_PATH_COUNT[filePath]++;
            // Pull out the file name and extension (or whatever the terminal part of the path is)
            const fileName = filePath.split("/").pop() ?? "";
            // Remove extension from file name (if it exists)
            const fileExtension = fileName?.split(".").pop();
            // Get the file name without the extension
            const fileNameWithoutExtension = fileName?.replace(`.${fileExtension}`, "");
            // Update the file path to include the count to make it unique
            filePath = filePath.replace(
              fileNameWithoutExtension,
              `${fileNameWithoutExtension}(${FILE_PATH_COUNT[filePath]})`,
            );
          } else {
            FILE_PATH_COUNT[filePath] = 0;
          }

          // Add file to zip with proper path
          zip.file(filePath, fileData, {
            binary: true,
            createFolders: true,
          });
        }
      } catch (fileError) {
        console.error(`Error processing file: ${fileError}`);
        // Continue with other files even if one fails
        continue;
      }
    }

    // Check if any files were added
    const zipFiles = Object.keys(zip.files);
    if (zipFiles.length === 0) {
      throw new Error("No valid files were added to the ZIP");
    }

    // Generate and save the zip file
    const zipFile: Blob = yield zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 6,
      },
    });

    // Verify zip file size
    if (zipFile.size === 0) {
      throw new Error("Generated ZIP file is empty");
    }

    // Save the file
    saveAs(zipFile, `${topLevelDirectoryName}.zip`);

    yield put(downloadAllDocumentsCompleted());
  } catch (error) {
    console.error("Download failed:", error);
    yield put(downloadAllDocumentsFailed(new Error(`Download failed: ${error}`)));
  }
}

function* handleDocumentRequestUpdate(action: ReturnType<typeof updateDocumentRequest>) {
  try {
    const { payload } = action;
    yield call(documentReminderService.updateDocumentRequest, payload);

    if ("reviewId" in payload && payload.reviewId) {
      yield put(annualReviewDetailsGetAction(payload.reviewId.toString()));
    } else if ("loanId" in payload && payload.loanId) {
      yield put(loanDetailsGetAction(payload.loanId.toString()));
    }

    yield put(updateDocumentRequestCompleted());
    toastService.showSuccess("Document request updated successfully");
  } catch (error) {
    yield put(updateDocumentRequestFailed(new Error(`${error}`)));
    toastService.showError("Failed to update document request");
  }
}

function* documents() {
  yield takeLatest(DocumentAction.DOWNLOAD_ALL_DOCUMENTS, handleDownloadDocuments);
  yield takeLatest(DocumentAction.UPDATE_DOCUMENT_REQUEST, handleDocumentRequestUpdate);
}

export default documents;
