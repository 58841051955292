import { CustomCellRendererProps } from "ag-grid-react";
import cx from "classnames";
import React from "react";

export const HFHeaderComponent = (props: CustomCellRendererProps) => {
  const { column } = props;

  const colId = column?.getColId() as string;

  // Add a ref to the header element to be able to measure it

  return (
    <div className="ag-header-cell-label">
      {/* Column title - A,B,C,etc. */}
      <span
        id={`header-${colId}`}
        className={cx(
          // ml-5 accounts for indentation of labels in first column due to row numbers
          colId === "A" ? "ml-5" : "text-right",
          // common text styles
          "text-xs font-light text-gray-600 w-full",
        )}
      >
        {colId}
      </span>
    </div>
  );
};
