export enum SpreadsDocumentType {
  UNKNOWN = "UNKNOWN",
  // note: the below document type is a combination of the document
  // types like, income statement, balance sheet etc.
  CORPORATE_TAX_RETURN = "CORPORATE_TAX_RETURN",
  FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT",
  PERSONAL_TAX_RETURN = "PERSONAL_TAX_RETURN",
  // the rent roll is currently assumed to be a single page document
  RENT_ROLL = "RENT_ROLL",
  // based off the first trust example in ./e2e/data/multi_rentroll
  MULTI_PROPERTY_RENTROLL = "MULTI_PROPERTY_RENTROLL",
  SCHEDULE_1 = "SCHEDULE_1",
  SCHEDULE_2 = "SCHEDULE_2",
  SCHEDULE_3 = "SCHEDULE_3",
  SCHEDULE_A = "SCHEDULE_A",
  SCHEDULE_B = "SCHEDULE_B",
  SCHEDULE_C = "SCHEDULE_C",
  SCHEDULE_D = "SCHEDULE_D",
  SCHEDULE_E = "SCHEDULE_E",
  SCHEDULE_K1 = "SCHEDULE_K1",
  STATEMENTS = "STATEMENTS",
  BALANCE_SHEET = "BALANCE_SHEET",
  INCOME_STATEMENT = "INCOME_STATEMENT",
  SCHEDULE_L = "SCHEDULE_L",
  SUPPLEMENT_FINANCIAL_STATEMENTS = "SUPPLEMENT_FINANCIAL_STATEMENTS",
  CORPORATE_TAX_STATEMENT = "CORPORATE_TAX_STATEMENT",
  FORM_1120S = "FORM_1120S",
  FORM_1040 = "FORM_1040",
  GENERIC_PDF_TABLE = "GENERIC_PDF_TABLE",

  WORKSHEET_FORM_8825 = "WORKSHEET_FORM_8825",
  FORM_8825 = "FORM_8825",
  FORM_4562 = "FORM_4562",

  // Schedule K-1 for Form 1065 version 651121 (upper right hand corner)
  // https://www.irs.gov/e-file-providers/k-1-bar-code-certification-process
  SCHEDULE_K1_1065_651121 = "SCHEDULE_K1_1065_651121",

  // Schedule K-1 for Form 1065 version 651123 (upper right hand corner)
  // https://www.irs.gov/e-file-providers/k-1-bar-code-certification-process
  SCHEDULE_K1_1065_651123 = "SCHEDULE_K1_1065_651123",
  SCHEDULE_K1_1065_651119 = "SCHEDULE_K1_1065_651119",

  // Schedule K-1 for Form 1120-S version 671121
  // https://www.irs.gov/e-file-providers/k-1-bar-code-certification-process
  SCHEDULE_K1_1120S_671121 = "SCHEDULE_K1_1120S_671121",
  SCHEDULE_K1_1120S_671120 = "SCHEDULE_K1_1120S_671120",
  FORM_1065 = "FORM_1065",
}
