import { call, put, takeEvery } from "redux-saga/effects";
import { ContactAction } from "../actions/actions.constants";
import { contactService } from "src/services/contact-service/ContactService";
import {
  fetchContactActionCompleted,
  fetchContactActionError,
  fetchContactsActionCompleted,
  fetchContactsActionError,
  fetchContactsByAnnualReviewIdActionCompleted,
  fetchContactsByAnnualReviewIdActionError,
  fetchContactsByLoanIdActionCompleted,
  fetchContactsByLoanIdActionError,
} from "src/redux/actions/contact.action";
import { toastService } from "src/services/ToastService";

function* fetchContacts(): any {
  try {
    const response = yield call(contactService.fetchContacts);
    yield put(fetchContactsActionCompleted(response));
  } catch (e: any) {
    yield put(fetchContactsActionError(e));
    console.log(e);
  }
}

function* fetchContact(action: { type: string; id: string }): any {
  try {
    const response = yield call(contactService.fetchContact, action.id);
    yield put(fetchContactActionCompleted(response));
  } catch (e: any) {
    yield put(fetchContactActionError(e.response.message));
    console.log(e.response.message);
    toastService.showError(e.response.message);
  }
}

function* fetchContactByAnnualReviewId(action: { type: string; id: string }): any {
  try {
    const response = yield call(contactService.fetchContactsByAnnualReviewId, action.id);
    yield put(fetchContactsByAnnualReviewIdActionCompleted(response));
  } catch (e: any) {
    yield put(fetchContactsByAnnualReviewIdActionError(e.response.message));
    console.log(e.response.message);
    toastService.showError(e.response.message);
  }
}

function* fetchContactByLoanId(action: { type: string; id: string }): any {
  try {
    const response = yield call(contactService.fetchContactsByLoanId, action.id);
    yield put(fetchContactsByLoanIdActionCompleted(response));
  } catch (e: any) {
    yield put(fetchContactsByLoanIdActionError(e.response.message));
    console.log(e.response.message);
    toastService.showError(e.response.message);
  }
}

function* contact() {
  yield takeEvery(ContactAction.FETCH_CONTACTS, fetchContacts);
  yield takeEvery(ContactAction.FETCH_CONTACT, fetchContact);
  yield takeEvery(ContactAction.FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID, fetchContactByAnnualReviewId);
  yield takeEvery(ContactAction.FETCH_CONTACTS_BY_LOAN_ID, fetchContactByLoanId);
}

export default contact;
