export enum ReminderTypeEnum {
  ANNUAL_REST_PERIOD = "Annual Rest Period (R)",
  ANNUAL_REVIEW = "Annual Review (R)",
  CONTINUATION_FEE = "Continuation Fee (R)",
  DEBT_SERVICE_COVERAGE_RATIO = "Debt Service Coverage Ratio (R)",
  POST_FUNDING_ITEMS = "Post Funding Items (R)",
  REAL_ESTATE_TAX_NOTICE = "Real Estate Tax Notice (R)",
  REAL_ESTATE_PROPERTY_TAX_NOTICE = "Real Estate Property Tax Notice (R)",
  CERTIFICATE_OF_GOOD_STANDING = "Certificate of Good Standing (R)",
  UCC1_FILING_RENEWAL = "UCC1 Filing Renewal (R)",
  UCC1_FIXTURE_FILING_RENEWAL = "UCC1 Fixture Filing Renewal (R)",
  VEHICLE_TITLE = "Vehicle Title (R)",
  BORROWING_BASE_CERTIFICATE = "Borrowing Base Certificate (R)",
  UCC3_FILING_AMENDMENT = "UCC3 Filing Amendment (R)",
  RATE_ADJUSTMENT = "Rate Adjustment (R)",
  UCC_FIXTURE_FILING = "UCC Fixture Filing (R)",
  UCC3_FIXTURE_FILING_AMENDMENT = "UCC3 Fixture Filing Amendment (R)",
  FINANCIALS = "Financials (R)",
  FLOOD_INSURANCE = "Flood Insurance (R)",
  INSURANCE = "Insurance (R)",
}
