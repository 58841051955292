import _ from "lodash";
import { FCN_LENDER_ID } from "src/utils/constants";
import { DisplayLabel } from "src/utils/label";
import { documentTypes } from "./documentTypes";

export type DocumentItem = {
  id: number;
  name: string;
  year: number;
};

export type DocumentTypes = {
  [key: number]: DocumentItem;
};

export const EntityToDocumentKeys = {
  BORROWER: "borrower",
  GUARANTOR: "guarantor",
  COLLATERAL: "collateral",
  INDIVIDUAL: "individual",
  COMPANY: "company",
} as const;

export type EntityToDocumentMapping = {
  [EntityToDocumentKeys.BORROWER]: {
    [EntityToDocumentKeys.INDIVIDUAL]: DocumentItem[];
    [EntityToDocumentKeys.COMPANY]: DocumentItem[];
  };
  [EntityToDocumentKeys.GUARANTOR]: {
    [EntityToDocumentKeys.INDIVIDUAL]: DocumentItem[];
    [EntityToDocumentKeys.COMPANY]: DocumentItem[];
  };
  [EntityToDocumentKeys.COLLATERAL]: DocumentItem[];
};

export const isDocumentType = (
  documentTypeId: number,
): documentTypeId is keyof typeof documentTypes => {
  return documentTypeId in documentTypes;
};

// TODO: This is currently not up-to-date with the document_type table
export const documentRequestOrder = [
  "Personal tax return",
  "Corporate tax return",
  "Schedule K-1's / Corporate tax return",
  "Personal financial statement",
  "Personal bank statement",
  "YTD financial statements",
  "Financial statements for the same period YTD",
  "Year end financial statements",
  "Projected income statement",
  "Business debt schedule",
  "Equipment and/or vehicle loans or leases",
  "Purchase invoice(s)",
  "AR aging schedule",
  "AP aging schedule",
  "Work in Process inventory report",
  "Certificate of good standing",
  "Insurance",
  "Borrowing base certificate (BBC)",
];

// refer INT-1044
export const documentsWithMonthInNameWhenAnnualFreq = [
  "Personal financial statement",
  "YTD financial statements",
  "YTD Income Statement",
  "YTD Balance Sheet",
  "Fiscal year end Income Statement",
  "Fiscal year end Balance Sheet",
  "Business debt schedule",
  "Rent Roll",
  "Leases",
  "Inventory List",
  "AP aging schedule",
  "AR aging schedule",
  "Work in Process inventory report",
  "Personal bank statement",
  "Borrowing base certificate (BBC)",
];

// black listing documents as per INT-1311
export const documentsThatCannotBeMultipleOnSameEntity: string[] = [];

const documentTypeToDisplayName = {
  "Year end financial statements": "Financial statements",
  "Financial statements for the same period YTD": "Financial statements YTD - Previous year (YoY)",
};

// If a "display name" exists for a document type, return it.
// (Display names are the values in the object documentTypeToDisplayName.)
// Otherwise, return the document type.
export const formatDocumentTypeName = (documentRequestTypeName: string): DisplayLabel => {
  return _.get(documentTypeToDisplayName, documentRequestTypeName, documentRequestTypeName);
};

// These docs should not be shown in the list of docs to request
// when editing a reporting sequence or making a new annual review/loan,
// given a lender ID.
export const docsToHideByLenderId = {
  [FCN_LENDER_ID]: [1, 2, 3, 8, 9, 10] as const,
} as const;

// For an EntityPath (such as borrower.individual),
// return the list of document types
// that are requested by default for that entity.
// TODO: this should also vary by lender
//  (see INT-1117 and INT-951)
const entityToDocumentMapping: EntityToDocumentMapping = {
  [EntityToDocumentKeys.BORROWER]: {
    [EntityToDocumentKeys.INDIVIDUAL]: ([4, 5, 6, 12, 13, 23] as const).map(
      (typeId) => documentTypes[typeId],
    ),
    // Removing "Financial Statement YTD - Previous Year (YoY)" document type from being shown
    // The actual doc_type in oyr db is (9,"Financial statements for the same period YTD")
    // as per requirement in https://linear.app/interfold/issue/INT-951/document-types-in-database
    [EntityToDocumentKeys.COMPANY]: ([11, 12, 13, 14, 15, 19, 19, 19, 24, 25] as const).map(
      (typeId) => documentTypes[typeId],
    ),
  },
  [EntityToDocumentKeys.GUARANTOR]: {
    [EntityToDocumentKeys.INDIVIDUAL]: ([1, 1, 1, 4, 5, 6] as const).map(
      (typeId) => documentTypes[typeId],
    ),
    [EntityToDocumentKeys.COMPANY]: ([11, 19, 19, 19, 24, 25] as const).map(
      (typeId) => documentTypes[typeId],
    ),
  },
  [EntityToDocumentKeys.COLLATERAL]: ([12, 13] as const).map(
    (documentId) => documentTypes[documentId],
  ),
};

// Use as the template for the default documents to request for any annual review.
export const defaultEntityReviewDocuments: EntityToDocumentMapping = {
  [EntityToDocumentKeys.BORROWER]: {
    [EntityToDocumentKeys.INDIVIDUAL]: ([5, 23] as const).map((typeId) => documentTypes[typeId]),
    [EntityToDocumentKeys.COMPANY]: ([11, 17, 24, 25] as const).map(
      (typeId) => documentTypes[typeId],
    ),
  },
  guarantor: {
    [EntityToDocumentKeys.INDIVIDUAL]: ([5, 23] as const).map((typeId) => documentTypes[typeId]),
    [EntityToDocumentKeys.COMPANY]: ([11, 17] as const).map((typeId) => documentTypes[typeId]),
  },
  [EntityToDocumentKeys.COLLATERAL]: ([] as const).map((documentId) => documentTypes[documentId]),
};

export default entityToDocumentMapping;
