export const DOCUMENT = "document";
export const REMINDER = "reminder";

export type DocumentReminderOptionType = typeof DOCUMENT | typeof REMINDER;

export type DocumentReminderOption = {
  id: number;
  name: string;
  value: string;
  type: DocumentReminderOptionType;
  label: string;
  // TODO: This duplicate logic can go away once we make sure we remove year from type tables and only rely on names
  duplicate_values: string[];
  displayName?: string;
};

export type DocumentReminderResponse = {
  data: DocumentReminderOption[];
};
