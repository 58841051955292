import { createSelector } from "@reduxjs/toolkit";
import { reportingStateSelector } from "src/redux/selectors/app.selectors";

export const reportingHeadersSelector = createSelector(
  [reportingStateSelector],
  (reporting) => reporting.headers,
);

export const reportingDataSelector = createSelector(
  [reportingStateSelector],
  (reporting) => reporting.data,
);

export const reportingTableDataLoading = createSelector(
  [reportingStateSelector],
  (reporting) => reporting.isDataLoading,
);

export const reportingAllDataLoaded = createSelector(
  [reportingStateSelector],
  (reporting) => reporting.allDataLoaded,
);

export const reportingFilterLoading = createSelector(
  [reportingStateSelector],
  (reporting) => reporting.isFilterLoading,
);

export const reportingFiltersSelector = createSelector(
  [reportingStateSelector],
  (reporting) => reporting.filters,
);
