import { call, put, takeEvery } from "redux-saga/effects";
import { AnnualReviewAction } from "src/redux/actions/actions.constants";
import { annualReviewService } from "src/services/annual-review-service/AnnualReviewService";
import { LoanDetails } from "src/models/Loan";
import { toastService } from "src/services/ToastService";
import { createAnnualReviewDataCompleted } from "src/redux/actions/annual-review.action";

function* createAnnualReview(action: {
  type: string;
  payload: { step: number; payload: any }[];
}): any {
  try {
    const response: LoanDetails | undefined = yield call(
      annualReviewService.createAnnualReview,
      action.payload,
    );
    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse = {
      ...response,
      documentRequests: response?.documentRequests?.map((e, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(createAnnualReviewDataCompleted(updatedResponse));
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch detail for this review. Something went wrong!");
  }
}

function* annualReviews() {
  yield takeEvery(AnnualReviewAction.CREATE_ANNUAL_REVIEW, createAnnualReview);
}

export default annualReviews;
