import { call, put, takeEvery } from "redux-saga/effects";
import { FeatureFlags } from "../actions/actions.constants";
import { fetchFeatureFlags, updateFeatureFlag } from "src/services/feature-flags/FeatureFlags";
import { setFeatureFlags, updatedFeatureFlag } from "src/redux/actions/feature-flag.action";

function* fetchLenderFeatureFlags(): any {
  try {
    const response = yield call(fetchFeatureFlags);
    yield put(setFeatureFlags({ featureFlags: response }));
  } catch (e: any) {
    console.log(e);
  }
}

function* updateLenderFeatureFlag(action: any): any {
  try {
    yield call(updateFeatureFlag, action.payload);

    const response = yield call(fetchFeatureFlags);
    yield put(setFeatureFlags({ featureFlags: response }));
    yield put(updatedFeatureFlag());
  } catch (e: any) {
    console.log(e);
  }
}

function* featureFlags() {
  yield takeEvery(FeatureFlags.FETCH_FEATURE_FLAGS, fetchLenderFeatureFlags);
  yield takeEvery(FeatureFlags.UPDATE_FEATURE_FLAG, updateLenderFeatureFlag);
}

export default featureFlags;
