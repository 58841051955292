import { createSelector } from "@reduxjs/toolkit";
import { lenderStateSelector } from "src/redux/selectors/app.selectors";
import { LenderSettingsEnum } from "src/models/LenderSettings";

export const lenderEmployeeSelector = createSelector(
  [lenderStateSelector],
  (lenderState) => lenderState,
);

export const lenderIdSelector = createSelector(
  [lenderStateSelector],
  (lenderState) => lenderState.data.find((employee) => employee.employerId)?.employerId || null,
);
export const lenderSettingsSelector = createSelector(
  [lenderStateSelector],
  (lenderState) => lenderState.settings,
);

export const lenderSettingsSelectorByName = (name: LenderSettingsEnum) =>
  createSelector([lenderStateSelector], (lenderState) =>
    lenderState.settings.find((setting) => setting.name === name),
  );
