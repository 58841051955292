import { ContactAction } from "src/redux/actions/actions.constants";

export const fetchContactsAction = () => {
  return { type: ContactAction.FETCH_CONTACTS };
};

export const fetchContactsActionCompleted = (data: any) => {
  return { type: ContactAction.FETCH_CONTACTS_COMPLETED, payload: data };
};

export const fetchContactsActionError = (data: any) => {
  return { type: ContactAction.FETCH_CONTACTS_ERROR, payload: data };
};

export const fetchContactsByAnnualReviewIdAction = (annualReviewId: string) => {
  return { type: ContactAction.FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID, id: annualReviewId };
};

export const fetchContactsByAnnualReviewIdActionCompleted = (data: any) => {
  return { type: ContactAction.FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID_COMPLETED, payload: data };
};

export const fetchContactsByAnnualReviewIdActionError = (data: any) => {
  return { type: ContactAction.FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID_ERROR, payload: data };
};

export const fetchContactsByLoanIdAction = (loanId: string) => {
  return { type: ContactAction.FETCH_CONTACTS_BY_LOAN_ID, id: loanId };
};

export const fetchContactsByLoanIdActionCompleted = (data: any) => {
  return { type: ContactAction.FETCH_CONTACTS_BY_LOAN_ID_COMPLETED, payload: data };
};

export const fetchContactsByLoanIdActionError = (data: any) => {
  return { type: ContactAction.FETCH_CONTACTS_BY_LOAN_ID_ERROR, payload: data };
};

export const fetchContactAction = (contactId: string) => {
  return { type: ContactAction.FETCH_CONTACT, id: contactId };
};

export const fetchContactActionCompleted = (data: any) => {
  return { type: ContactAction.FETCH_CONTACT_COMPLETED, payload: data };
};

export const fetchContactActionError = (data: any) => {
  return { type: ContactAction.FETCH_CONTACT_ERROR, payload: data };
};
