import { DocumentAndRemindersActions } from "src/redux/actions/actions.constants";
import { createAction } from "@reduxjs/toolkit";
import { DocumentAndRemindersFetchResponse } from "src/services/document-reminder/DocumentReminderService";

export const fetchDocumentAndReminders = createAction(
  DocumentAndRemindersActions.FETCH_DOCUMENT_AND_REMINDERS,
);

export const fetchDocumentAndRemindersCompleted = createAction<DocumentAndRemindersFetchResponse>(
  DocumentAndRemindersActions.FETCH_DOCUMENT_AND_REMINDERS_COMPLETED,
);

export const fetchDocumentAndRemindersFailed = createAction<Error>(
  DocumentAndRemindersActions.FETCH_DOCUMENT_AND_REMINDERS_FAILED,
);
