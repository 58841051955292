import { call, put, select, takeEvery } from "redux-saga/effects";
import { ManagementAction } from "src/redux/actions/actions.constants";
import { isEqual, Pagination, PaginationDebounce, PaginationParams } from "src/utils/pagination";
import { toastService } from "src/services/ToastService";
import Papa from "papaparse";
import { openSaveFileDialog } from "src/utils/helpers";
import { loanService } from "src/services/loan-services/LoanService";
import { ManagementDetails } from "src/components/ManagementDetailsTable/ManagementDetailsTable";
import {
  managementDataSelector,
  managementSearchParamsSelector,
} from "src/redux/selectors/management-selector";
import {
  managementDataForCsvActionCompleted,
  setManagementDataForTable,
  setManagementSearchParams,
} from "src/redux/actions/management.action";

const paginationDebounce = new PaginationDebounce();
const CSV_PAGINATION_LIMIT = 300;
const PAGINATION_LIMIT = 20;
const pagination = new Pagination(PAGINATION_LIMIT);

function* getManagementDataForTable(action: {
  type: string;
  payload: { fetchNextPage: boolean };
}): any {
  const { fetchNextPage } = action.payload;
  const { limit, offset: previousOffset } = pagination.getPage();
  const offset = !fetchNextPage ? 0 : previousOffset;
  if (!paginationDebounce.canMakeRequest() || (offset > 0 && pagination.wasLastFetchEmpty())) {
    return;
  }
  const searchParams = yield select(managementSearchParamsSelector);
  const existingData = yield select(managementDataSelector) ?? [];
  try {
    const response = yield call(loanService.list, {
      limit,
      offset,
      ...searchParams,
    });
    const combinedData = !fetchNextPage
      ? response.managementDetails
      : existingData.concat(response.managementDetails);
    paginationDebounce.setDebounceTimer();
    pagination.setOffset(offset + limit);
    pagination.setLastFetchEmpty(response.managementDetails.length === 0);
    yield put(setManagementDataForTable(combinedData));
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch loans. Something went wrong!");
  }
}

function* getManagementDataForCsv(action: { type: string }): any {
  try {
    let offset = 0;
    let combinedData: ManagementDetails[] = [];
    let haveMoreData = true;
    const searchParams = yield select(managementSearchParamsSelector);

    while (haveMoreData) {
      const response = yield call(loanService.list, {
        CSV_PAGINATION_LIMIT,
        offset,
        ...searchParams,
      });
      combinedData.push(...response.managementDetails);
      offset = offset + CSV_PAGINATION_LIMIT;
      if (!response.length) {
        haveMoreData = false;
      }
    }

    const modifiedData = combinedData.map((obj) => ({
      "Account Name": obj.accountName,
      "Workflow Type": obj.workFlowType,
      "Loan Size": obj.loanSize,
      "Loan Status": obj.loanStatus,
      "Account Owner": obj.accountOwner,
      Status: obj.status,
      "Document Due Date": obj.docDueDate,
      "Closing Date": obj.closingDate,
    }));

    const csvData = Papa.unparse(modifiedData);
    openSaveFileDialog(csvData, "management", "text/csv");

    yield put(managementDataForCsvActionCompleted());
    toastService.showSuccess("Management report created successfully.");
  } catch (e: any) {
    console.log(e);
    toastService.showError("Unable to generate management report. Something went wrong!");
  }
}

function* updateManagementSearchParams(action: { type: string; payload: PaginationParams }): any {
  if (!paginationDebounce.canMakeRequest()) {
    return;
  }
  const newSearchParams = action.payload;
  const oldSearchParams = yield select(managementSearchParamsSelector);
  if (!oldSearchParams || !isEqual(newSearchParams, oldSearchParams)) {
    pagination.setOffset(0);
    yield put(
      setManagementSearchParams({
        ...oldSearchParams,
        ...newSearchParams,
      }),
    );
  }
}

function* management() {
  yield takeEvery(ManagementAction.GET_MANAGEMENT_DATA_FOR_TABLE, getManagementDataForTable);
  yield takeEvery(ManagementAction.GET_MANAGEMENT_DATA_FOR_CSV, getManagementDataForCsv);
  yield takeEvery(ManagementAction.SET_MANAGEMENT_SEARCH_PARAMS, updateManagementSearchParams);
}

export default management;
