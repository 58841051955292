import produce from "immer";
import { Reducer } from "redux";
import { FeatureFlags } from "src/redux/actions/actions.constants";
import { DEFAULT_FEATURE_FLAGS, FeatureFlag } from "src/models/FeatureFlag";

export interface FeatureFlagState {
  featureFlags: FeatureFlag;
  isLoading: boolean;
  isUpdating: boolean;
  isEditing: boolean;
}

export const initialFeatureFlagState: FeatureFlagState = {
  featureFlags: DEFAULT_FEATURE_FLAGS,
  isLoading: false,
  isUpdating: false,
  isEditing: false,
};

export const featureFlagReducer: Reducer<FeatureFlagState> = (
  state: FeatureFlagState = initialFeatureFlagState,
  action,
) =>
  produce(state, (draft: any) => {
    const payload = action.payload as any;
    switch (action.type) {
      case FeatureFlags.SET_FEATURE_FLAGS: {
        draft.isLoading = false;
        draft.isUpdating = false;
        draft.isEditing = false;
        draft.featureFlags = payload.featureFlags;
        break;
      }
      case FeatureFlags.FETCH_FEATURE_FLAGS: {
        draft.isLoading = true;
        break;
      }
      case FeatureFlags.UPDATE_FEATURE_FLAG: {
        draft.isUpdating = true;
        break;
      }
      case FeatureFlags.UPDATED_FEATURE_FLAG: {
        draft.isUpdating = false;
        break;
      }
      case FeatureFlags.CANCEL_EDIT_FEATURE_FLAG: {
        draft.isEditing = false;
        break;
      }
      case FeatureFlags.EDIT_FEATURE_FLAG: {
        draft.isEditing = true;
        draft.isUpdating = false;
        break;
      }
    }
  });
