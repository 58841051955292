import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";

export const Form1065IncomeStatementRowHeadings = {
  GrossRevenues: "Gross Revenues",
  LessReturnsAndAllowances: "Less: Returns and Allowances",
  NetRevenues: "Net Revenues",
  LessCostOfGoodsSold: "Less: Cost of Goods Sold",
  GrossProfit: "Gross Profit",
  OrdinaryIncomeFromOtherPartnershipsEstatesAndTrusts:
    "Ordinary income (loss) from other partnerships, estates and trusts",
  NetFarmProfit: "Net farm profit (loss)",
  NetGainOrLoss: "Net gain or (Loss) from Form 4797, Part II, line 17",
  OtherIncome: "Other Income",
  TotalIncome: "Total Income",
  SalariesAndWages: "Salaries and wages (other than to partners) (less employment credits)",
  GuaranteedPaymentsToPartners: "Guaranteed payments to partners",
  RepairsAndMaintenance: "Repairs and maintenance",
  BadDebts: "Bad debts",
  Rents: "Rents",
  TaxesAndLicenses: "Taxes and licenses",
  InterestExpense: "Interest expense",
  Depreciation: "Depreciation",
  Depletion: "Depletion",
  PensionAndProfitSharingPlans: "Retirement plans, etc.",
  EmployeeBenefitPrograms: "Employee benefit programs",
  EnergyEfficientCommercialBuildingsDeduction: "Energy efficient commercial buildings deduction",
  OtherDeductions: "Other deductions",
  TotalExpensesAndDeductions: "Total expenses and deductions",
  PreTaxProfitOrLoss: "Pre-tax profit or loss",
  TotalTax: "Total tax",
  InterestDueLookBackCompletedLongTermContracts:
    "Interest due under the look-back method - completed long-term contracts",
  InterestDueLookBackIncomeForecastMethod:
    "Interest due under the look-back method - income forecast method",
  BBBAARImputedUnderpayment: "BBA AAR imputed underpayment",
  OtherTaxes: "Other taxes",
  NetIncome: "Net Income",
};

export function buildForm1065IncomeStatement(
  data: Form1065,
  startingColumn: string,
  startingRow: number,
): AutoRenderedSheetBuilder<Form1065, typeof Form1065IncomeStatementRowHeadings> {
  return new AutoRenderedSheetBuilder(
    data,
    Form1065IncomeStatementRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRow(({ data, labels }) => [labels.GrossRevenues, data.income?.grossRevenue ?? 0])
    .addRow(({ data, labels }) => [
      labels.LessReturnsAndAllowances,
      data.income?.lessReturnsAndAllowances ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.NetRevenues,
      `=${columnReference(labels.GrossRevenues)} - ${columnReference(labels.LessReturnsAndAllowances)}`,
    ])
    .addRow(({ data, labels }) => [
      labels.LessCostOfGoodsSold,
      data.income?.lessCostOfGoodsSold ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.GrossProfit,
      `=${columnReference(labels.NetRevenues)} - ${columnReference(labels.LessCostOfGoodsSold)}`,
    ])
    .addRow(({ data, labels }) => [
      labels.OrdinaryIncomeFromOtherPartnershipsEstatesAndTrusts,
      data.income?.ordinaryIncomeFromOtherPartnershipsEstatesAndTrusts ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.NetFarmProfit, data.income?.netFarmProfit ?? 0])
    .addRow(({ data, labels }) => [labels.NetGainOrLoss, data.income?.netGainOrLoss ?? 0])
    .addRow(({ data, labels }) => [labels.OtherIncome, data.income?.otherIncome ?? 0])
    .addRow(({ labels, columnReference }) => [
      labels.TotalIncome,
      `=SUM(${columnReference(labels.GrossProfit)}:${columnReference(labels.OtherIncome)})`,
    ])
    .addRow(({ data, labels }) => [labels.SalariesAndWages, data.deductions?.salariesAndWages ?? 0])
    .addRow(({ data, labels }) => [
      labels.GuaranteedPaymentsToPartners,
      data.deductions?.guaranteedPaymentsToPartners ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.RepairsAndMaintenance,
      data.deductions?.repairsAndMaintenance ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.BadDebts, data.deductions?.badDebts ?? 0])
    .addRow(({ data, labels }) => [labels.Rents, data.deductions?.rents ?? 0])
    .addRow(({ data, labels }) => [labels.TaxesAndLicenses, data.deductions?.taxesAndLicenses ?? 0])
    .addRow(({ data, labels }) => [labels.InterestExpense, data.deductions?.interest ?? 0])
    .addRow(({ data, labels }) => [labels.Depreciation, data.deductions?.depreciation ?? 0])
    .addRow(({ data, labels }) => [labels.Depletion, data.deductions?.depletion ?? 0])
    .addRow(({ data, labels }) => [
      labels.PensionAndProfitSharingPlans,
      data.deductions?.pensionAndProfitSharingPlans ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.EmployeeBenefitPrograms,
      data.deductions?.employeeBenefitPrograms ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.EnergyEfficientCommercialBuildingsDeduction,
      data.deductions?.energyEfficientCommercialBuildingsDeduction ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.OtherDeductions, data.deductions?.otherDeductions ?? 0])
    .addRow(({ labels, columnReference }) => [
      labels.TotalExpensesAndDeductions,
      `=SUM(${columnReference(labels.SalariesAndWages)}:${columnReference(labels.OtherDeductions)})`,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.PreTaxProfitOrLoss,
      `=${columnReference(labels.TotalIncome)} - ${columnReference(labels.TotalExpensesAndDeductions)}`,
    ])
    .addRow(({ data, labels }) => [
      labels.InterestDueLookBackCompletedLongTermContracts,
      data.taxes?.interestDueLookBackCompletedLongTermContracts ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.InterestDueLookBackIncomeForecastMethod,
      data.taxes?.interestDueLookBackIncomeForecastMethod ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.BBBAARImputedUnderpayment,
      data.taxes?.bbaAarImputedUnderpayment ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.OtherTaxes, data.taxes?.otherTaxes ?? 0])
    .addRow(({ labels, columnReference }) => [
      labels.TotalTax,
      `=SUM(${columnReference(labels.InterestDueLookBackCompletedLongTermContracts)}:${columnReference(
        labels.OtherTaxes,
      )})`,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.NetIncome,
      `=${columnReference(labels.PreTaxProfitOrLoss)} - ${columnReference(labels.TotalTax)}`,
    ]);
}
