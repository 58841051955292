import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FeatureFlags as _FeatureFlags } from "@prisma/client";
import { baseApiService } from "src/services/api-services/BaseApiService";
import { fetchFeatureFlags as fetchFeatureFlagsAction } from "src/redux/actions/feature-flag.action";
import { CONVERSION, DEFAULT_FEATURE_FLAGS, UpdatedFeatureFlag } from "src/models/FeatureFlag";
import { fetchLenderSettings } from "src/redux/actions/lender.action";

export const fetchFeatureFlags = async () => {
  try {
    const response: _FeatureFlags[] = await baseApiService.get("/api/lender/feature-flags");
    const featureFlagsFromAPI: { [key: string]: any } = {};
    response &&
      response.length &&
      response.forEach((featureFlag) => {
        featureFlagsFromAPI[featureFlag.name] = CONVERSION[featureFlag.type](featureFlag.value);
      });

    const featureFlagUnion = {
      ...DEFAULT_FEATURE_FLAGS,
      ...featureFlagsFromAPI,
    };
    return featureFlagUnion;
  } catch (_) {
    return DEFAULT_FEATURE_FLAGS;
  }
};

export const updateFeatureFlag = async (featureFlag: UpdatedFeatureFlag) => {
  const response = await baseApiService.put("/api/lender/feature-flags", featureFlag);
  return response;
};

const FeatureFlags = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFeatureFlagsAction());
    dispatch(fetchLenderSettings());
  }, [dispatch]);

  return null;
};

export default FeatureFlags;
