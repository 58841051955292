import { AutoRendered } from "src/classes/AutoRendered";
import { SupportedLenderId } from "src/interfaces/SpreadsConfig/SpreadsConfig";
import spreadConfig from "src/spreads-config";

export type LoanCalculatorData = {
  excessCashflow: string;
  newDebt: string;
  lenderId: SupportedLenderId;
};

const defaultAmortizationMonths = 240;

export class LoanCalculatorRendered extends AutoRendered<LoanCalculatorData> {
  asColumns(): string[][] {
    //PV(RATE, NPER, PMT)
    const rate = `${this.columnId}${this.rowStart}/12`;
    const nper = `${this.columnId}${this.rowStart + 2}`;
    const monthlyPayment = `-${this.columnId}${this.rowStart + 1}`;
    const maxLoanCalc = `=PV(${rate}, ${nper}, ${monthlyPayment})`;
    //Monthly Payment  = [New Debt] / 12
    const monthlyPaymentFormula = `=${this.underlying.newDebt} / 12`;
    return [
      [
        "Interest Rate",
        spreadConfig.lenderSettings[this.underlying.lenderId].defaultInterestRate.toString(),
      ],
      ["Monthly Payment", `${monthlyPaymentFormula}`],
      ["Amortization (months)", `${defaultAmortizationMonths}`],
      ["Loan Amount", maxLoanCalc],
    ];
  }

  get highlightedRowLabels() {
    return ["Loan Amount"];
  }

  get percentageRowLabels() {
    return ["Interest Rate"];
  }

  metadataRowLabels: string[] = [];

  constructor(
    public underlying: LoanCalculatorData,
    rowStart: number = 1,
  ) {
    super(underlying);
    this.rowStart = rowStart;
  }
}

export type ManyLoanCalculatorsData = {
  excessCashflows: string[];
  lenderId: SupportedLenderId;
  newDebt: string[];
};

export class ManyLoanCalculatorsRendered extends AutoRendered<ManyLoanCalculatorsData> {
  private generateLoanAmountFormula(column: number): string {
    const columnId = this.colNumberToExcelCol(column + 2);
    const rate = `${columnId}${this.rowStart}/12`;
    const nper = `${columnId}${this.rowStart + 2}`;
    const monthlyPayment = `-${columnId}${this.rowStart + 1}`;
    return `=PV(${rate}, ${nper}, ${monthlyPayment})`;
  }
  asColumns(): string[][] {
    //Monthly Payment  = [New Debt] / 12
    const monthlyPayments = this.underlying.newDebt.map((debt) => `=${debt} / 12`);

    const loanAmounts = this.underlying.excessCashflows.map((_, i) =>
      this.generateLoanAmountFormula(i),
    );

    let rows: string[][];
    const interestRate =
      spreadConfig.lenderSettings[this.underlying.lenderId].defaultInterestRate.toString();
    const blanks = Array(this.underlying.excessCashflows.length - 1).fill(" ");
    if (this.totalColumnOnly) {
      rows = [
        ["Interest Rate", interestRate, ...blanks],
        ["Monthly Payment", monthlyPayments[0], ...blanks],
        ["Amortization (months)", defaultAmortizationMonths, ...blanks],
        ["Loan Amount", loanAmounts[0], ...blanks],
      ];
    } else {
      rows = [
        ["Interest Rate", ...Array(this.underlying.excessCashflows.length).fill(interestRate)],
        ["Monthly Payment", ...monthlyPayments],
        [
          "Amortization (months)",
          ...Array(this.underlying.excessCashflows.length).fill(defaultAmortizationMonths),
        ],
        ["Loan Amount", ...loanAmounts],
      ];
    }
    return rows;
  }

  get highlightedRowLabels() {
    return ["Loan Amount"];
  }

  get percentageRowLabels() {
    return ["Interest Rate"];
  }

  metadataRowLabels: string[] = [];

  constructor(
    public underlying: ManyLoanCalculatorsData,
    rowStart: number = 1,
    private totalColumnOnly: boolean = false,
  ) {
    super(underlying);
    this.rowStart = rowStart;
  }
}
