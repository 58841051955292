import { applyTheme } from "src/themes/utils";
import { useDisclosure } from "@mantine/hooks";
import BrushIcon from "@mui/icons-material/Brush";
import { Avatar, Dialog, Text } from "@mantine/core";
import { theme } from "src/themes/base";
import AppFormAutoCompleteSelectField from "src/components/AppFormAutoCompleteSelectField/AppFormAutoCompleteSelectField";
import { useUser } from "@auth0/nextjs-auth0/client";
import { LenderEmployeeRole } from "@prisma/client";

enum LENDER_NAMES {
  DEFAULT = "DEFAULT",
  DEFAULT_2 = "DEFAULT 2",
  FCN = "FCN",
  PAB = "PAB",
  LNB = "LNB",
  CBTX = "CBTX",
  ACB = "ACB",
  NCNB = "NCNB",
}

const LENDER_THEMES = {
  [LENDER_NAMES.DEFAULT]: "1",
  [LENDER_NAMES.DEFAULT_2]: "2",
  [LENDER_NAMES.FCN]: "3",
  [LENDER_NAMES.PAB]: "4",
  [LENDER_NAMES.LNB]: "5",
  [LENDER_NAMES.CBTX]: "6",
  [LENDER_NAMES.ACB]: "7",
  [LENDER_NAMES.NCNB]: "10",
};

const LENDER_THEME_OPTIONS = (Object.keys(LENDER_NAMES) as Array<keyof typeof LENDER_THEMES>).map(
  (key) => {
    return {
      value: LENDER_THEMES[key],
      label: key,
    };
  },
);

const AppThemeSelector = () => {
  const { user } = useUser();
  const [opened, { toggle, close }] = useDisclosure(false);

  if (!user || user.lenderRole !== LenderEmployeeRole.INTERFOLD_EMPLOYEE) {
    return null;
  }

  return (
    <div className={"absolute top-2 right-2"}>
      {!opened && (
        <Avatar color="blue" radius="xl" onClick={toggle}>
          <BrushIcon />
        </Avatar>
      )}

      <Dialog
        opened={opened}
        withCloseButton
        onBlur={close}
        onClose={close}
        size="lg"
        radius="md"
        position={{ top: "10px", right: "10px" }}
      >
        <Text size="sm" mb="xs" fw={500}>
          Select a theme
        </Text>
        <AppFormAutoCompleteSelectField
          options={LENDER_THEME_OPTIONS}
          placeholder="Select a theme"
          onInputChange={() => {
            // Do nothing
          }}
          required
          onChange={(value: keyof typeof theme) => {
            applyTheme(theme[value]);
          }}
        />
      </Dialog>
    </div>
  );
};

export default AppThemeSelector;
