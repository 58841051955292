import { AxiosError } from "axios";
import { StatusCodes, StatusMessage } from "src/constants/status-codes";

export enum ErrorCode {
  UNIDENTIFIED,
}

export type ErrorStatusCode = ErrorCode | StatusCodes;

export type ValidationErrorType = {
  field: string;
  message: string;
  rule: string;
};

const DEFAULT_ERROR = "An unexpected error occurred. Please try again";

export class BaseError<T = any> {
  constructor(
    readonly message: string = DEFAULT_ERROR,
    readonly status: ErrorStatusCode = ErrorCode.UNIDENTIFIED,
    readonly response?: T,
    readonly errors: ValidationErrorType[] = [],
    readonly data: any = [],
  ) {}

  static fromJSON<T>(axiosError: AxiosError<T>): BaseError<T | never> {
    if (axiosError.code === "ECONNABORTED") {
      return new BaseError<never>(`Request Timeout (${axiosError.message})`);
    }

    if (!axiosError.response) {
      return new BaseError<never>(
        "Unable to connect to server. Please check your internet connection try again.",
      );
    }

    const { status, data } = axiosError.response;
    const message: string = StatusMessage[status as StatusCodes] ?? DEFAULT_ERROR;

    return new BaseError<T>(
      message,
      status,
      data,
      (data as any)?.errors ?? [],
      (data as any)?.data ?? [],
    );
  }

  static toJSON(json: BaseError) {
    return {
      message: json.message || "Error. Please Try Again.",
      status: json.status || -1,
      response: json.response,
      errors: json.errors || [],
      data: json.data || [],
    };
  }
}
