import { TaxFormYear } from "src/interfaces/TaxFormData/TaxFormData";

interface FicaCalculationConfiguration {
  socialSecurity: number;
  medicare: number;
  socialSecurityThreshold: number;
  additionalMedicare: number;
  additionalMedicareThreshold: number;
}

const baseFicaCalculationConfiguration = {
  socialSecurity: 0.062,
  medicare: 0.0145,
  additionalMedicare: 0.009,
  additionalMedicareThreshold: 250000,
};

// FICA calculation configuration for each tax year
// https://arc.net/l/quote/hzdmtsaw
export const ficaCalculationConfiguration: Record<TaxFormYear, FicaCalculationConfiguration> = {
  "2020": { ...baseFicaCalculationConfiguration, socialSecurityThreshold: 137700 },
  "2021": { ...baseFicaCalculationConfiguration, socialSecurityThreshold: 142800 },
  "2022": { ...baseFicaCalculationConfiguration, socialSecurityThreshold: 147000 },
  "2023": { ...baseFicaCalculationConfiguration, socialSecurityThreshold: 160200 },
};
