import { z } from "zod";

export enum FrequencyOptionEnum {
  ANNUALLY = "Annually",
  BIANNUALLY = "Biannually",
  QUARTERLY = "Quarterly",
  MONTHLY = "Monthly",
  CUSTOM = "Custom",
  ONE_TIME = "One-Time",
}

export enum CustomPeriodFrequencyOptionEnum {
  MONTHS = "Months",
  WEEKS = "Weeks",
}

export const frequencyOptionEnumSchema = z.nativeEnum(FrequencyOptionEnum);
export const customPeriodFrequencyOptionEnumSchema = z.nativeEnum(CustomPeriodFrequencyOptionEnum);
