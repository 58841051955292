import { IncomeAssetParts } from "src/backend/services/ocr/abstractions/property";
import {
  DefaultRowLabelsSubset,
  HoverInfo,
  HoverLabel,
  Rendered,
  RenderedDoc,
  RenderedMetaData,
  createGridEntry,
  highlightedNumberRow,
  metaDataDateRow,
  metadataTextRow,
  standardNumberRow,
} from "src/classes/RenderedDoc";
import { ExtractContext } from "src/backend/services/ocr/spread-data-aggregation/spread-data-aggregate";
import { GridState } from "src/classes/GridState";
import { LoanCalculatorRendered } from "src/classes/RenderedDocuments/LoanCalculatorRendered";
import { SupportedLenderId } from "src/interfaces/SpreadsConfig/SpreadsConfig";
import { CanonicalRentRoll } from "src/models/CanonicalRentRoll";
import { canonicalRentRoll2RentRollData } from "./canonicalRentRoll2RentRollData";

// This is specifying that RentRollTableData has all the properties of PropertyParts,
// some of which are required, and (the rest of which are optional)
export type RentRollTableData = RenderedMetaData & {
  [Part in keyof Pick<
    IncomeAssetParts,
    | "managementFeePercentage"
    | "replacementCostPercentage"
    | "grossRents"
    | "annualPropertyTaxes"
    | "annualInsurance"
    | "utilities"
    | "occupancyPercentage"
    | "year"
  >]-?: IncomeAssetParts[Part];
} & IncomeAssetParts & {
    extractContext: ExtractContext;
  };

type RentRollTableDefaultRowLabels =
  | "Gross Rents"
  | "Vacancy (%)"
  | "Adjusted Rents"
  | "Management Fee"
  | "Replacement Cost"
  | "Taxes"
  | "Insurance"
  | "Utilities"
  | "Total Expenses"
  | "Net Operating Income (NOI)"
  | "Total Debt Service"
  | "Debt Service Coverage Ratio (DSCR)"
  | "Interest Rate"
  | "Excess/Deficit";

export class RentRollTable
  extends Rendered<RentRollTableData, RentRollTableDefaultRowLabels>
  implements RenderedDoc
{
  constructor(
    rentRoll: RentRollTableData,
    public lenderId: SupportedLenderId = 1,
  ) {
    super(rentRoll);
    this.numberOfColumns = 2;
    this.setHoverInfos();
  }

  static from(
    canonicalRentRoll: CanonicalRentRoll,
    lenderId: SupportedLenderId = 1,
  ): RentRollTable {
    return new RentRollTable(canonicalRentRoll2RentRollData(canonicalRentRoll), lenderId);
  }

  numberOfColumns: number;

  setHoverInfos(): void {
    const height = Object.keys(this.initialGridState).length;
    const width = 2;
    const def: HoverInfo[][] = Array.from({ length: height }, (v, i) =>
      Array.from({ length: width }, (v, j) => ({ type: "auto" })),
    );

    const findRowById = (id: string) => {
      return Object.entries(this.initialGridState).findIndex(([key, _]) => key === id);
    };

    const grossRentsRowIdx = findRowById("grossRents");
    if (grossRentsRowIdx > -1) {
      def[grossRentsRowIdx][1] = HoverLabel.from(
        "The total amount of rent collected from tenants.",
      );
    }

    this.hoverInfos = def;
  }

  get initialGridState(): GridState {
    const docType = this.underlying?.extractContext?.packageDetails?.[0]?.docType;
    const formattedDocType =
      docType === "RENT_ROLL"
        ? "Rent Roll"
        : docType === "MULTI_PROPERTY_RENTROLL"
          ? "Multi-Property RR"
          : "";

    const entityName = this.underlying?.extractContext?.metadata?.entityName;
    const year = this.underlying?.year;
    const propertyName = this.underlying?.propertyName || "";
    const grossRents = this.underlying?.grossRents || 0;

    const occupancyRate = 0.08;
    let index = 0;
    const mainPart = {
      source: metadataTextRow(["Source", formattedDocType], index++),
      entity: metadataTextRow(["Entity", entityName], index++),
      property: metadataTextRow(["Property", propertyName], index++),
      periodStartDate: metaDataDateRow(["Period Start Date", `01-01-${year}`], index++),
      periodEndDate: metaDataDateRow(["Period End Date", `12-31-${year}`], index++), //5
      blankRow: createGridEntry(["", ""], "text", "standard", index++),
      grossRents: standardNumberRow(["Gross Rents", grossRents], index++),
      occupancyRate: createGridEntry(
        ["Vacancy (%)", `=B7 * ${occupancyRate} * -1`],
        "number",
        "standard",
        index++,
        true,
      ),
      adjustedRents: standardNumberRow(["Effective Gross Rents", "=B7 + B8"], index++),
      // TODO taxes here
      annualPropertyTaxes: standardNumberRow(
        ["Taxes", this.underlying.annualPropertyTaxes || 0],
        index++,
      ),
      annualInsurance: standardNumberRow(
        ["Insurance", this.underlying.annualInsurance || 0],
        index++,
      ),
      utilities: standardNumberRow(["Utilities", this.underlying.utilities || 0], index++),
      repairs: standardNumberRow(["Repairs", this.underlying.repairs], index++),
      managementFee: standardNumberRow(["Management Fee", `=B9 * 0.04`], index++),
      replacementFee: standardNumberRow(["Replacement Cost", "=1 * 250"], index++),
      totalExpenses: standardNumberRow(["Total Expenses", "=SUM(B10:B15)"], index++), //16

      noi: highlightedNumberRow(["Net Operating Income (NOI)", "=B9-B16"], index++),
      existingDebt: standardNumberRow(["Existing Debt", 0], index++),
      // New Debt = [NOI ] * 1.25 - [Existing Debt]
      newDebt: standardNumberRow(["New Debt", `=B17 / 1.25 - B18`], index++),
      totalDebtService: highlightedNumberRow(["Total Debt Service", "=B18+B19"], index++),
      dscr: highlightedNumberRow(["Debt Service Coverage Ratio (DSCR)", "=B17/B20"], index++), // 21
      excessDeficit: highlightedNumberRow(["Excess/Deficit", "=B17-B20"], index++),
    } as GridState;

    const loanCalculator = new LoanCalculatorRendered(
      {
        excessCashflow: `B${index}`,
        lenderId: this.lenderId,
        newDebt: `B19`,
      },
      ++index,
    );
    return {
      ...mainPart,
      ...loanCalculator.initialGridState,
    };
  }

  get defaultRowLabels(): RentRollTableDefaultRowLabels[] {
    return [
      "Gross Rents",
      "Vacancy (%)",
      "Adjusted Rents",
      "Management Fee",
      "Replacement Cost",
      "Taxes",
      "Insurance",
      "Utilities",
      "Total Expenses",
      "Net Operating Income (NOI)",
      "Total Debt Service",
      "Debt Service Coverage Ratio (DSCR)",
      "Excess/Deficit",
    ];
  }

  get highlightedRowLabels(): RentRollTableDefaultRowLabels[] {
    return [
      "Net Operating Income (NOI)",
      "Total Debt Service",
      "Debt Service Coverage Ratio (DSCR)",
      "Excess/Deficit",
    ];
  }

  percentageRowLabels: DefaultRowLabelsSubset<RentRollTableDefaultRowLabels[]> = ["Interest Rate"];
  metadataRowLabels: DefaultRowLabelsSubset<RentRollTableDefaultRowLabels[]> = [];
}
