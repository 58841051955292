export enum DocumentQuality {
  SELF_PREPARED = "self_prepared",
  COMPANY_PREPARED = "company_prepared",
  CPA_PREPARED = "cpa_prepared",
  CPA_REVIEWED = "cpa_reviewed",
  CPA_AUDITED = "cpa_audited",
  CPA_COMPILED = "cpa_compiled",
  NONE = "none",
}

export const qualityToLabel = (quality: DocumentQuality): string => {
  switch (quality) {
    case DocumentQuality.SELF_PREPARED:
      return "Self Prepared";
    case DocumentQuality.COMPANY_PREPARED:
      return "Company Prepared";
    case DocumentQuality.CPA_PREPARED:
      return "CPA Prepared";
    case DocumentQuality.CPA_REVIEWED:
      return "CPA Reviewed";
    case DocumentQuality.CPA_AUDITED:
      return "CPA Audited";
    case DocumentQuality.CPA_COMPILED:
      return "CPA Compiled";
    case DocumentQuality.NONE:
      return "None";
  }
};
