import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { Form1120sIncomeStatementGrouped } from "./Form1120sIncomeStatementGrouped";
import { Form1120sBalanceSheetGrouped } from "./Form1120sBalanceSheetGrouped";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";
import { Form1120sCashFlowGrouped } from "./Form1120sCashFlowGrouped";
import { AutoRenderedSheetBuilderWithConfidence } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";

export const Form1120sRatiosRowHeadings = {
  DebtServiceCoverageRatio: "Debt service coverage ratio",
  ROA: "ROA",
  ROE: "ROE",
  LiquidityRatio: "Liquidity ratio",
  QuickRatio: "Quick ratio",
  CurrentRatio: "Current ratio",
  DebtToEquityRatio: "Debt-to-equity ratio",
  AssetRatio: "Asset ratio",
  DaysPayable: "Days payable",
  DaysReceivable: "Days receivable",
  DaysInventory: "Days inventory",
};

export function buildForm1120sRatios(
  data: RenderableWithConfidence<Form1120S>,
  balanceSheetTabName: string,
  incomeStatementTabName: string,
  cashFlowTabName: string,

  balanceSheet: Form1120sBalanceSheetGrouped,
  incomeStatement: Form1120sIncomeStatementGrouped,
  cashFlow: Form1120sCashFlowGrouped,

  startingColumn: string,
  startingRow: number,
) {
  const col = startingColumn;
  const balanceSheetTab = (row: number) => `'${balanceSheetTabName}'!${col}${row}`;
  const incomeStatementTab = (row: number) => `'${incomeStatementTabName}'!${col}${row}`;
  const cashFlowTab = (row: number) => `'${cashFlowTabName}'!${col}${row}`;

  const netIncome = incomeStatementTab(incomeStatement.indexOfHeader("NetIncome"));
  const totalAssets = balanceSheetTab(balanceSheet.indexOfHeader("TotalAssets"));
  const totalLiabilities = balanceSheetTab(balanceSheet.indexOfHeader("TotalLiabilities"));
  const cash = balanceSheetTab(balanceSheet.indexOfHeader("Cash"));
  const receivables = balanceSheetTab(balanceSheet.indexOfHeader("AccountsAndNotesReceivable"));
  const inventory = balanceSheetTab(balanceSheet.indexOfHeader("Inventory"));
  const marketableSecurities = balanceSheetTab(balanceSheet.indexOfHeader("MarketableSecurities"));
  const currentLiabilities = balanceSheetTab(balanceSheet.indexOfHeader("TotalCurrentLiabilities"));
  const currentAssets = balanceSheetTab(balanceSheet.indexOfHeader("TotalCurrentAssets"));
  const accountsPayable = balanceSheetTab(balanceSheet.indexOfHeader("AccountsPayable"));
  const costOfGoods = incomeStatementTab(incomeStatement.indexOfHeader("LessCostOfGoodsSold"));
  const grossRevenues = incomeStatementTab(incomeStatement.indexOfHeader("GrossRevenues"));

  return AutoRenderedSheetBuilderWithConfidence.from(
    data,
    Form1120sRatiosRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRow(({ labels }) => [
      labels.DebtServiceCoverageRatio,
      `=${cashFlowTab(cashFlow.indexOfHeader("DebtServiceCoverageRatio"))}`,
    ])
    .addRow(({ labels }) => [
      labels.ROA,
      `=IF(${netIncome} / ${totalAssets} < 0, "Negative", ${netIncome} / ${totalAssets})`,
    ])
    .addRow(({ labels }) => [
      labels.ROE,
      `=IF(${netIncome} / ${totalLiabilities} < 0, "Negative", ${netIncome} / ${totalLiabilities})`,
    ])
    .addRow(({ labels }) => [
      labels.LiquidityRatio,
      `=(${cash} + ${marketableSecurities}) / ${currentLiabilities}`,
    ])
    .addRow(({ labels }) => [
      labels.QuickRatio,
      `=(${cash} + ${receivables} + ${marketableSecurities}) / ${currentLiabilities}`,
    ])
    .addRow(({ labels }) => [labels.CurrentRatio, `=${currentAssets} / ${currentLiabilities}`])
    .addRow(({ labels }) => [labels.DebtToEquityRatio, `=${totalLiabilities} / ${totalAssets}`])
    .addRow(({ labels }) => [labels.AssetRatio, `=${totalAssets} / ${totalLiabilities}`])
    .addRow(({ labels }) => [labels.DaysPayable, `=${accountsPayable} / ${costOfGoods} * 365`])
    .addRow(({ labels }) => [labels.DaysReceivable, `=${receivables} / ${grossRevenues} * 365`])
    .addRow(({ labels }) => [labels.DaysInventory, `=${inventory} / ${costOfGoods} * 365`]);
}
